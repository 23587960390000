import React from 'react';
import Draggable from 'react-draggable';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleDragStop.bind(this);
        this.handleResize.bind(this);
        this.outsideClickRef = React.createRef(null)
        this.clickTimeout = null
        this.state = {
            font: false,
            image: this.props.data,
            notDraggable: false,
            controls: false,
            id: this.props.element_id,
            toDelete: false,
            x: this.props.data.x,
            y: this.props.data.y,
            textarea: {
                height: this.props.data.elHeight,
                width: this.props.data.elWidth,
            },
            zIndex: this.props.data.zIndex,
            enableGrid: this.props.enableGrid,
            mount: true,
            gridLevel: parseInt(this.props.grid_level) * 10,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.textarea.height !== props.data.elHeight || state.textarea.width !== props.data.elWidth) {
            return {
                image: props.data,
                id: state.id,
                zIndex: props.data.zIndex,
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        } else {
            return {
                image: props.data,
                id: state.id,
                zIndex: props.data.zIndex,
                textarea: {
                    height: props.data.elHeight,
                    width: props.data.elWidth,
                },
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.last_selected_element === this.state.id) {
            this.findGrid(0, { x: this.state.image.x, y: this.state.image.y })
        }
        if (this.props.data.x !== prevProps.data.x) {
            if (this.props.data.x !== this.state.x) {
                this.setState({
                    x: this.props.data.x,
                    y: this.props.data.y,
                })
            }
        }
        if (this.state.mount) {
            this.setState({
                mount: false,
            })
        }
    }

    hideControls = () => {
        this.setState({
            controls: false,
            notDraggable: false,
        })
    }

    handleSendToParent = (e) => {
        if (e.currentTarget.id === "delete" && this.state.toDelete !== 'required') {
            this.setState({
                toDelete: this.state.image.timeStamp,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (this.state.textarea.width === "auto") {
            const img = document.querySelector("#box" + this.state.image.timeStamp + " img");
            img.addEventListener('load', this.handleImageLoad);
        }
    }

    handleImageLoad = () => {
        const box = document.querySelector("#box" + this.state.image.timeStamp);
        const offsetWidth = box.offsetWidth;
        this.setState({
            textarea: {
                height: this.state.textarea.height,
                width: offsetWidth,
            }
        }, () => {
            this.props.fetchChildSize(this.state)
        })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.state.controls && !this.outsideClickRef.current.contains(e.target)) {
            this.hideControls();
        }
    }

    handleDragStop = (e, dragElement) => {
        this.setState({
            x: dragElement.x,
            y: dragElement.y,
            textarea: {
                height: e.target.offsetHeight,
                width: e.target.offsetWidth,
            },
        }, () => {
            this.props.fetchChildDrag(this.state)
        })
    };

    handleHistory = (event, dragElement) => {
        this.props.fetchHistory(this.state.id)
    };

    handleClicks = (e) => {
        if (this.clickTimeout !== null && !this.state.image.required) {
            this.setState({
                controls: true,
                notDraggable: true,
            });
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else if (!this.state.notDraggable) {
            this.clickTimeout = setTimeout(() => {
                e.preventDefault()
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 500)
        }
    }

    handleResize = (e) => {
        if (e.target.offsetHeight !== this.state.textarea.height && e.target.offsetWidth !== this.state.textarea.width) {
            if (this.state.image.resizeLock) {
                e.target.offsetHeight - this.state.textarea.height > e.target.offsetWidth - this.state.textarea.width ?
                    this.setState({
                        textarea: {
                            height: e.target.offsetHeight,
                            width: parseInt(e.target.offsetHeight * (this.state.textarea.width / this.state.textarea.height)),
                        },
                    }, () => {
                        this.props.fetchChildSize(this.state)
                    })
                    :
                    this.setState({
                        textarea: {
                            height: parseInt(e.target.offsetWidth * (this.state.textarea.height / this.state.textarea.width)),
                            width: e.target.offsetWidth,
                        },
                    }, () => {
                        this.props.fetchChildSize(this.state)
                    })
            } else {
                this.setState({
                    textarea: {
                        height: e.target.offsetHeight,
                        width: e.target.offsetWidth,
                    },
                }, () => {
                    this.props.fetchChildSize(this.state)
                })
            }
        } else {
            //this causes image to resize automatically when entering edit mode
            // this.props.fetchChildSize(this.state)
            this.setState({
                textarea: {
                    height: this.state.textarea.height,
                    width: this.state.textarea.width,
                },
            }, () => {
                this.props.fetchChildSize(this.state)
            })
        }

        //To add to history when resized
        this.handleHistory();

        setTimeout(() => {
            this.props.fetchCenterGrid(false, false)
        }, 1000);
    }

    findGrid = (event, dragElement) => {
        let centerGridX = false;
        let centerGridY = false;
        if (Math.ceil(parseInt(dragElement.x + (parseInt(this.state.textarea.width / 2))) / 2) === this.props.certWidth / 4) {
            centerGridX = true
        }
        if (Math.ceil(parseInt(dragElement.y + (parseInt(this.state.textarea.height / 2))) / 2) === this.props.certHeight / 4) {
            centerGridY = true
        }
        this.props.fetchCenterGrid(centerGridX, centerGridY)
    }

    render() {
        return (
            <Draggable position={{ x: this.state.image.x, y: this.state.image.y }} disabled={this.state.notDraggable} cancel={this.state.notDraggable ? '.imagearea,select,button' : 'select,button'} onStop={this.handleDragStop} grid={this.state.enableGrid ? [this.state.gridLevel, this.state.gridLevel] : null} onStart={this.handleHistory} onDrag={this.findGrid} scale={this.state.enableGrid ? 1 : this.props.certificate_zoom}>
                <div className={this.state.notDraggable ? 'box pinned' : 'box'} ref={this.outsideClickRef} style={{ zIndex: this.state.controls ? 999 : this.state.zIndex, pointerEvents: this.props.move_canvas ? "all" : "none" }} id={"box" + this.state.image.timeStamp}>
                    {this.state.controls && (
                        <div className='variable-controls'>
                            {
                                this.state.image.required ? "" : <button className='delete' id="delete" onClick={this.handleSendToParent}><i className="fa-solid fa-trash pe-none"></i></button>
                            }
                        </div>
                    )}
                    <div className="wrap">
                        <div className="pull-tab"></div>
                        <div className={this.state.image.shape !== undefined ? 'imagearea ' + this.state.image.shape : 'imagearea'}
                            style={{
                                resize: this.state.notDraggable ? this.state.image.resize : "none",
                                height: this.state.image.elHeight + "px",
                                width: this.state.image.elWidth === "auto" ? "auto" : this.state.image.elWidth + "px",
                            }}
                            onMouseDown={this.handleClicks}
                            onMouseUp={this.handleResize}
                        >
                            {
                                this.state.image.content === "shape" ? <div className='shape'></div> : <img src={this.state.image.content} alt="logo in certificate" style={this.state.mount ? { width: "auto" } : {}}></img>
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    }
}

export default ElementComponent;