import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';
import Draggable from 'react-draggable';

import domtoimage from 'dom-to-image';
import download from 'downloadjs';

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import Warning from '../components/warning';
import ProgressLoader from '../components/progress_loader';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            sliderValue: 3,
            sliderClass: "slider-3",
            elements: [],
            certView: false,
            view_type: '',
            QRSize: 125,
            resetX: 0,
            resetY: 0,
            cert_attachment: null,
            scaleWidth: 1,
            moreBtn: 'more',
            progress_loader: false,
            cacheTs: Date.now(),
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.post(this.props.node_server_axios + 'get_appreciation', {
            params: {
                documentId: this.props.documentId,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'certificate found') {

                    var myElement = document.querySelector('.cropper-rel');
                    var width = myElement.offsetHeight > myElement.offsetWidth ? myElement.offsetWidth : myElement.offsetHeight;

                    const updatedData = JSON.parse(res.data.elements);
                    let tableVariables = []
                    if (updatedData.filter(element => element.type === "table").length > 0) {
                        tableVariables = updatedData.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
                    }
                    let variableIndexElements = updatedData.filter(element => element.type !== "image" && element.type !== "signature" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
                    let variablesLocal = variableIndexElements.map(element => element.variables)
                    let mergedVariablesLocal = [].concat.apply([], variablesLocal);
                    let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
                    uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);
                    this.setState({
                        elements: updatedData,
                        variables: uniqueVariablesLocal,
                        org_id: res.data.org_id,
                        cert_bg: res.data.cert_bg,
                        csvData: JSON.parse(res.data.csvData),
                        certView: true,
                        accepted: res.data.accepted,
                        event_name: res.data.event_name,
                        issue_date: res.data.issue_date,
                        pronetin_verified: res.data.pronetin_verified,
                        signees: res.data.signees,
                        org_name: res.data.org_name,
                        cert_title: res.data.cert_title,
                        cert_attachment: res.data.cert_attachment,
                        domain: res.data.domain,
                        contact: res.data.contact,
                    })

                    const img = new Image();
                    const imgPromise = new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                    img.src = "https://pronetin-new.s3.amazonaws.com/batch/00000000-0000-0000-0000-000000000000/" + encodeURIComponent(res.data.cert_bg);

                    try {
                        await imgPromise;
                        let cWidth = 904;
                        let cHeight = 640;

                        if (img.naturalWidth > img.naturalHeight) {
                            cWidth = 904;
                            cHeight = 640;
                        } else if (img.naturalWidth < img.naturalHeight) {
                            cWidth = 640;
                            cHeight = 904;
                        } else {
                            console.log("Invalid Size");
                        }
                        this.setState({
                            certWidth: cWidth,
                            certHeight: cHeight,
                            scaleWidth: myElement.offsetHeight > myElement.offsetWidth ? ((width - 20) / cWidth).toFixed(2) : ((width - 20) / cHeight).toFixed(2),
                        })
                    } catch (error) {
                        console.log("Error loading image:", error);
                    }
                }
            }
        })
    }

    downloadCertificate = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');

        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;

        // Scroll the element into view
        node.scrollIntoView();

        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth + 'px';
        node.style.height = elementHeight + 'px';

        // Capture the screenshot
        domtoimage
            .toPng(node)
            .then(async (dataUrl) => {
                await download(dataUrl, 'certificate.png');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                })
            });
    };

    updateZoom = (e) => {
        this.setState({
            sliderValue: e.target.value,
            sliderClass: "slider-" + e.target.value
        })
    }

    handleCertDragStop = (e, dragElement) => {
        this.setState({
            resetX: dragElement.x,
            resetY: dragElement.y,
        })
    };

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        var wrapperElem = document.getElementsByClassName('wrapper')[0];
        if (subNav.style.display !== 'flex') {
            subNav.style.display = 'flex';
            wrapperElem.style.display = 'none';
            this.setState({
                moreBtn: 'back',
            })
        } else {
            subNav.style.display = 'none';
            wrapperElem.style.display = 'flex';
            this.setState({
                moreBtn: 'more',
            })
        }
    }

    recenterCertificate = () => {
        this.setState({
            resetX: 0,
            resetY: 0,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="element full-element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Issued Document<label>public</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                        </div>
                        <div className='tiles marketplace pg-certficate_viewer'>
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-circle-info"></i> About</h1>
                                {this.state.accepted === 'true' ?
                                    <div className='nav-container'>
                                        <div className='info-container'>
                                            <h3>Document Information</h3>
                                            <p>Title : {this.state.cert_title}</p>
                                            <p>Event : {this.state.event_name}</p>
                                            {this.state.event_name !== 'Onboarding' && (<p>Issue Date : {this.state.issue_date}</p>)}
                                        </div>
                                        <div className='info-container'>
                                            <h3>Signed By</h3>
                                            <p>CEO, Pronetin</p>
                                        </div>
                                        <a className='nav' href="foo" onClick={this.downloadCertificate}>
                                            <i className="fa-solid fa-download"></i>
                                            Download
                                        </a>
                                        <div className='info-container'>
                                            <h3>Issuer Information</h3>
                                            <p>Issuer : {this.state.org_name}</p>
                                        </div>
                                        {/* <a className='nav' href={"https://" + this.state.domain} target="_blank" rel="noreferrer">
                                            <i className="fa-solid fa-link"></i>
                                            Website
                                        </a>
                                        <a className='nav' href={"mailto:" + this.state.contact} target="_blank" rel="noreferrer">
                                            <i className="fa-regular fa-envelope"></i>
                                            Contact
                                        </a> */}
                                        <a className='nav' href={"https://pronetin.com"} target="_blank" rel="noreferrer">
                                            <i className="fa-solid fa-link"></i>
                                            Website
                                        </a>
                                        <a className='nav' href={"mailto:help@pronetin.com"} target="_blank" rel="noreferrer">
                                            <i className="fa-regular fa-envelope"></i>
                                            Contact
                                        </a>
                                    </div>
                                    :
                                    <div className='nav-container'>
                                        <p>Certificate not yet accepted</p>
                                    </div>
                                }
                            </div>
                            <div className='wrapper'>
                                <button className='recenter-certificate' onClick={this.recenterCertificate}><i className="fa-solid fa-arrows-to-circle"></i></button>
                                <div className='cropper-rel'>
                                    {this.state.certView && (
                                        <div className='image-container'>
                                            <div className={this.state.sliderClass} style={{ '--scale-width': this.state.scaleWidth, transformOrigin: "top left" }}>
                                                <Draggable position={{ x: this.state.resetX, y: this.state.resetY }} onStop={this.handleCertDragStop}>
                                                    <div className='cert-container' id="capture" style={{ 'border': '1px solid #157EFB', width: this.state.certWidth, height: this.state.certHeight }}>
                                                        <img src={"https://pronetin-new.s3.amazonaws.com/batch/00000000-0000-0000-0000-000000000000/" + encodeURIComponent(this.state.cert_bg) + "?cacheblock=" + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template'></img>
                                                        <div className='elements'>
                                                            {this.state.elements && this.state.elements.length ?
                                                                this.state.elements.map((element, index) => {
                                                                    if (element.type === "image") {
                                                                        return <ViewImage element_id={index} key={element.timeStamp + "-" + index} data={element} />
                                                                    } else if (element.type === "table") {
                                                                        return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else if (element.type === "signature") {
                                                                        return <ViewSignee element_id={index} key={element.timeStamp + "-" + index} data={element} node_server_axios={this.props.node_server_axios} />
                                                                    } else {
                                                                        return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    }
                                                                })
                                                                : ""}
                                                        </div>
                                                        <ViewQR data={this.props.documentId} size={this.state.QRSize} />
                                                    </div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="mob-actions mobile-only">
                                    {this.state.accepted === 'true' ?
                                        <div className='nav-container'>
                                            <a className='nav' href="foo" onClick={this.downloadCertificate}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                            {/* <a className='nav' href={"https://" + this.state.domain} target="_blank" rel="noreferrer">
                                                <i className="fa-solid fa-link"></i>
                                                Website
                                            </a>
                                            <a className='nav' href={"mailto:" + this.state.contact} target="_blank" rel="noreferrer">
                                                <i className="fa-regular fa-envelope"></i>
                                                Contact
                                            </a> */}
                                            <a className='nav' href={"https://pronetin.com"} target="_blank" rel="noreferrer">
                                                <i className="fa-solid fa-link"></i>
                                                Website
                                            </a>
                                            <a className='nav' href={"mailto:help@pronetin.com"} target="_blank" rel="noreferrer">
                                                <i className="fa-regular fa-envelope"></i>
                                                Contact
                                            </a>
                                        </div>
                                        :
                                        <div className='nav-container'>
                                            <p>Certificate not yet accepted</p>
                                        </div>
                                    }
                                </div>
                                <div className='cropper-zoom'>
                                    <div className='container'>
                                        <p>Zoom</p>
                                        <input type="range" min="1" max="7" className="slider" defaultValue={this.state.sliderValue} onChange={this.updateZoom}></input>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default CertificateViewerComponent;