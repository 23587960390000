import React from 'react';
import Draggable from 'react-draggable';
import '../css/element.css';
import JohnDoe from '../../images/signature-image-john-doe.png'

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleDragStop.bind(this);
        this.outsideClickRef = React.createRef(null)
        this.clickTimeout = null
        this.state = {
            font: false,
            image: this.props.data,
            notDraggable: false,
            controls: false,
            id: this.props.element_id,
            toDelete: false,
            x: this.props.data.x,
            y: this.props.data.y,
            textarea: {
                height: this.props.data.elHeight,
                width: this.props.data.elWidth,
            },
            zIndex: this.props.data.zIndex,
            enableGrid: this.props.enableGrid,
            gridLevel: parseInt(this.props.grid_level) * 10,
        };
        this.divRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            image: props.data,
            id: state.id,
            zIndex: props.data.zIndex,
            enableGrid: props.enableGrid,
            gridLevel: parseInt(props.grid_level) * 10,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.last_selected_element === this.state.id) {
            this.findGrid(0, { x: this.state.image.x, y: this.state.image.y })
        }
        if (this.props.data.x !== prevProps.data.x) {
            if (this.props.data.x !== this.state.x) {
                this.setState({
                    x: this.props.data.x,
                    y: this.props.data.y,
                })
            }
        }
    }

    hideControls = () => {
        this.setState({
            controls: false,
            notDraggable: false,
            zIndex: this.props.element_id,
        })
    }

    handleSendToParent = (e) => {
        if (e.currentTarget.id === "delete" && this.state.toDelete !== 'required') {
            this.setState({
                toDelete: this.state.image.timeStamp,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        const divElement = this.divRef.current;
        if (divElement) {
            const height = divElement.clientHeight;
            const width = divElement.clientWidth;
            this.setState({
                textarea: {
                    height: height,
                    width: width,
                }
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.state.controls && !this.outsideClickRef.current.contains(e.target)) {
            this.hideControls();
        }
    }

    handleDragStop = (e, dragElement) => {
        this.setState({
            x: dragElement.x,
            y: dragElement.y,
        }, () => {
            this.props.fetchChildDrag(this.state)
        })
    };

    handleHistory = (event, dragElement) => {
        this.props.fetchHistory(this.state.id)
    };

    handleClicks = (e) => {
        if (this.clickTimeout !== null && !this.state.image.required) {
            this.setState({
                controls: true,
                notDraggable: true,
                zIndex: 999,
            });
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else if (!this.state.notDraggable) {
            this.clickTimeout = setTimeout(() => {
                e.preventDefault()
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 500)
        }
    }

    handleRemoveCenterGrid = () => {
        setTimeout(() => {
            this.props.fetchCenterGrid(false, false)
        }, 1000);
    }

    findGrid = (event, dragElement) => {
        let centerGridX = false;
        let centerGridY = false;
        if (Math.ceil(parseInt(dragElement.x + (parseInt(this.state.textarea.width / 2))) / 2) === this.props.certWidth / 4) {
            centerGridX = true
        }
        if (Math.ceil(parseInt(dragElement.y + (parseInt(120 / 2))) / 2) === this.props.certHeight / 4) {
            centerGridY = true
        }
        this.props.fetchCenterGrid(centerGridX, centerGridY)
    }

    render() {
        return (
            <Draggable position={{ x: this.state.image.x, y: this.state.image.y }} bounds="parent" disabled={this.state.notDraggable} cancel={this.state.notDraggable ? '.imagearea,select,button' : 'select,button'} onStop={this.handleDragStop} grid={this.state.enableGrid ? [this.state.gridLevel, this.state.gridLevel] : null} onStart={this.handleHistory} onDrag={this.findGrid} scale={this.state.enableGrid ? 1 : this.props.certificate_zoom}>
                <div className={this.state.notDraggable ? 'box pinned' : 'box'} ref={this.outsideClickRef} style={{ zIndex: this.state.zIndex, mixBlendMode: "multiply", pointerEvents: this.props.move_canvas ? "all" : "none" }} id={"box" + this.state.image.timeStamp}>
                    {this.state.controls && (
                        <div className='variable-controls'>
                            {
                                this.state.image.required ? "" : <button className='delete' id="delete" onClick={this.handleSendToParent}><i className="fa-solid fa-trash pe-none"></i></button>
                            }
                        </div>
                    )}
                    <div className="wrap no-pull-tab">
                        {/* <div className="pull-tab"></div> */}
                        <div className={this.state.image.shape !== undefined ? 'imagearea ' + this.state.image.shape : 'imagearea'}
                            style={{
                                resize: "none",
                            }}
                            onMouseDown={this.handleClicks}
                            onMouseUp={this.handleRemoveCenterGrid}
                        >
                            {
                                <div className='sign-container' ref={this.divRef}>
                                    <div className='img-placeholder'>
                                        <img src={JohnDoe} alt="sign in certificate"></img>
                                    </div>
                                    <h1>John Doe</h1>
                                    <h2>{this.state.image.signee_designation}</h2>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    }
}

export default ElementComponent;