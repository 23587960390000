import React from 'react';
import './css/element.css';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href).hostname + `/certificate/${this.props.data.document_id}`,
            title: this.props.data.title,
            description: 'Document received for ' + this.props.data.event + ' on ' + this.props.data.date,
            moreShareOptions: false
        };
        this.handleShare = this.handleShare.bind(this);
        this.containerRef = React.createRef();
        this.textElementRef = React.createRef();
    }

    openTile = () => {
        window.location = `/viewer/${this.props.data.document_id}`
    }

    handleShare(event) {
        event.preventDefault();
        const { url, title, description } = this.state;
        const socialMedia = event.currentTarget.getAttribute('data-network');
        const shareUrl = getShareUrl(socialMedia, url, title, description);
        window.open(shareUrl, '_blank', 'width=550,height=450');
    }

    toggleMoreShareOptions = () => {
        this.setState({
            moreShareOptions: !this.state.moreShareOptions,
        })
    }

    componentDidMount() {
        const container = this.containerRef.current;
        const textElement = this.textElementRef.current;

        if (textElement.scrollWidth > container.clientWidth) {
            textElement.classList.add('scrolling');
        } else {
            textElement.classList.remove('scrolling');
        }
    }

    formatNumberWithKAndM = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }

    render() {
        const document = this.props.data;
        const trial_period = 365;
        const parts = document.date.split('/');
        const year = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[0], 10);

        let issue_date = new Date(year, month, day);
        let current_date = new Date();
        let trial = "Certificate has expired"
        let days_left = trial_period - ((current_date.getTime() - issue_date.getTime()) / (1000 * 3600 * 24));
        if (days_left > 0 && days_left < 365) {
            trial = "Expires in " + Math.floor(days_left) + " days";
        }
        if (this.props.data.document_id !== null) {
            return (
                <div className='tile-wrapper'>
                    <div className='tile' onClick={this.openTile}>
                        <div className={document.view_type === "public" ? 'view-option public' : 'view-option private'}>
                            <div className='view'>{document.view_type}</div>
                            {document.view_type === "public" ? <i className="fa-solid fa-lock-open"></i> : <i className="fa-solid fa-lock"></i>}
                        </div>
                        <h1 ref={this.containerRef}><span ref={this.textElementRef}>{document.event}</span><label>{document.date}</label></h1>
                        <p>{document.title}</p>
                        <div className='organisation'>{document.issuer_name}</div>
                        <div className='bottom'>
                            <div className='trial'><i className="fa-solid fa-stopwatch"></i> {trial}</div>
                            <div className='views'><i className="fa-solid fa-eye"></i> {this.formatNumberWithKAndM(document.view_count)}</div>
                        </div>
                    </div>
                    <div className='action'>
                        {document.view_type !== 'private' ?
                            <div className='icon-btn'>
                                {/* <button><i className="fa-solid fa-download"></i></button> */}
                                {this.state.moreShareOptions ?
                                    <button onClick={this.toggleMoreShareOptions}><i className="fa-solid fa-x"></i></button>
                                    :
                                    <button onClick={this.toggleMoreShareOptions}><i className="fa-solid fa-share"></i></button>
                                }
                                <button onClick={this.handleShare} data-network="linkedin"><i className="fa-brands fa-linkedin-in"></i></button>
                                {this.state.moreShareOptions && (
                                    <button onClick={this.handleShare} data-network="facebook"><i className="fa-brands fa-facebook-f"></i></button>
                                )}
                                {this.state.moreShareOptions && (
                                    <button onClick={this.handleShare} data-network="twitter"><i className="fa-brands fa-twitter"></i></button>
                                )}
                            </div>
                            :
                            <div className='icon-btn'>
                                <button className='disabled'><i className="fa-solid fa-share"></i></button>
                            </div>
                        }
                        {!this.state.moreShareOptions && (
                            <a href={`/viewer/${this.props.data.document_id}`}>View Certificate</a>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className='tile-wrapper no-hover'>
                    <div className='tile grayed' onClick={this.openTile}>
                        <div className='view-option comingsoon'>
                            <div className='view'>Unlocking Soon</div>
                            <i className="fa-solid fa-lock"></i>
                        </div>
                        <h1 ref={this.containerRef}><span ref={this.textElementRef}>Will be available on</span><label>{document.date}</label></h1>
                        <p>{document.event}</p>
                        <div className='organisation'>{document.issuer_name}</div>
                        <div className='bottom'>
                            <div className='trial'>{document.issuer_name} has scheduled this certificate to be unlocked automatically on {document.date}</div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function getShareUrl(network, url, title, description) {
    switch (network) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
        case 'twitter':
            return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
        case 'linkedin':
            return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`;
        default:
            return null;
    }
}

export default TileComponent;