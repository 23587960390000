import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';

class MyDesignComponent extends React.Component {
    constructor(props) {
        super(props);
        this.textareaRef = React.createRef();
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            editorMode: false,
            templates: [],
            uniqueCategory: [],
            filteredEvent: false,
            filteredEventValue: '',
            moreBtn: 'more',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    componentDidMount() {
        if (this.state.editorMode) {
            this.resizeTextarea();
        }
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'fetch_email_templates', {
            params: {
                //Future when organisation specific custom templates can be created
                org_id: this.props.org_id,
            },
        }).then(res => {
            if (res.data !== undefined) {
                this.setState({
                    templates: res.data.map((obj, index) => { return { ...obj, index: index }; }),
                    uniqueCategory: Array.from(new Set(res.data.map(element => element.category)))
                })
            }
        });
    }

    componentDidUpdate() {
        if (this.state.editorMode) {
            this.resizeTextarea();
        }
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    toggleEditorMode = (index) => {
        this.setState({
            editorMode: !this.state.editorMode,
            selectedTemplate: this.state.templates[index],
        })
    }

    resizeTextarea = () => {
        this.textareaRef.current.style.height = 'auto';
        this.textareaRef.current.style.height = this.textareaRef.current.scrollHeight + 'px';
    }

    setTemplate = (template_id) => {
        axios.post(this.props.node_server_axios + 'update_email_template', {
            params: {
                org_id: this.props.org_id,
                event: this.props.event,
                subevent: this.props.subevent,
                template_id: template_id,
            },
        }).then(res => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    window.location.href = "../../../subevents/" + this.props.event + '/' + this.props.subevent;
                }
            }
        });
    }

    filterEvent = (e, element) => {
        e.preventDefault();
        this.setState({
            filteredEventValue: element,
            filteredEvent: true,
            editorMode: false,
        })
        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    clearFilter = () => {
        this.setState({
            filteredEventValue: '',
            filteredEvent: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Email Templates<label>View all your email templates</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-envelopes-bulk"></i> Categories</h1>
                                <div className='nav-container'>
                                    {
                                        this.state.uniqueCategory.map((element, index) => {
                                            return (
                                                <a className='nav' href="foo" key={index} onClick={(e) => { this.filterEvent(e, element) }}>
                                                    <i className="fa-regular fa-folder-open"></i>
                                                    {element}
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                                <div className='flex-grow'></div>
                                {/* <div className='plan-container'>
                                    <h1>Custom Template</h1>
                                    <button>Create New <i className="fa-solid fa-plus"></i></button>
                                    <p><i className="fa-solid fa-circle-info"></i> Custom templated may be rejected if used for marketing / malicious purposes</p>
                                </div> */}
                            </div>
                            {!this.state.filteredEvent ?
                                !this.state.editorMode ?
                                    <div className='wrapper'>
                                        {!this.props.event && !this.props.subevent && (
                                            <div className='explanation-wrapper'>
                                                <p className='explanation-content'>You may choose/change these templates during the process of issuing certificates from inside a batch. <a href="/help#19_Organisations" target='_blank'>Learn what is a batch</a></p>
                                                <a href='/organisation' className='explanation-btn'>Go to Batches <i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        )}
                                        <p><i className="fa-regular fa-envelope"></i> All Email Templates</p>
                                        {
                                            this.state.uniqueCategory.map((element, index) => {
                                                return (
                                                    <div className='event-wrapper' key={index}>
                                                        <div className='event-name-container'>
                                                            <p key={index}><i className="fa-regular fa-folder-open"></i> {element}</p>
                                                        </div>
                                                        <div className='template-wrapper'>
                                                            {this.state.templates.filter(obj => obj.category === element).map((elements, index) =>
                                                                <div className='template-subevent' key={index} onClick={() => { this.toggleEditorMode(elements.index) }}>
                                                                    <div className='subevent'>{elements.category}</div>
                                                                    <div className='data'>
                                                                        <div className='content'>
                                                                            <p className='fine-text'>{elements.body.replace(/<br\s*[/]?>/gi, '')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='btn-container'>
                                                                        <div className='btn modified-on'><label>Modified On</label><p>{elements.modified_on}</p></div>
                                                                        {this.props.event && this.props.subevent ?
                                                                            <button className='btn'>Continue</button>
                                                                            :
                                                                            <button className='btn'>View</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    :
                                    <div className='wrapper'>
                                        <p>Header</p>
                                        <div className='email-editor'>
                                            <input type="text" name='header' placeholder='Header' defaultValue={this.state.selectedTemplate.header} readOnly />
                                        </div>
                                        <p><i className="fa-solid fa-lock"></i> Logo</p>
                                        <div className='logo-wrapper'>
                                            <img src={require('../images/static/Group 11.png')} alt="pronetin" />
                                        </div>
                                        <p>Subject</p>
                                        <div className='email-editor'>
                                            <input type="text" name='subject' placeholder='Subject' defaultValue={this.state.selectedTemplate.subject} readOnly />
                                        </div>
                                        <p>Body</p>
                                        <div className='email-editor'>
                                            <textarea type="text" name='body' placeholder='Body' ref={this.textareaRef} onInput={this.resizeTextarea} defaultValue={this.state.selectedTemplate.body.replace(/<br\s*\/?>/g, "\n")}>
                                            </textarea>
                                        </div>
                                        <p><i className="fa-solid fa-lock"></i> Footer</p>
                                        <div className='email-template-btn-container'>
                                            <button className='back-btn' onClick={this.toggleEditorMode}><i className="fa-solid fa-arrow-left-long"></i> Back to Templates</button>
                                            {this.props.event && this.props.subevent && (
                                                <button onClick={() => { this.setTemplate(this.state.selectedTemplate.template_id) }}>Use this template <i className="fa-regular fa-floppy-disk"></i></button>
                                            )}
                                        </div>
                                    </div>
                                :
                                !this.state.editorMode ?
                                    <div className='wrapper'>
                                        <p><i className="fa-regular fa-envelope"></i> All Email Templates</p>
                                        <div className='template-wrapper'>
                                            {this.state.templates.filter(obj => obj.category === this.state.filteredEventValue).map((elements, index) =>
                                                <div className='template-subevent' key={index} onClick={() => { this.toggleEditorMode(elements.index) }}>
                                                    <div className='subevent'>{elements.category}</div>
                                                    <div className='data'>
                                                        <div className='content'>
                                                            <p className='fine-text'>{elements.body.replace(/<br\s*[/]?>/gi, '')}</p>
                                                        </div>
                                                    </div>
                                                    <div className='btn-container'>
                                                        <div className='btn modified-on'><label>Modified On</label><p>{elements.modified_on}</p></div>
                                                        {this.props.event && this.props.subevent ?
                                                            <button className='btn'>Continue</button>
                                                            :
                                                            <button className='btn'>View</button>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <button onClick={this.clearFilter}>View All Templates</button>
                                    </div>
                                    :
                                    <div className='wrapper'>
                                        <p>Header</p>
                                        <div className='email-editor'>
                                            <input type="text" name='header' placeholder='Header' defaultValue={this.state.selectedTemplate.header} readOnly />
                                        </div>
                                        <p><i className="fa-solid fa-lock"></i> Logo</p>
                                        <div className='logo-wrapper'>
                                            <img src={require('../images/static/Group 11.png')} alt="pronetin" />
                                        </div>
                                        <p>Subject</p>
                                        <div className='email-editor'>
                                            <input type="text" name='subject' placeholder='Subject' defaultValue={this.state.selectedTemplate.subject} readOnly />
                                        </div>
                                        <p>Body</p>
                                        <div className='email-editor'>
                                            <textarea type="text" name='body' placeholder='Body' ref={this.textareaRef} onInput={this.resizeTextarea} defaultValue={this.state.selectedTemplate.body.replace(/<br\s*\/?>/g, "\n")}>
                                            </textarea>
                                        </div>
                                        <p><i className="fa-solid fa-lock"></i> Footer</p>
                                        <div className='email-template-btn-container'>
                                            <button className='back-btn' onClick={this.toggleEditorMode}><i className="fa-solid fa-arrow-left-long"></i> Back to Templates</button>
                                            {this.props.event && this.props.subevent && (
                                                <button onClick={() => { this.setTemplate(this.state.selectedTemplate.template_id) }}>Use this template <i className="fa-regular fa-floppy-disk"></i></button>
                                            )}
                                        </div>
                                    </div>
                            }

                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MyDesignComponent;