import React from 'react';
import axios from 'axios';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/element';
import Filter from '../components/filter';
import Loading from '../components/loading';

import '../components/css/main.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
            UserInfoCollect: false, //Popup modal for collecting user info
            label_1: "Role",
            label_2: "Part of",
            placeholder_1: "Please Specify Your Role",
            placeholder_2: "Organisation Name If Any",
            MoreUserInfo: false, //More info section after selecting user category from modal
            UserBackground: false, //User category selection
            position: '', //User position selection
            organisation_name: '', //User organisation selection
            error: false, //Error display for modal
        }
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    loading: false,
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    user_info: JSON.parse(res.data.user_info),
                    UserInfoCollect: this.getLocalStorageItemWithExpiry('user_info_skip') === null ? true : false,
                    header_user_data: res.data,
                    storage: res.data.storage,
                });
            } else {
                window.location = "/home";
            }
        }).catch(() => {
            window.location = "/signin";
        })
    }

    setLocalStorageItemWithExpiry = (key, value, expiryInMs) => {
        const now = new Date().getTime();
        const item = {
            value: value,
            expiry: now + expiryInMs,
        };
        localStorage.setItem(key, JSON.stringify(item));
        this.setState({
            UserInfoCollect: false,
        });
    };

    // Function to get an item from localStorage, checking if it has expired
    getLocalStorageItemWithExpiry = (key) => {
        const item = JSON.parse(localStorage.getItem(key));
        if (!item) {
            return null; // Item not found in localStorage
        }
        const now = new Date().getTime();
        if (now >= item.expiry) {
            localStorage.removeItem(key); // Remove the item if it has expired
            return null;
        }
        return item.value;
    };

    skipUserInfo = () => {
        this.setLocalStorageItemWithExpiry('user_info_skip', 'true', 43200000)
        this.setState({
            UserInfoCollect: false,
            MoreUserInfo: false,
            UserBackground: false,
        });
    }

    industries = [
        "Aerospace and Aviation",
        "Agriculture and Agribusiness",
        "Architecture and Design",
        "Art and Creative",
        "Automotive",
        "Biotechnology",
        "Chemical and Petrochemical",
        "Civil Engineering and Construction",
        "Computer Science",
        "Consulting",
        "Education",
        "Education Technology (EdTech)",
        "Electrical",
        "Electronics",
        "Energy and Utilities",
        "Entertainment and Media",
        "Environmental and Sustainability",
        "Fashion and Apparel",
        "Financial Services",
        "Food and Beverage",
        "Gaming and Interactive Entertainment",
        "Government and Public Administration",
        "Information Technology (IT)",
        "Legal and Law Services",
        "Machine Learning (ML)",
        "Manufacturing",
        "Marine and Maritime",
        "Mechanical Engineering",
        "Medical and Healthcare",
        "Mining and Natural Resources",
        "Nonprofit and Social Services",
        "Pharmaceuticals",
        "Real Estate and Property Development",
        "Renewable Energy",
        "Research and Development",
        "Retail",
        "Robotics",
        "Social Media and Digital Marketing",
        "Sports and Fitness",
        "Telecommunications",
        "Tourism and Hospitality",
        "Transportation and Logistics",
        "Quantum Computing"
    ];

    students = [
        "Play School",
        "Kindergarten",
        "Primary",
        "Upper Primary",
        "High School",
        "Higher Secondary",
        "Home Schooling",
        "Under Graduate",
        "Post Graduate",
        "Post-Doctoral Studies."
    ];

    chooseUserInfo = (value) => {
        switch (value) {
            case 'student':
                this.setState({
                    label_1: "In the field of",
                    label_2: "Studying at",
                    placeholder_1: this.students.concat(this.industries),
                    placeholder_2: "Institute Name",
                    MoreUserInfo: true,
                    UserBackground: value,
                    position: "School",
                    error: false,
                });
                break;
            case 'professional':
                this.setState({
                    label_1: "In the field of",
                    label_2: "Working at",
                    placeholder_1: this.industries,
                    placeholder_2: "Organisation / Company Name",
                    MoreUserInfo: true,
                    UserBackground: value,
                    position: "Developer",
                    error: false,
                });
                break;
            default:
                this.setState({
                    label_1: "Role",
                    label_2: "Part of",
                    placeholder_1: "Please Specify Your Role",
                    placeholder_2: "Organisation Name If Any",
                    MoreUserInfo: true,
                    UserBackground: value,
                    error: false,
                });
                break;
        }
    }

    changePosition = (e) => {
        this.setState({
            position: e.target.value,
            error: false,
        })
    }
    changeOrganisationName = (e) => {
        this.setState({
            organisation_name: e.target.value,
            error: false,
        })
    }

    submitUserInfo = () => {
        if (this.state.UserBackground !== false && this.state.position !== '' && this.state.organisation_name !== '') {
            this.setState({
                UserInfoCollect: false,
            });
            axios.post(this.props.node_server_axios + 'update_user_info', {
                params: {
                    email: this.state.email,
                    user_category: this.state.UserBackground,
                    position: this.state.position,
                    organisation_name: this.state.organisation_name,
                }
            }).then((res) => {
                if (res.data.message === 'updated') {
                    localStorage.setItem('user_info_skip', 'true');
                }
            });
        } else {
            this.setState({
                error: "Please complete the form before submit.",
            })
        }
    }

    sidenavType = (e) => {
        this.setState({
            sidenavType: e,
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header />
                        <Loading />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="main index">
                        <Header login={true} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} sidenavType={this.sidenavType} />
                        <div className='body-wrapper'>
                            <SideNav name={this.state.name} email={this.state.email} selected={"allDocuments"} user_type={this.state.user_type} node_server_axios={this.props.node_server_axios} storage={this.state.storage} sidenav_type={this.state.sidenavType} sidenavType={this.sidenavType} />
                            <Element email={this.state.email} user_type={this.state.user_type} node_server_axios={this.props.node_server_axios} />
                            {this.state.user_type === 'organisation' && (
                                <Filter email={this.state.email} user_type={this.state.user_type} node_server_axios={this.props.node_server_axios} />
                            )}
                        </div>

                        {(this.state.user_info === null || this.state.user_info.user_category === undefined) && this.state.UserInfoCollect && (
                            <div className='intro-user-data'>
                                <div className='wrapper'>
                                    <h2>Kindly let us know more about you</h2>
                                    <p>What is your background?</p>
                                    <div className={this.state.UserBackground === 'student' ? 'container active' : 'container'} onClick={() => this.chooseUserInfo('student')}>
                                        <i className="fa-solid fa-graduation-cap"></i>
                                        Student
                                    </div>
                                    <div className={this.state.UserBackground === 'professional' ? 'container active' : 'container'} onClick={() => this.chooseUserInfo('professional')}>
                                        <i className="fa-solid fa-user-tie"></i>
                                        Professional
                                    </div>
                                    <div className={this.state.UserBackground === 'others' ? 'container active' : 'container'} onClick={() => this.chooseUserInfo('others')}>
                                        <i className="fa-solid fa-person-circle-question"></i>
                                        Others
                                    </div>
                                    {this.state.MoreUserInfo && (
                                        <div className='more-user-info'>
                                            <hr></hr>
                                            <p>{this.state.label_1}</p>
                                            {Array.isArray(this.state.placeholder_1) ?
                                                <select onChange={this.changePosition}>
                                                    {this.state.placeholder_1.map((element, index) =>
                                                        <option value={element} key={index}>{element}</option>
                                                    )}
                                                </select>
                                                :
                                                <input type="text" placeholder={this.state.placeholder_1} onChange={this.changePosition} required />
                                            }
                                            <p>{this.state.label_2}</p>
                                            <input type="text" placeholder={this.state.placeholder_2} onChange={this.changeOrganisationName} required />
                                            {this.state.error && (<p className='error'>{this.state.error}</p>)}
                                            <button onClick={this.submitUserInfo}>Submit <i className="fa-solid fa-arrow-right"></i></button>
                                        </div>
                                    )}
                                    <button className='skip' onClick={this.skipUserInfo}>Skip</button>
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        }
    }
}
export default Index;