import React from 'react';
import './css/signin.css';
// import SigninIcon from './resources/lf-1.png';
// import GoogleButton from './googleSigin';
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            confpassword: '',
            isError: {
                name: '',
                email: '',
                password: '',
                confpassword: '',
            },
            response: '',
            warningActive: '',
            showPassword: false,
            showConfPassword: false,
            signingup: false,
        };
    }

    checkValidation = (e) => {
        var regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var regPassword = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).+$/;
        const { name, value } = e.target;
        let isError = { ...this.state.isError };
        let pass = { ...this.state };

        //Resets warning on any field input change
        this.setState({
            response: '',
            warningActive: false
        });

        switch (name) {
            case "name":
                isError.name =
                    value.length < 3 ? "Atleast 3 characaters required" : "";
                break;
            case "email":
                isError.email = value.length > 0 ? regEmail.test(value) ? "" : "Enter a valid email id" : "Email field cannot be empty";
                break;
            case "password":
                isError.password = value.length < 8 ? "Atleast 8 characaters required" : "";
                if (isError.password === "") {
                    isError.password = !regPassword.test(value) ? "Password should contain \n 1. Alphabet \n 2. Number \n 3. Special Character" : "";
                }
                document.getElementById('confpassword').value = '';
                break;
            case "confpassword":
                isError.confpassword = pass.password === value ? "" : "Passwords do not match";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            signingup: true
        })
        localStorage.removeItem('onboarding_index');

        if (this.state.isError.password === "") {
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'signup', {
                params: {
                    data: {
                        email: event.target.email.value.toLowerCase().replace(/\s+/g, ''),
                        name: event.target.name.value,
                        password: event.target.password.value,
                        confpassword: event.target.confpassword.value,
                    },
                    error: this.state.isError
                },
            }).then(res => {
                if (res.data.message !== '') {
                    this.setState({ response: res.data.message, warningActive: true, signingup: false })
                }
                if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                    window.location = res.data.redirect;
                }
            });
        } else {
            this.setState({ response: "invalid password", warningActive: true, signingup: false })
        }
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    }

    toggleConfPasswordVisibility = () => {
        this.setState(prevState => ({
            showConfPassword: !prevState.showConfPassword,
        }));
    }

    render() {
        const { isError } = this.state;
        return (
            <React.Fragment>
                <header className="wrapper">
                    <form method='POST' onSubmit={this.handleSubmit}>
                        <div className="container">
                            {/* <img src={SigninIcon} className="userimage" alt="user signin"></img> */}
                            <h1>Get Started</h1>
                            <div className='relative block w-100 flex-cr'>
                                {isError.name.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.name}</span></div>
                                )}
                                <input type="text" name='name' className={this.state.response !== '' && this.state.name === '' ? 'error capitalize' : 'capitalize'} placeholder="John Doe" onChange={this.checkValidation} />
                                <label>Full Name</label>
                            </div>
                            <div className='relative block w-100 flex-cr'>
                                {isError.email.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.email}</span></div>
                                )}
                                <input type="email" name='email' className={this.state.response !== '' && this.state.email === '' ? 'error lowercase' : 'lowercase'} placeholder="johndoe@pronetin.com" onChange={this.checkValidation} />
                                <label>Email</label>
                            </div>
                            <div className='relative block w-100 flex-cr'>
                                {isError.password.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.password}</span></div>
                                )}
                                <div className='password-visibility' onClick={this.togglePasswordVisibility}>{this.state.showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                <input type={this.state.showPassword ? 'text' : 'password'} name='password' autoComplete='on' placeholder="********" className={this.state.response !== '' && this.state.password === '' ? 'error' : ''} onChange={this.checkValidation} onKeyDown={(e) => e.key === " " && e.preventDefault()} />
                                <label>Password</label>
                            </div>
                            {this.state.password.length > 0 && (
                                <div className='password-warns'>
                                    <p>{isError.password === 'Atleast 8 characaters required' ? <i className="fa-solid fa-circle-xmark"></i> : <i className="fa-solid fa-circle-check"></i>} Atleast 8 Character</p>
                                    <p>{/[a-zA-Z]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Alphabet</p>
                                    <p>{/[0-9]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Number</p>
                                    <p>{/[\W_]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Special Character</p>
                                </div>
                            )}
                            <div className='relative block w-100 flex-cr'>
                                {isError.confpassword.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.confpassword}</span></div>
                                )}
                                <div className='password-visibility' onClick={this.toggleConfPasswordVisibility}>{this.state.showConfPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                <input type={this.state.showConfPassword ? 'text' : 'password'} name='confpassword' autoComplete='on' placeholder="********" id='confpassword' className={this.state.response !== '' && this.state.confpassword === '' ? 'error' : ''} onChange={this.checkValidation} onKeyDown={(e) => e.key === " " && e.preventDefault()} />
                                <label>Confirm Password</label>
                            </div>
                            <p className={this.state.warningActive ? 'warning block' : 'warning none'}><i className='fa-solid fa-circle-exclamation'></i> {this.state.response}</p>
                            <p className='signup-policy'>By Signing up you agree to our Terms & Privacy Policy</p>
                            {this.state.signingup ?
                                <button type='button'>Signing Up...</button>
                                :
                                <button type='submit'>Sign Up</button>
                            }
                            <p>Have an account?&nbsp;&nbsp;<a className='secondary' href='/signin'>Sign in <i className="fa-solid fa-arrow-right-to-bracket"></i></a></p>
                            <p><a href='foo'>Introduction</a> . <a href='/help' target='_blank'>Help</a> . <a href='./privacypolicy' target='_blank'>Privacy Policy</a></p>
                            {/* <div id="googleButton"><GoogleButton /></div> */}
                        </div>
                    </form>
                </header>
            </React.Fragment>
        );
    }
};

export default App;