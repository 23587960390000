import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages';
import Landing from './pages/home';
import MyDocuments from './pages/mydocuments';
import Signin from './pages/signin';
import Signup from './pages/signup';
import Issue from './pages/issue';
import Marketplace from './pages/marketplace';
import MyDesign from './pages/mydesign';
import MySignature from './pages/mysignature';
import Profile from './pages/profile';
import Settings from './pages/settings';
import Viewer from './pages/viewer';
import Appreciation from './pages/appreciation';
import ViewerPublic from './pages/public';
import AppreciationPublic from './pages/appreciation_public';
import ViewerDoc from './pages/viewerdoc';
import Approvals from './pages/approvals';
import Organisation from './pages/organisation';
import Email from './pages/email';
import Subevents from './pages/subevents';
import Batch from './pages/batch';
import Create from './pages/create';
import EditSaved from './pages/editsaved';
import Verify from './pages/verify';
import TransferConsent from './pages/transfer_consent';
import Premium from './pages/premium';
import VerifyQR from './pages/verifyqr';
import Forgot from './pages/forgot';
import Reset from './pages/reset';
import PrivacyPolicy from './pages/privacypolicy';
import Tos from './pages/tos';
import RefundPolicy from './pages/refundpolicy';
import Help from './pages/help';
import Kicked from './pages/kicked';
import ActionCenter from './pages/action_center';
import Buy from './pages/buy';
import ActivityLogs from './pages/activity_logs';
import DownloadBatch from './pages/download_batch';
import Completed from './pages/completed';

function App(props) {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home node_server_axios={props.node_server_axios} />} />
                <Route path='/home' element={<Landing node_server_axios={props.node_server_axios} />} />
                <Route path='/mydocuments' element={<MyDocuments node_server_axios={props.node_server_axios} />} />
                <Route path='/signin' element={<Signin node_server_axios={props.node_server_axios} />} />
                <Route path='/signup' element={<Signup node_server_axios={props.node_server_axios} />} />
                <Route path='/issue' element={<Issue node_server_axios={props.node_server_axios} />} />
                <Route path='/issue/:slug' element={<Issue node_server_axios={props.node_server_axios} />} />
                <Route path='/profile' element={<Profile node_server_axios={props.node_server_axios} />} />
                <Route path='/settings' element={<Settings node_server_axios={props.node_server_axios} />} />
                <Route path='/marketplace' element={<Marketplace node_server_axios={props.node_server_axios} />} />
                <Route path='/mydesign' element={<MyDesign node_server_axios={props.node_server_axios} />} />
                <Route path='/mysignature' element={<MySignature node_server_axios={props.node_server_axios} />} />
                <Route path='/viewer/:slug' element={<Viewer node_server_axios={props.node_server_axios} />} />
                <Route path='/appreciation/:slug' element={<Appreciation node_server_axios={props.node_server_axios} />} />
                <Route path='/doc/:email/:slug' element={<ViewerDoc node_server_axios={props.node_server_axios} />} />
                <Route path='/certificate/:slug' element={<ViewerPublic node_server_axios={props.node_server_axios} />} />
                <Route path='/awards/:slug' element={<AppreciationPublic node_server_axios={props.node_server_axios} />} />
                <Route path='/approvals' element={<Approvals node_server_axios={props.node_server_axios} />} />
                <Route path='/organisation' element={<Organisation node_server_axios={props.node_server_axios} />} />
                <Route path='/email/:slug/:subevent' element={<Email node_server_axios={props.node_server_axios} />} />
                <Route path='/email' element={<Email node_server_axios={props.node_server_axios} />} />
                <Route path='/subevents/:slug/:subevent' element={<Subevents node_server_axios={props.node_server_axios} />} />
                <Route path='/batch/:slug' element={<Batch node_server_axios={props.node_server_axios} />} />
                <Route path='/create' element={<Create node_server_axios={props.node_server_axios} />} />
                <Route path='/edit/:slug' element={<EditSaved node_server_axios={props.node_server_axios} />} />
                <Route path='/verify/:slug/:code' element={<Verify node_server_axios={props.node_server_axios} />} />
                <Route path='/transferconsent/:slug/:email/:code' element={<TransferConsent node_server_axios={props.node_server_axios} />} />
                <Route path='/premium' element={<Premium node_server_axios={props.node_server_axios} />} />
                <Route path='/v/:slug' element={<VerifyQR node_server_axios={props.node_server_axios} />} />
                <Route path='/forgot' element={<Forgot node_server_axios={props.node_server_axios} />} />
                <Route path='/reset/:slug/:otp' element={<Reset node_server_axios={props.node_server_axios} />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy node_server_axios={props.node_server_axios} />} />
                <Route path='/tos' element={<Tos node_server_axios={props.node_server_axios} />} />
                <Route path='/refundpolicy' element={<RefundPolicy node_server_axios={props.node_server_axios} />} />
                <Route path='/help' element={<Help node_server_axios={props.node_server_axios} />} />
                <Route path='/kicked' element={<Kicked node_server_axios={props.node_server_axios} />} />
                <Route path='/actioncenter' element={<ActionCenter node_server_axios={props.node_server_axios} />} />
                <Route path='/buy' element={<Buy node_server_axios={props.node_server_axios} />} />
                <Route path='/activitylogs' element={<ActivityLogs node_server_axios={props.node_server_axios} />} />
                <Route path='/download/:slug' element={<DownloadBatch node_server_axios={props.node_server_axios} />} />
                <Route path='/completed' element={<Completed node_server_axios={props.node_server_axios} />} />

                <Route path="/edit" element={<Navigate to="/" />} />
                <Route path="/viewer" element={<Navigate to="/" />} />
                <Route path="/batch" element={<Navigate to="/" />} />
                <Route path='/subevents/:slug' element={<Navigate to="/" />} />
                {/* Redirect all unknown routes to the home page */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
