import React from 'react';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            font: false,
            image: this.props.data,
        };
    }

    render() {
        return (
            <div className="box wrap viewer-wrap" style={{ top: this.state.image.y + "px", left: this.state.image.x + "px", zIndex: this.state.image.zIndex }}>
                <div className={this.state.image.shape !== undefined ? 'imagearea ' + this.state.image.shape : 'imagearea'}
                    style={{
                        resize: "none",
                        height: this.state.image.elHeight + "px",
                        width: this.state.image.elWidth + "px",
                    }}
                >
                    {
                        this.state.image.content === "shape" ? <div className='shape'></div> : <img src={this.state.image.content} alt="logo in certificate" crossOrigin='anonymous'></img>
                    }
                </div>
            </div>
        );
    }
}

export default ElementComponent;