import React from 'react';
import axios from 'axios';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/buy';
import Loading from '../components/loading';

import '../components/css/main.css';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
        }
    }

    componentDidMount = async () => {
        axios.defaults.withCredentials = true;
        await axios.post(this.props.node_server_axios + 'force_update_session');

        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    loading: false,
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    user_id: res.data.user_id,
                    storage: res.data.storage,
                    org_id: res.data.org_id,
                    header_user_data: res.data,
                });
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })
    }
    render() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header />
                        <Loading />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header login={true} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                        <div className='body-wrapper'>
                            <SideNav name={this.state.name} email={this.state.email} selected={"buystorage"} user_type={this.state.user_type} node_server_axios={this.props.node_server_axios} storage={this.state.storage} />
                            <Element email={this.state.email} name={this.state.name} user_type={this.state.user_type} user_id={this.state.user_id} storage={this.state.storage} node_server_axios={this.props.node_server_axios} org_id={this.state.org_id} />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}
export default Profile;