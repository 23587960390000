import './css/sidenav.css';
import axios from 'axios';

import React from 'react';
import coinIcon from '../images/static/Component 397 – 4.png';

import io from 'socket.io-client';

class SidenavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: [],
            approvals: 0,
            credits: 0,
            minNav: localStorage.getItem("minNav") === null ? false : JSON.parse(localStorage.getItem("minNav")),
            storage: this.props.storage,
        }
        this.socket = io(this.props.node_server_axios.replace("8080/api/", "8090"));
    }

    organisationRender = (value) => {
        if (value === 'organisation') {
            return (
                <div className='module'>
                    {!this.state.minNav ? <label>Organisation</label> : <label>Org</label>}
                    <a className={this.props.selected === 'myorganisation' ? 'nav selected' : 'nav'} href="/organisation">
                        <i className="fa-solid fa-paper-plane"></i>
                        {!this.state.minNav && ('Issue Certificate')}
                    </a>
                    <a className={this.props.selected === 'emailtemplate' ? 'nav selected' : 'nav'} href="/email">
                        <i className="fa-solid fa-envelope-open-text"></i>
                        {!this.state.minNav && ('Email Templates')}
                    </a>
                    <a className={this.props.selected === 'mydesign' ? 'nav selected' : 'nav'} href="/mydesign">
                        <i className="fa-solid fa-brush"></i>
                        {!this.state.minNav && ('My Designs')}
                    </a>
                    <a className={this.props.selected === 'marketplace' ? 'nav selected' : 'nav'} href="/marketplace">
                        <i className="fa-solid fa-shop"></i>
                        {!this.state.minNav && ('Marketplace')}
                    </a>
                    <a className={this.props.selected === 'actioncenter' ? 'nav selected' : 'nav'} href="/actioncenter">
                        <i className="fa-solid fa-list-check"></i>
                        {!this.state.minNav && ('Action Center')}
                    </a>
                    {/* <a className={this.props.selected === 'settings' ? 'nav selected' : 'nav'} href="/settings">
                        <i className="fa-solid fa-gear"></i>
                        {!this.state.minNav && ('Organisation Settings')}
                    </a> */}
                </div>
            );
        } else {
            return (
                <div className='module'>
                    {!this.state.minNav ? <label>Organisation</label> : <label>Org</label>}
                    <a className={this.props.selected === 'createorganisation' ? 'nav selected' : 'nav'} href="/create">
                        <i className="fa-solid fa-users"></i>
                        {!this.state.minNav && ('Create / Join Organisation')}
                    </a>
                </div>
            )
        }
    }

    componentDidMount() {
        if (this.props.user_type === 'organisation') {
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'session').then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                    this.setState({
                        name: res.data.name,
                        email: res.data.email,
                        user_type: res.data.user_type,
                        org_id: res.data.org_id,
                        storage: res.data.storage,
                    });

                    this.socket.emit('joinRoom', res.data.org_id);
                    this.socket.on('kicked', (data) => {
                        if (data === res.data.email) {
                            axios.post(this.props.node_server_axios + 'signout').then((res) => {
                                window.location = './kicked';
                            })
                        }
                    });

                    axios.post(this.props.node_server_axios + 'get_organisation_data', {
                        params: {
                            org_id: res.data.org_id,
                        }
                    }).then((res) => {
                        if (res.data !== undefined && res.data.message === 'success') {
                            localStorage.setItem('displyCredits', parseInt(res.data.credits));
                            this.setState({
                                loading: false,
                                credits: parseInt(res.data.credits),
                                per_credit: parseInt(res.data.per_credit),
                                running_plan: res.data.running_plan,
                                org_name: res.data.org_name,
                            });
                        } else {
                            window.location = "/signin";
                        }
                    })
                } else {
                    window.location = "/signin";
                }
            }).catch(() => {
                window.location = "/signin";
            })
        }

        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate() {
        if (this.props.sidenav_type === 'min' && !this.state.minNav) {
            this.setState({
                minNav: true,
            });
            this.props.sidenavType(false);
        }
    }

    componentWillUnmount() {
        this.socket.disconnect();
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        if (window.innerWidth <= 820) {
            this.setState({
                minNav: false,
            });
        }
        if (window.innerWidth > 820) {
            var mobileNavModal = document.getElementById('mobile-nav-modal');
            mobileNavModal.style.display = 'flex';
        }
    };

    toggleMenu = (e) => {
        e.preventDefault();
        var subNav = document.getElementsByClassName('cert-search')[0];
        if (subNav !== undefined) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
        }
        var mobileNavModal = document.getElementById('mobile-nav-modal');
        if (mobileNavModal.style.display !== 'flex') {
            mobileNavModal.style.display = 'flex';
        } else {
            mobileNavModal.style.display = 'none';
        }
    }

    toggleMinNav = () => {
        localStorage.setItem("minNav", !this.state.minNav);
        this.setState({
            minNav: !this.state.minNav,
        }, () => {
            if (this.props.minifiedNav && typeof this.props.minifiedNav === 'function') {
                this.props.minifiedNav(this.state.minNav)
            }
        });
    }

    toggleSearch = (e) => {
        e.preventDefault();
        var mobileNavModal = document.getElementById('mobile-nav-modal');
        mobileNavModal.style.display = 'none';
        var subNav = document.getElementsByClassName('cert-search')[0];
        if (subNav !== undefined) {
            if (subNav.classList.contains('show')) {
                subNav.classList.remove('show');
                subNav.classList.add('hide');
            } else {
                subNav.classList.add('show');
                subNav.classList.remove('hide');
            }
        } else {
            window.location = '/?search=true';
        }
    }

    render() {
        return (
            <div className={!this.state.minNav ? "sidenav" : "sidenav min-sidenav"}>
                <div className='mobile'>
                    <a href='../' className={this.props.selected === 'allDocuments' ? 'nav-ico active' : 'nav-ico'}><i className="fa-solid fa-house"></i><p>Home</p></a>
                    <a href='foo' className='nav-ico' onClick={this.toggleSearch}><i className="fa-solid fa-magnifying-glass"></i><p>Search</p></a>
                    {this.state.user_type === 'organisation' ?
                        <a href='/organisation' className={this.props.selected === 'myorganisation' ? 'nav-ico active' : 'nav-ico'}><i className="fa-solid fa-stamp"></i><p>Issue</p></a>
                        :
                        <a href='/create' className={this.props.selected === 'createorganisation' ? 'nav-ico active' : 'nav-ico'}><i className="fa-solid fa-stamp"></i><p>Issue</p></a>
                    }
                    <a href='foo' className='nav-ico' onClick={this.toggleMenu}><i className="fa-solid fa-bars"></i><p>Menu</p></a>
                </div>
                <div className="container" id='mobile-nav-modal'>
                    <div className='profile'>
                        <div className='user-sec tooltip-premium'>
                            <span className="tooltiptext">Upgrade</span>
                            <div className={this.state.storage > 100 ? 'userbadge premium' : 'userbadge'} onClick={() => window.location = '/buy'}><i className="fa-solid fa-crown"></i></div>
                            {!this.state.minNav && (<h1><span>{this.props.name}</span></h1>)}
                        </div>
                        {this.props.user_type === 'organisation' && (
                            !this.state.minNav ?
                                <div className='org-sec'>
                                    <div className='org-name'>{this.props.org_name ? this.props.org_name : this.state.org_name}</div>
                                    <h1><span>{this.props.credits ? this.props.credits : this.state.credits}</span><img src={coinIcon} className="coin-icon" alt="credit icon"></img></h1>
                                    <p>Credits</p>
                                    <a className='org-plan' href='/premium'>Upgrade <i className="fa-solid fa-bolt"></i></a>
                                    <a className='add-credits' href='/premium'><p>Add&nbsp;Credits</p><i className="fa-solid fa-plus"></i></a>
                                </div>
                                :
                                <div className='org-sec-min'>
                                    <h1>{this.props.credits ? this.props.credits : this.state.credits}</h1>
                                    <p>Credits</p>
                                </div>
                        )}
                    </div>

                    {/* <form method='POST' className='search'>
                        <input type='text' name='search' placeholder='Search' />
                        <button type='submit'><i className="fa-solid fa-magnifying-glass"></i></button>
                    </form> */}

                    <div className='container scroll'>
                        <div className='module'>
                            {!this.state.minNav ? <label>Personal</label> : <label>Me</label>}
                            <a className={this.props.selected === 'allDocuments' ? 'nav selected' : 'nav'} href="/">
                                <i className="fa-solid fa-award"></i>
                                {!this.state.minNav && ('Certificates')}
                            </a>
                            <a className={this.props.selected === 'uploadedDocuments' ? 'nav selected' : 'nav'} href="/mydocuments">
                                <i className="fa-regular fa-file-lines"></i>
                                {!this.state.minNav && ('My Documents')}
                            </a>
                            <a className={this.props.selected === 'mySignature' ? 'nav selected' : 'nav'} href="/mysignature">
                                <i className="fa-solid fa-signature"></i>
                                {!this.state.minNav && ('My Signature')}
                            </a>
                            <a className={this.props.selected === 'approvals' ? 'nav selected' : 'nav'} href="/approvals">
                                <i className="fa-solid fa-file-circle-check"></i>
                                {!this.state.minNav && ('Approvals')}
                                <div className='fg-1'></div>
                                {this.state.approvals > 0 && (
                                    <i className="notification-bell fa-solid fa-bell"></i>
                                )}
                            </a>
                            {/* <a className={this.props.selected === 'editProfile' ? 'nav selected' : 'nav'} href="/profile">
                                <i className="fa-solid fa-pen-to-square"></i>
                                {!this.state.minNav && ('Edit Profile')}
                            </a> */}
                        </div>
                        {
                            this.organisationRender(this.props.user_type)
                        }
                        <div className='fg-1'></div>
                        {!this.state.minNav && (
                            <div className='copyright'>
                                <b>PRONETIN &copy; {new Date().getFullYear()}</b>
                                <p>Aerobits Developers Pvt. Ltd.</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='min-sidenav-btn' onClick={this.toggleMinNav}>
                    {this.state.minNav ? <i className="fa-solid fa-angle-right"></i> : <i className="fa-solid fa-angle-left"></i>}
                </div>
            </div>
        );
    }
}

export default SidenavComponent;