import React from 'react';
import './css/home.css';
import axios from 'axios';
import UndrawOnboarding from '../images/svg/undraw_onboarding_re_6osc.svg'
import UndrawCallback from '../images/svg/undraw_video_call_re_4p26.svg'

class HelpModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailsAndTitles: [],
            prevVideoKeys: [],
            nav_selection: this.props.nav_selection ? this.props.nav_selection : 'how-to',
            callTime: "Today",
            supportType: "Sales",
            supportName: "",
            supportPhone: "",
            subnav_selection: false,
            support_token: false,
        };
    }

    closeHelpModal = () => {
        this.props.fetchHelpAction("close-help-modal");
    }

    enableOnboardingTutorial = (status) => {
        if (status === 'organiser') {
            this.props.fetchHelpAction("enable-onboarding-tutorial-organiser");
        } else {
            this.props.fetchHelpAction("enable-onboarding-tutorial");
        }
    }

    outsideModalClick = (e) => {
        if (e.target.className === 'help-modal') {
            this.props.fetchHelpAction("close-help-modal");
        }
    }

    openInBlank = (url) => {
        window.open(url, '_blank');
    }

    toggleNavSelection = (value) => {
        if (value === 'external-links') {
            this.fetchThumbnails(['SOTamWNgDKc', 'zJSY8tbf_ys', 'b9eMGE7QtTk', 'Z1RJmh_OqeA', 'i_LwzRVP7bg']);
        }
        this.setState({ nav_selection: value });
        if (window.innerWidth < 730) {
            let element = document.querySelector('.help-modal .navigation');
            element.classList.remove('active');
        }
    }

    toggleSubNavSelection = (value) => {
        //Temporarily disabled
        return null;
        // switch (value) {
        //     case 'user':
        //         this.fetchThumbnails(['SOTamWNgDKc', 'zJSY8tbf_ys', 'b9eMGE7QtTk']);
        //         break;
        //     case 'signee':
        //         this.fetchThumbnails(['SOTamWNgDKc', 'b9eMGE7QtTk']);
        //         break;
        //     case 'organiser':
        //         this.fetchThumbnails(['SOTamWNgDKc', 'zJSY8tbf_ys']);
        //         break;
        //     case 'organisation':
        //         this.fetchThumbnails(['SOTamWNgDKc', 'zJSY8tbf_ys', 'Z1RJmh_OqeA', 'i_LwzRVP7bg']);
        //         break;
        //     case 'developer':
        //         this.fetchThumbnails(['SOTamWNgDKc', 'Z1RJmh_OqeA', 'i_LwzRVP7bg']);
        //         break;
        //     case 'advertising':
        //         this.fetchThumbnails(['zJSY8tbf_ys', 'b9eMGE7QtTk']);
        //         break;
        //     default:
        //         return null;
        // }
        // this.setState({
        //     subnav_selection: value,
        //     nav_selection: 'how-to-for',
        // });
    }

    fetchThumbnails(videos) {
        if (JSON.stringify(videos) !== JSON.stringify(this.state.prevVideoKeys)) {
            const apiKey = 'AIzaSyAKDy3QHaBr6N2V4xrghTSQZGru4hLlYUw';
            const videoIds = videos;
            const urls = videoIds.map(videoId =>
                `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`
            );

            Promise.all(urls.map(url => fetch(url).then(response => response.json())))
                .then(dataArray => {
                    const thumbnailsAndTitles = dataArray.map((data, index) => {
                        const snippet = data.items[0].snippet;
                        return {
                            thumbnailUrl: snippet.thumbnails.high.url,
                            title: snippet.title,
                            url: "https://www.youtube.com/watch?v=" + videoIds[index],
                        };
                    });
                    this.setState({
                        thumbnailsAndTitles: thumbnailsAndTitles,
                        prevVideoKeys: videos,
                    });
                })
                .catch(error => {
                    console.error('Error fetching YouTube thumbnails:', error);
                });
        } else {
            console.log("test");
        }
    }

    handleSupportTypeChange = (event) => {
        this.setState({
            supportType: event.target.value
        });
    };

    handleSupportName = (event) => {
        this.setState({
            supportName: event.target.value
        });
    };

    handleSupportPhone = (event) => {
        this.setState({
            supportPhone: event.target.value
        });
    };

    handleCallTimeChange = (event) => {
        this.setState({
            callTime: event.target.value
        });
    };

    submitCallback = () => {
        axios.post(this.props.node_server_axios + 'submit_callback', {
            params: {
                support_type: this.state.supportType,
                support_time: this.state.callTime,
                support_name: this.state.supportName,
                support_phone: this.state.supportPhone,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    support_token: res.data.token
                });
            } else {
                this.setState({
                    support_token: "You already have an open callback"
                });
            }
        });
    }

    backToMainNav = () => {
        this.setState({
            subnav_selection: false,
            nav_selection: 'how-to',
        });
    }

    toggleModalMenu = () => {
        let element = document.querySelector('.help-modal .navigation');
        if (element.classList.contains('active')) {
            element.classList.remove('active');
        } else {
            element.classList.add('active');
        }
    }

    render() {
        const { thumbnailsAndTitles } = this.state;
        return (
            <div className='help-modal' onClick={this.outsideModalClick}>
                <div className='wrapper'>
                    <div className='navigation'>
                        <div className='nav-toggle close' onClick={this.toggleModalMenu}>
                            <i className="fa-regular fa-x"></i>
                        </div>
                        <h2>Learning Hub</h2>
                        <hr></hr>
                        <button className={this.state.nav_selection === 'onboarding-tutorial' ? 'active' : ''} onClick={() => this.toggleNavSelection('onboarding-tutorial')}><i className="fa-solid fa-champagne-glasses"></i> Onboarding tutorial</button>
                        {/* <button className={this.state.nav_selection === 'how-to' ? 'active' : ''} onClick={() => this.toggleNavSelection('how-to')}><i className="fa-solid fa-shapes"></i> How to</button> */}
                        <button className='coming-soon'><i className="fa-solid fa-shapes"></i> How to</button>
                        {/* <button className={this.state.nav_selection === 'external-links' ? 'active' : ''} onClick={() => this.toggleNavSelection('external-links')}><i className="fa-solid fa-video"></i> External Links</button> */}
                        <button className='coming-soon'><i className="fa-solid fa-video"></i> External Links</button>
                        <hr></hr>
                        <button onClick={() => this.openInBlank('/help')}><i className="fa-solid fa-life-ring"></i> Go to FAQ <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                        <button className='coming-soon'><i className="fa-regular fa-lightbulb"></i> Submit an idea <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                        <button><i className="fa-solid fa-link"></i> API Integrations <i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                        <hr></hr>
                        <button className={this.state.nav_selection === 'whats-new' ? 'active' : ''} onClick={() => this.toggleNavSelection('whats-new')}><i className="fa-solid fa-gift"></i> Whats New</button>
                        <button className={this.state.nav_selection === 'schedule-callback' ? 'active' : ''} onClick={() => this.toggleNavSelection('schedule-callback')}><i className="fa-solid fa-headset"></i> Schedule Callback</button>
                        <div className='fg-1'></div>
                        <button className={this.state.nav_selection === 'give-us-feedback' ? 'active' : ''} onClick={() => this.toggleNavSelection('give-us-feedback')}><i className="fa-regular fa-comment-dots"></i> Give Us Feedback</button>
                    </div>
                    <div className='content'>
                        <div className='header'>
                            <div className='nav-toggle' onClick={this.toggleModalMenu}>
                                <i className="fa-solid fa-bars"></i>
                            </div>
                            <div className='search-container'>
                                {this.state.subnav_selection && (<button className='back-btn' onClick={this.backToMainNav}><i className="fa-solid fa-chevron-left"></i></button>)}
                                <h3>Lets understand Pronetin</h3>
                                {/* <input type="text" placeholder='Search' /> */}
                            </div>
                            <button className='modal-close-btn' onClick={this.closeHelpModal}><i className="fa-regular fa-circle-xmark"></i></button>
                        </div>
                        {this.state.nav_selection === 'onboarding-tutorial' && (
                            <div className='sections'>
                                <h2>Onboarding Tutorial</h2>
                                <b>Lets start from the basics</b>
                                <div className='wrapper-container'>
                                    <img className='main-img' src={UndrawOnboarding} alt='undraw-drawing'></img>
                                    <p className='normal'>Pronetin is a platform which seamlessly connects both certificate issuing organisations and certificate receiving users.
                                        <br></br>
                                        This section of the Help centre will help you in going through the important features of Pronetin for Both Users and Organisers.
                                        <br></br>
                                        Head on to click the below buttons to learn more through - step by step navigation.
                                    </p>
                                    <button onClick={() => this.enableOnboardingTutorial('user')}>For Users <i className="fa-solid fa-angles-right"></i></button>
                                    <button onClick={() => this.enableOnboardingTutorial('organiser')}>For Organisers <i className="fa-solid fa-angles-right"></i></button>
                                </div>
                            </div>
                        )}
                        {this.state.nav_selection === 'how-to' && (
                            <div className='sections'>
                                <h2>How To</h2>
                                <b>Use our platform to the fullest</b>
                                <div className='wrapper-container'>
                                    <div className='container' onClick={() => this.toggleSubNavSelection('user')}>
                                        <i className="fa-solid fa-user"></i>
                                        <h3>For User</h3>
                                        <p>To know about managing your certificates</p>
                                    </div>
                                    <div className='container' onClick={() => this.toggleSubNavSelection('signee')}>
                                        <i className="fa-solid fa-signature"></i>
                                        <h3>For Signee</h3>
                                        <p>To verify and sign a certificate or document</p>
                                    </div>
                                    <div className='container' onClick={() => this.toggleSubNavSelection('organiser')}>
                                        <i className="fa-solid fa-building-user"></i>
                                        <h3>For Organiser</h3>
                                        <p>To design and send certificates or documents</p>
                                    </div>
                                    <div className='container' onClick={() => this.toggleSubNavSelection('organisation')}>
                                        <i className="fa-solid fa-building"></i>
                                        <h3>For Organisation</h3>
                                        <p>To manage all the resources within the platform</p>
                                    </div>
                                    <div className='container' onClick={() => this.toggleSubNavSelection('developer')}>
                                        <i className="fa-solid fa-code"></i>
                                        <h3>For Developer</h3>
                                        <p>To integrate Pronetin within your website or webapp</p>
                                    </div>
                                    <div className='container' onClick={() => this.toggleSubNavSelection('advertising')}>
                                        <i className="fa-solid fa-rectangle-ad"></i>
                                        <h3>For Advertising</h3>
                                        <p>To reach more customers and grow your business</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.nav_selection === 'how-to-for' && (
                            <div className='sections'>
                                <h2>For {this.state.subnav_selection}</h2>
                                <b>How the community uses pronetin</b>
                                <div className='wrapper-container'>
                                    {thumbnailsAndTitles.map((data, index) => (
                                        <div className='vid-tile' key={index} onClick={() => { window.open(data.url, "_blank"); }}>
                                            <img src={data.thumbnailUrl} alt="YouTube Thumbnail" />
                                            <div className='play-button'><button>Play Video <i className="fa-solid fa-arrow-up-right-from-square"></i></button></div>
                                            <p className='normal'><i className="fa-regular fa-circle-play"></i> <span>{data.title}</span></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {this.state.nav_selection === 'external-links' && (
                            <div className='sections'>
                                <h2>External Links</h2>
                                <b>How the community uses pronetin</b>
                                <div className='wrapper-container'>
                                    {thumbnailsAndTitles.map((data, index) => (
                                        <div className='vid-tile' key={index} onClick={() => { window.open(data.url, "_blank"); }}>
                                            <img src={data.thumbnailUrl} alt="YouTube Thumbnail" />
                                            <div className='play-button'><button>Play Video <i className="fa-solid fa-arrow-up-right-from-square"></i></button></div>
                                            <p className='normal'><i className="fa-regular fa-circle-play"></i> <span>{data.title}</span></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {this.state.nav_selection === 'whats-new' && (
                            <div className='sections'>
                                <h2>Whats New</h2>
                                <b>Stay upto date with the latest features</b>
                                <div className='wrapper-container jc-left'>
                                    <p className='normal'><i className="fa-solid fa-star"></i> Feature Updates</p>
                                    <ul>
                                        <li>Updated UI <span>Added improved UI for recepient tracking</span></li>
                                        <li>Improved help center</li>
                                        <li>Added feedbacks</li>
                                    </ul>
                                    <p className='normal'><i className="fa-solid fa-bug-slash"></i> Fixes</p>
                                    <ul>
                                        <li>Fixed responsive issue in Tabs</li>
                                    </ul>
                                    <p className='normal'><i className="fa-solid fa-book"></i> Learn More</p>
                                    <ul>
                                        <li><a href='foo'>Previous Versions</a></li>
                                        <li><a href='foo'>Register for beta (Testers)</a></li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.state.nav_selection === 'schedule-callback' && (
                            <div className='sections callback'>
                                <div className='wrapper-container'>
                                    {this.state.support_token ?
                                        <div className='callback-sec'>
                                            <h2><i className="fa-solid fa-phone-volume"></i> Schedule A Callback</h2>
                                            <p>We have received your callback request, out team will contact you soon via <span>{this.props.email}</span></p>
                                            <h3>{this.state.support_token}</h3>
                                            <p>Generated token</p>
                                        </div>
                                        :
                                        <div className='callback-sec'>
                                            <h2><i className="fa-solid fa-phone-volume"></i> Schedule Video Callback</h2>
                                            <p>Tell us a bit more about your need, so that we can personalize the follow up</p>
                                            <p className='label'><i className="fa-solid fa-headset"></i> Support type</p>
                                            <div className='support-type'>
                                                <div className='radio-btn'>
                                                    <input type="radio" id="Sales" name="support-type" value="Sales" checked={this.state.supportType === 'Sales'} onChange={this.handleSupportTypeChange} />
                                                    <label htmlFor="Sales"><i className="fa-regular fa-circle-check"></i> Sales</label>
                                                </div>
                                                <div className='radio-btn'>
                                                    <input type="radio" id="Technical" name="support-type" value="Technical" checked={this.state.supportType === 'Technical'} onChange={this.handleSupportTypeChange} />
                                                    <label htmlFor="Technical"><i className="fa-regular fa-circle-check"></i> Technical</label>
                                                </div>
                                                <div className='radio-btn'>
                                                    <input type="radio" id="Training" name="support-type" value="Training" checked={this.state.supportType === 'Training'} onChange={this.handleSupportTypeChange} />
                                                    <label htmlFor="Training"><i className="fa-regular fa-circle-check"></i> Training</label>
                                                </div>
                                            </div>
                                            <p className='label'><i className="fa-regular fa-user"></i> Name</p>
                                            <input type='text' placeholder='John Doe' required onChange={this.handleSupportName} />
                                            <p className='label'><i className="fa-solid fa-phone"></i> Phone</p>
                                            <input type='number' placeholder='Contact Number' required onChange={this.handleSupportPhone} />
                                            <p className='label'><i className="fa-regular fa-clock"></i> Best time to call</p>
                                            <div className='support-type'>
                                                <div className='radio-btn'>
                                                    <input type="radio" id="ASAP" name="call-time" value="ASAP" checked={this.state.callTime === 'ASAP'} onChange={this.handleCallTimeChange} />
                                                    <label htmlFor="ASAP"><i className="fa-regular fa-circle-check"></i> ASAP</label>
                                                </div>
                                                <div className='radio-btn'>
                                                    <input type="radio" id="Today" name="call-time" value="Today" checked={this.state.callTime === 'Today'} onChange={this.handleCallTimeChange} />
                                                    <label htmlFor="Today"><i className="fa-regular fa-circle-check"></i> Today</label>
                                                </div>
                                                <div className='radio-btn'>
                                                    <input type="radio" id="Tomorrow" name="call-time" value="Tomorrow" checked={this.state.callTime === 'Tomorrow'} onChange={this.handleCallTimeChange} />
                                                    <label htmlFor="Tomorrow"><i className="fa-regular fa-circle-check"></i> Tomorrow</label>
                                                </div>
                                            </div>
                                            <button onClick={this.submitCallback}>Request Callback <i className="fa-solid fa-arrow-right-long"></i></button>
                                        </div>
                                    }
                                    <img className='main-img' src={UndrawCallback} alt='undraw-drawing'></img>
                                </div>
                            </div>
                        )}
                        {this.state.nav_selection === 'give-us-feedback' && (
                            <div className='sections'>
                                <h2>Give Us Feedback</h2>
                                <b>Your feedbacks are valuble to us</b>
                                <div className='wrapper-container'>
                                    <p>Coming Soon !</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default HelpModal;