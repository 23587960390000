import logo from '../images/static/Group 11.png';
import logo_small from '../images/static/Pronetin Logo.png';
import React from 'react';
import './css/header.css';
import axios from 'axios';

import WarningAction from './warning_action';
import OnboardingTutorial from './onboarding_tutorial';
import HelpModal from './help_modal';
import Warning from '../components/warning';
import ProgressLoader from '../components/progress_loader';

import io from 'socket.io-client';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.targetRef_1 = React.createRef();
        this.targetRef_2 = React.createRef();
        this.state = {
            profileImage: false,
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            OnboardingTutorial: false,
            OnboardingTutorialMode: 'user',
            HelpModal: false,
            UserNav: false,
            NotificationNav: false,
            helpModal_navSelection: 'how-to',
            menu_navigation: this.props.menu_navigation,
            currentPage: window.location.href.split('/').pop(),
            notifications: [],
            push_notifications: [],
            approvals: 0,
            progress_loader: false,
            cacheTs: Date.now(),
            feedbackModal: false,
            eventName: '',
            subeventName: '',
            eventArray: [],
            subeventArray: [],
            newEvent: false,
            newSubevent: false
        };
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleOutsideClick);
        setTimeout(() => {
            this.setState({ OnboardingTutorial: true });
        }, 3000);

        let profileIconColor = localStorage.getItem('profile-icon-color');
        if (profileIconColor !== null) {
            document.documentElement.style.setProperty('--random-color', profileIconColor);
        } else {
            this.setRandomColor();
        }

        this.setState({
            window_width: window.innerWidth,
        })
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!this.state.profileImage && this.props.login === true) {
            fetch("https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + Date.now())
                .then((response) => {
                    if (response.ok) {
                        this.setState({
                            profileImage: true,
                        })
                    }
                })
        }

        if (prevProps.user_data !== this.props.user_data && this.props.node_server_axios && this.props.user_data) {
            axios.post(this.props.node_server_axios + 'get_notifications').then((res) => {
                if (res.data && res.data.message === 'success') {
                    this.setState({
                        notifications: res.data.notifications.notification ? res.data.notifications.notification : [],
                        approvals: res.data.notifications.approvals ? res.data.notifications.approvals : 0,
                    });
                }
            })
            this.socket = io.connect(this.props.node_server_axios.replace("8080/api/", "8090"), { reconnectionAttempts: 3 });
            //Websocket to read notifications
            this.socket.emit('setEmail', this.props.user_data.email);
            this.socket.on('notification', (data) => {
                this.setState({
                    push_notifications: this.state.push_notifications.concat(data.message),
                });
            });
        }

        if (prevProps.menu_navigation !== this.props.menu_navigation) {
            this.setState({
                menu_navigation: this.props.menu_navigation,
            })
        }
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    Signout = event => {
        event.preventDefault();
        this.setState({
            progress_loader: true,
        });
        localStorage.removeItem('user_info_skip');
        localStorage.removeItem('profile-icon-color');
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'signout').then((res) => {
            if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                window.location = res.data.redirect;
            }
        })
    }

    goHome = async () => {
        const path = window.location.pathname;
        const segments = path.split('/');
        if (segments[segments.length - 1] === 'issue') {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Return to Home",
                    subtext: "Documents attached and recipient details will be lost. Do you wish to continue?",
                    type: "warning",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: true,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
        window.location.href = './';
    }

    openOnboardingTutorial = (page, mode) => {
        if (mode === 'organiser') {
            if (this.props.user_data.user_type !== 'organisation') {
                this.setState({
                    warning: {
                        message: "This feature is available only to an organisation account.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
                return null;
            }
        }

        localStorage.removeItem('onboarding_' + page);
        if (this.state.currentPage !== '') {
            window.location = '/';
        }
        this.setState({
            OnboardingTutorial: true,
            OnboardingTutorialMode: mode,
        });
        this.props.sidenavType('min');
    }

    toggleHelpModal = (helpModal_navSelection) => {
        this.setState({
            HelpModal: !this.state.HelpModal,
            helpModal_navSelection: typeof helpModal_navSelection === 'string' ? helpModal_navSelection : 'how-to',
        });
    }

    helpModalAction = (values) => {
        switch (values) {
            case 'close-help-modal':
                this.toggleHelpModal();
                break;
            case 'enable-onboarding-tutorial':
                this.toggleHelpModal();
                this.openOnboardingTutorial('index', 'user');
                break;
            case 'enable-onboarding-tutorial-organiser':
                this.toggleHelpModal();
                this.openOnboardingTutorial('index', 'organiser');
                break;
            default:
                return null
        }
    }

    toggleUserNav = () => {
        this.setState({
            NotificationNav: false,
            UserNav: !this.state.UserNav,
        });
    }

    toggleNotificationNav = () => {
        this.setState({
            UserNav: false,
            NotificationNav: !this.state.NotificationNav,
        });
    }

    handleOutsideClick = (event) => {
        const targetElement = event.target;
        const { current: targetDiv_1 } = this.targetRef_1;
        const { current: targetDiv_2 } = this.targetRef_2;

        if (targetDiv_2 && !targetDiv_2.contains(targetElement)) {
            this.setState({
                UserNav: false,
            });
        }
        if (targetDiv_1 && !targetDiv_1.contains(targetElement)) {
            this.setState({
                NotificationNav: false,
            });
        }
    };

    randomDarkColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    };

    setRandomColor = () => {
        const randomColor = this.randomDarkColor();
        document.documentElement.style.setProperty('--random-color', randomColor);
        localStorage.setItem('profile-icon-color', randomColor);
    };

    switchPlatform = (e, platform) => {
        e.preventDefault();
        let user_type = "user";
        let redirect = "/";
        switch (platform) {
            case "certificates":
                user_type = "user";
                redirect = "/";
                break;
            case "feedbacks":
                user_type = "organisation";
                redirect = "function";
                this.toggleFeedbackModal();
                this.toggleUserNav();
                break;
            case "events":
                user_type = "comingsoon";
                redirect = "/";
                break;
            case "nfc":
                user_type = "comingsoon";
                redirect = "/";
                break;
            case "resume":
                user_type = "comingsoon";
                redirect = "/";
                break;
            case "social":
                user_type = "comingsoon";
                redirect = "/";
                break;
            case "activity":
                user_type = "organisation";
                redirect = "/activitylogs";
                break;
            case "advertise":
                user_type = "comingsoon";
                redirect = "/";
                break;
            case "accredit":
                user_type = "comingsoon";
                redirect = "/";
                break;
            default:
                user_type = "user";
                redirect = "/";
        }
        if (user_type === "organisation") {
            if (user_type === this.props.user_data.user_type) {
                if (redirect !== 'function') {
                    window.location.href = redirect;
                }
            } else {
                this.setState({
                    warning: {
                        message: "You need an organisation account to access this.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        }
        else if (user_type === "user") {
            if (redirect !== 'function') {
                window.location.href = redirect;
            }
        }
        else {
            this.setState({
                warning: {
                    message: "This feature will be launched soon.",
                    type: "success",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    handleCopyToClipboard = (event, index) => {
        const text = event.target.closest(".copy-container").querySelector(".no-view-copy").textContent;

        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            copied: true,
            copiedIndex: index,
            warning: {
                message: "Organisation ID copied !",
                type: "success",
                learnmore: false,
                autoclose: true,
            }
        });
        setTimeout(() => {
            this.setState({ copied: false, copiedIndex: false });
        }, 2000);
    }

    toggleMenuNavigation = () => {
        this.setState({
            menu_navigation: !this.state.menu_navigation,
        }, () => {
            this.props.toggleMenuNavigation(this.state.menu_navigation);
        });
    }

    removePushNotification = (index) => {
        const updatedNotifications = [...this.state.push_notifications];
        updatedNotifications.splice(index, 1);
        this.setState({ push_notifications: updatedNotifications });
    }

    removeNotification = (index) => {
        const updatedNotifications = [...this.state.notifications];
        updatedNotifications.splice(index, 1);
        this.setState({ notifications: updatedNotifications });
        axios.post(this.props.node_server_axios + 'delete_notification', {
            params: {
                notification: updatedNotifications,
            }
        });
    }

    toggleFeedbackModal = () => {
        if (this.state.eventArray.length === 0) {
            axios.post(this.props.node_server_axios + 'get_events', {
                params: {
                    query: "all",
                },
            }).then(res => {
                if (res.data.eventList) {
                    this.setState({
                        subeventArray: res.data.eventList,
                        eventArray: Array.from(new Set(res.data.eventList.map(obj => obj.event))),
                    })
                }
            });
        }
        this.setState({ feedbackModal: !this.state.feedbackModal });
    }

    openDropDown = (e) => {
        if (e === 'event') {
            this.setState({ eventName: '', newEvent: !this.state.newEvent });
        } else {
            this.setState({ subeventName: '', newSubevent: !this.state.newSubevent });
        }
    }

    handleEventChange = (e) => {
        if (!/[^A-Za-z0-9@:._,~\s]/.test(e)) {
            e = e.replace(/ +/g, " ").trimLeft();
            let subevent = this.state.subeventArray.filter(obj => obj.event === e)[0] ? this.state.subeventArray.filter(obj => obj.event === e)[0].subevent : e;
            if (e === '' || this.state.eventArray.includes(e)) {
                if (this.state.enableSubevent) {
                    this.setState({ eventName: e, newEvent: false, subeventName: '' });
                } else {
                    this.setState({ eventName: e, newEvent: false, subeventName: subevent });
                }
            } else {
                if (this.state.enableSubevent) {
                    this.setState({ eventName: e, newEvent: true, subeventName: '' });
                } else {
                    this.setState({ eventName: e, newEvent: true, subeventName: subevent });
                }
            }
        }
    }

    handleSubeventChange = (e) => {
        if (!/[^A-Za-z0-9@:._,~\s]/.test(e)) {
            e = e.replace(/ +/g, " ").trimLeft();
            if (e === '' || this.state.subeventArray.filter(obj => { return obj.event === this.state.eventName }).find(item => item.subevent === e)) {
                this.setState({ subeventName: e, newSubevent: false });
            } else {
                this.setState({ subeventName: e, newSubevent: true });
            }
        }
    }

    renderFeedbacks = () => {
        if (!this.state.newEvent && !this.state.newSubevent && this.state.eventName !== '' && this.state.subeventName !== '') {
            window.location.href = '/subevents/' + this.state.eventName + '/' + this.state.subeventName + '#feedbacks';
        } else {
            this.setState({
                warning: {
                    message: "Invalid Event / Subevent",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
        }
    }

    render() {
        const notification_count = this.state.approvals + this.state.notifications.length + this.state.push_notifications.length;
        try {
            if (this !== undefined && this.props.login === true) {
                return (
                    <header className="header">
                        <img className='logo' src={this.state.window_width < 820 ? logo_small : logo} alt='Pronetin' onClick={this.goHome} />
                        {this.state.feedbackModal && (
                            <div className='element-modal transfer-modal event-modal'>
                                <div className='content'>
                                    <button className='close-btn' onClick={this.toggleFeedbackModal}><i className="fa-solid fa-x"></i></button>
                                    <div className='container'>
                                        <h1>Feedbacks</h1>
                                        <p>Select event to view feedbacks</p>
                                    </div>
                                    <div className='input-wrapper'>
                                        <input name='eventName' placeholder='Event Name' type='text' onChange={(e) => { this.handleEventChange(e.target.value) }} value={this.state.eventName} required autoComplete='off' />
                                        {this.state.newEvent && (
                                            <ul>
                                                {this.state.eventArray.filter(item => item.toLowerCase().includes(this.state.eventName.toLowerCase())).map((element, index) => {
                                                    return (<li key={index} onClick={() => { this.handleEventChange(element) }}>{element}</li>)
                                                })}
                                            </ul>)}
                                        {this.state.newEvent ? <div className='new'>Not Found</div> : <div className='all' onClick={() => { this.openDropDown('event') }}>Choose <i className="fa-solid fa-chevron-down"></i></div>}
                                    </div>
                                    {!this.state.newEvent && this.state.eventName !== '' && (<p>Select subevent</p>)}
                                    {!this.state.newEvent && this.state.eventName !== '' && (
                                        <div className='input-wrapper'>
                                            <input name='subeventName' placeholder='Sub Event' type='text' onChange={(e) => { this.handleSubeventChange(e.target.value) }} value={this.state.subeventName} required autoComplete='off' />
                                            {this.state.newSubevent && (
                                                <ul>
                                                    {this.state.subeventArray.filter(obj => { return obj.event === this.state.eventName && obj.subevent.toLowerCase().includes(this.state.subeventName.toLowerCase()) }).map((element, index) => {
                                                        return (<li key={index} onClick={() => { this.handleSubeventChange(element.subevent) }}>{element.subevent}</li>)
                                                    })}
                                                </ul>)}
                                            {this.state.newSubevent ? <div className='new'>Not Found</div> : <div className='all' onClick={() => { this.openDropDown('subevent') }}>Choose <i className="fa-solid fa-chevron-down"></i></div>}
                                        </div>
                                    )}
                                    <button type="button" onClick={this.renderFeedbacks}>View Feedbacks <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                            </div>
                        )}
                        <div className='container'>
                            <button className='onboarding-help help' onClick={this.toggleHelpModal}><i className="fa-regular fa-circle-question"></i> <span>Help Center</span></button>
                            <button className='home' onClick={this.goHome}><i className="fa-solid fa-house"></i> Home</button>
                            <div className='notification-icon-container' ref={this.targetRef_1}>
                                <div className='notification-icon' onClick={this.toggleNotificationNav}><i className="fa-regular fa-bell"></i>{notification_count > 0 && (<span>{notification_count}</span>)}</div>
                                {this.state.NotificationNav && (
                                    <div className='notification-nav'>
                                        {this.state.approvals > 0 && (
                                            <div className='content' onClick={() => window.location = '/approvals'}>
                                                <div className='icon'><i className="fa-solid fa-file-circle-check"></i></div>
                                                <p>You have approvals pending</p>
                                            </div>
                                        )}
                                        {this.state.notifications && this.state.notifications.map((element, index) => {
                                            let notification_redirect = null;
                                            if (element.includes('would like to join')) {
                                                notification_redirect = '/settings';
                                            } else if (element.includes('join their organisation')) {
                                                notification_redirect = '/create';
                                            } else if (element.includes('Action center')) {
                                                notification_redirect = '/actioncenter';
                                            }
                                            return (
                                                <div className='content' key={index} onClick={() => notification_redirect !== null ? window.location = notification_redirect : null}>
                                                    <div className='icon'><i className="fa-solid fa-bell"></i></div>
                                                    <p>{element}</p>
                                                    <button className='close-btn' onClick={() => this.removeNotification(index)}>x</button>
                                                </div>
                                            )
                                        }
                                        )}
                                        {this.state.push_notifications && this.state.push_notifications.map((element, index) => {
                                            let notification_redirect = null;
                                            if (element.includes('would like to join')) {
                                                notification_redirect = '/settings';
                                            } else if (element.includes('join their organisation')) {
                                                notification_redirect = '/create';
                                            } else if (element.includes('Action center')) {
                                                notification_redirect = '/actioncenter';
                                            }
                                            return (
                                                <div className='content' key={index} onClick={() => notification_redirect !== null ? window.location = notification_redirect : null}>
                                                    <div className='icon'><i className="fa-solid fa-info"></i></div>
                                                    <p>{element}</p>
                                                    {/* Removed below button so that the user does not have to delete same notification multiple times */}
                                                    {/* <button className='close-btn' onClick={() => this.removePushNotification(index)}>x</button> */}
                                                </div>
                                            )
                                        }
                                        )}
                                        {notification_count === 0 && (
                                            <div className='content'>
                                                <div className='icon'><i className="fa-regular fa-face-smile-beam"></i></div>
                                                <p>All wrapped up here !</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='profile-icon-container' ref={this.targetRef_2}>
                                {!this.state.profileImage ?
                                    <p className='profile-icon' onClick={this.toggleUserNav}>{this.props.user_data.name.charAt(0)}</p>
                                    :
                                    <img className='profile-icon' src={"https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + this.state.cacheTs} alt="profile icon" onClick={this.toggleUserNav}></img>
                                }
                                {this.state.UserNav && (
                                    <div className='user-nav'>
                                        <div className='container'>
                                            <div className='icon'>
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                            <div className='content'>
                                                <h3>{this.props.user_data.name}</h3>
                                                <h2>{this.props.user_data.email}</h2>
                                            </div>
                                            <a href='/profile'><i className="fa-solid fa-pen-to-square"></i> Manage Your Profile</a>
                                        </div>
                                        {this.props.user_data.user_type === 'organisation' && (
                                            <div className='container'>
                                                <div className='icon'>
                                                    <i className="fa-solid fa-building-user"></i>
                                                </div>
                                                <div className='content'>
                                                    <h3>Organisation Account</h3>
                                                    <div className='copy-container'>
                                                        <p className='no-view-copy'>{this.props.user_data.org_id}</p>
                                                        <h2 onClick={this.handleCopyToClipboard}>Org. ID:<b><i className="fa-solid fa-asterisk"></i><i className="fa-solid fa-asterisk"></i><i className="fa-solid fa-asterisk"></i>-<i className="fa-solid fa-asterisk"></i><i className="fa-solid fa-asterisk"></i>-{this.props.user_data.org_id.split("-").pop()}</b> <span><i className="fa-regular fa-copy"></i></span></h2>
                                                    </div>
                                                </div>
                                                <a href='/settings'><i className="fa-solid fa-gear"></i> Manage Organisation</a>
                                            </div>
                                        )}
                                        <div className='blocks-container'>
                                            <a href='foo' className='blocks' onClick={(e) => this.switchPlatform(e, "certificates")}><i className="fa-solid fa-file-signature"></i> Certificates</a>
                                            <a href='foo' className='blocks' onClick={(e) => this.switchPlatform(e, "feedbacks")}><i className="fa-regular fa-message"></i> Feedbacks</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => this.switchPlatform(e, "events")}><i className="fa-solid fa-calendar-check"></i> Events</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => this.switchPlatform(e, "nfc")}><i className="fa-brands fa-nfc-symbol"></i> NFC</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => this.switchPlatform(e, "resume")}><i className="fa-regular fa-address-card"></i> Resume</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => this.switchPlatform(e, "social")}><i className="fa-solid fa-users"></i> Social</a>
                                            <a href='foo' className='blocks' onClick={(e) => this.switchPlatform(e, "activity")}><i className="fa-solid fa-chart-simple"></i> Activity Log</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => this.switchPlatform(e, "advertise")}><i className="fa-solid fa-rectangle-ad"></i> Advertise</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => this.switchPlatform(e, "accredit")}><i className="fa-solid fa-medal"></i> Accredit</a>
                                        </div>
                                        <button className='signout' onClick={this.Signout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Sign Out</button>
                                        <div className='privacy'>
                                            <a href='/privacypolicy' rel='noreferrer' target='_blank'>Privacy policy</a>
                                            <div className='dot'></div>
                                            <a href='/help' rel='noreferrer' target='_blank'>Help Center</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                        }
                        {this.state.OnboardingTutorial && !localStorage.getItem('onboarding_index') && window.location.pathname === "/" && (
                            <OnboardingTutorial page={'index'} mode={this.state.OnboardingTutorialMode} toggleHelpModal={this.toggleHelpModal} />
                        )}
                        {this.state.HelpModal && (
                            <HelpModal fetchHelpAction={this.helpModalAction} node_server_axios={this.props.node_server_axios} email={this.props.user_data.email} nav_selection={this.state.helpModal_navSelection} />
                        )}
                        {
                            this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                        }
                        {
                            this.state.progress_loader && (<ProgressLoader />)
                        }
                    </header>
                );
            } else {
                if (this.props.prompt) {
                    return (
                        <header className="header main-header">
                            <a className='logo' href='/'><img className='logo' src={logo} alt='Pronetin' /></a>
                            <div className='container'>
                                <button className='signin-btn' onClick={() => window.location = './signin'}>Sign In</button>
                                <button className='menu-nav-btn' onClick={this.toggleMenuNavigation}>{!this.state.menu_navigation ? <i className="fa-solid fa-bars"></i> : <i className="fa-solid fa-x"></i>}</button>
                            </div>
                        </header>
                    );
                } else {
                    return (
                        <header className="header">
                            <a className='logo' href='/'><img className='logo' src={logo} alt='Pronetin' /></a>
                            {this.props.signin && (<button className='signin-btn' onClick={() => window.location = './signin'}>Sign In</button>)}
                            {this.props.signup && (<button className='signin-btn' onClick={() => window.location = './signup'}>Sign Up</button>)}
                        </header>
                    );
                }
            }
        } catch (e) {
            return (
                <header className="header">
                    <a className='logo' href='/'><img className='logo' src={logo} alt='Pronetin' /></a>
                </header>
            );
        }
    }
}

export default HeaderComponent;