import React from 'react';

class OnboardingTutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
            shouldRender: true,
            steps: [
                {
                    heading: 'Welcome to Pronetin',
                    content: 'This is how you will receive your certificates.',
                    style: { top: this.getPositionPx("tile-wrapper", 0, "bottom") + 10, left: this.getPositionPx("tile-wrapper", 0, "left"), zIndex: "995" },
                    caret: { top: '0px', left: '10px' }
                },
                {
                    heading: 'My Documents',
                    content: 'Here is where you can upload your own documents.',
                    style: { top: this.getPositionPx("nav", 1, "bottom") + 10, left: this.getPositionPx("nav", 1, "left") },
                    caret: { top: '0px', left: '5px' }
                },
                {
                    heading: 'My Signature',
                    content: 'If you want to sign a certificate, this is the place to upload your signature.',
                    style: { top: this.getPositionPx("nav", 2, "bottom") + 10, left: this.getPositionPx("nav", 2, "left") },
                    caret: { top: '0px', left: '5px' }
                },
                {
                    heading: 'Approvals',
                    content: 'The certificates that require your signature come up here.',
                    style: { top: this.getPositionPx("nav", 3, "bottom") + 10, left: this.getPositionPx("nav", 3, "left") },
                    caret: { top: '0px', left: '5px' }
                },
                {
                    heading: 'Create / Issue Certificates',
                    content: 'If you are here to design and distribute certificates. You can start from here.',
                    style: { top: this.getPositionPx("nav", 4, "bottom") + 10, left: this.getPositionPx("nav", 4, "left") },
                    caret: { top: '0px', left: '5px' }
                },
                {
                    heading: 'Profile',
                    content: 'Here is where you can see and edit details of your account.',
                    style: { top: this.getPositionPx("profile-icon-container", 0, "bottom") + 10, right: this.getPositionPx("profile-icon-container", 0, "bottom") - 20 },
                    caret: { top: '0px', right: '30px' }
                },
                {
                    heading: 'To Know More',
                    content: 'Click here to get further ideas on how to use this platform.',
                    style: { top: this.getPositionPx("onboarding-help", 0, "bottom") + 10, left: this.getPositionPx("onboarding-help", 0, "left") },
                    caret: { top: '0px', left: '10px' }
                }
            ],
        };
    }

    handleNextStep = () => {
        if (this.state.currentStep >= this.state.steps.length - 1) {
            this.handleCloseTutorial();
        } else {
            this.setState((prevState) => ({
                currentStep: prevState.currentStep + 1,
            }));
        }
    };

    componentDidMount = () => {
        this.updateSteps();

        window.addEventListener('resize', this.handleWindowResize);
    }

    updateSteps = () => {
        let newSteps = [];
        switch (this.props.mode) {
            case 'organiser':
                newSteps = [
                    {
                        heading: 'Welcome Organisers',
                        content: 'You can start designing a certificate from here.',
                        style: { top: this.getPositionPx("btn-issue", 0, "bottom") + 10, left: this.getPositionPx("btn-issue", 0, "left") },
                        caret: { top: '0px', left: '30px' }
                    },
                    {
                        heading: 'Sending Certificates',
                        content: 'You will find the details of the certificate batches created for distribution.',
                        style: { top: this.getPositionPx("nav", 4, "bottom") + 10, left: this.getPositionPx("nav", 4, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Email Templates',
                        content: 'You will find the email content you can attach with the certificates you distribute.',
                        style: { top: this.getPositionPx("nav", 5, "bottom") + 10, left: this.getPositionPx("nav", 5, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Your Designs',
                        content: 'You will find your saved designs and draft designs here.',
                        style: { top: this.getPositionPx("nav", 6, "bottom") + 10, left: this.getPositionPx("nav", 6, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Design Better',
                        content: 'More designs or templates can be chosen from here.',
                        style: { top: this.getPositionPx("nav", 7, "bottom") + 10, left: this.getPositionPx("nav", 7, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Take Action',
                        content: 'Any requests from the certificate recipient will be found here.',
                        style: { top: this.getPositionPx("nav", 8, "top") - 40, left: this.getPositionPx("nav", 8, "right") },
                        caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                    }
                ]
                break;
            default:
                newSteps = [
                    {
                        heading: 'Welcome to Pronetin',
                        content: 'This is how you will receive your certificates.',
                        style: { top: this.getPositionPx("tile-wrapper", 0, "bottom") + 10, left: this.getPositionPx("tile-wrapper", 0, "left"), zIndex: "995" },
                        caret: { top: '0px', left: '10px' }
                    },
                    {
                        heading: 'My Documents',
                        content: 'Here is where you can upload your own documents.',
                        style: { top: this.getPositionPx("nav", 1, "bottom") + 10, left: this.getPositionPx("nav", 1, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'My Signature',
                        content: 'If you want to sign a certificate, this is the place to upload your signature.',
                        style: { top: this.getPositionPx("nav", 2, "bottom") + 10, left: this.getPositionPx("nav", 2, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Approvals',
                        content: 'The certificates that require your signature come up here.',
                        style: { top: this.getPositionPx("nav", 3, "bottom") + 10, left: this.getPositionPx("nav", 3, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Create / Issue Certificates',
                        content: 'If you are here to design and distribute certificates. You can start from here.',
                        style: { top: this.getPositionPx("nav", 4, "bottom") + 10, left: this.getPositionPx("nav", 4, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Profile',
                        content: 'Here is where you can see and edit details of your account.',
                        style: { top: this.getPositionPx("profile-icon-container", 0, "bottom") + 10, right: this.getPositionPx("profile-icon-container", 0, "bottom") - 20 },
                        caret: { top: '0px', right: '30px' }
                    },
                    {
                        heading: 'To Know More',
                        content: 'Click here to get further ideas on how to use this platform.',
                        style: { top: this.getPositionPx("onboarding-help", 0, "bottom") + 10, left: this.getPositionPx("onboarding-help", 0, "left") },
                        caret: { top: '0px', left: '10px' }
                    }
                ]
        }
        this.setState({
            currentStep: 0,
            steps: newSteps,
        });
    }

    handlePreviousStep = () => {
        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
        }));
    };

    handleCloseTutorial = () => {
        localStorage.setItem('onboarding_' + this.props.page, true);
        this.setState({ shouldRender: false });
        this.props.toggleHelpModal('onboarding-tutorial');
    }

    getPositionPx(elem, index, pos) {
        const element = document.getElementsByClassName(elem)[index];
        if (element) {
            const rect = element.getBoundingClientRect();

            const top = rect.top + window.scrollY;
            const left = rect.left + window.scrollX;
            const bottom = rect.bottom + window.scrollY;
            const right = rect.right + window.scrollX;

            switch (pos) {
                case "top":
                    return top;
                case "left":
                    return left;
                case "bottom":
                    return bottom;
                case "right":
                    return right;
                default:
                    return "0px";
            }
        }
    }

    handleWindowResize = () => {
        this.updateSteps();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        const { currentStep } = this.state;
        const { heading } = this.state.steps[currentStep];
        const { content } = this.state.steps[currentStep];
        const { style } = this.state.steps[currentStep];
        const { caret } = this.state.steps[currentStep];

        return (
            <React.Fragment>
                {this.state.shouldRender && (
                    <div className='onboarding-tutorial' style={style}>
                        <div className='pos-rel'>
                            <div className='caret' style={caret}></div>
                            <div className='close-btn' onClick={this.handleCloseTutorial}><i className="fa-regular fa-circle-xmark"></i></div>
                            <h2>{heading}</h2>
                            <p>{content}</p>
                            <div className='buttons'>
                                <button onClick={this.handlePreviousStep} disabled={currentStep === 0}><i className="fa-solid fa-angle-left"></i></button>
                                <p className='steps'>{currentStep + 1 + " / " + this.state.steps.length}</p>
                                <button onClick={this.handleNextStep} disabled={currentStep >= this.state.steps.length}><i className="fa-solid fa-angle-right"></i></button>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default OnboardingTutorial;