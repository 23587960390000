import React from 'react';
import staticImage from '../images/static/Group 252.png';
import Signup from '../components/signup';
import Header from '../components/header'

import '../components/css/main.css';

const SignupComponent = (props) => {
    return (
        <React.Fragment>
            <div className="signin">
                <Header signin={true} />
                <img src={staticImage} alt="static" className='landing-img' />
                <Signup node_server_axios={props.node_server_axios} />
            </div>
        </React.Fragment>
    );

};

export default SignupComponent;