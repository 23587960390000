import React from 'react';
import './css/element.css';
import Warning from './warning';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href).hostname + `/doc/${this.props.data.email}/${this.props.data.document_id}`,
            title: this.props.data.title,
            description: 'Document received for ' + this.props.data.event + ' on ' + this.props.data.date,
            moreShareOptions: false,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
        };
        this.handleShare = this.handleShare.bind(this);
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    openTile = () => {
        window.location = `/doc/${this.props.data.email}/${this.props.data.document_id}`;
    }

    handleShare(event) {
        event.preventDefault();
        const { url, title, description } = this.state;
        const socialMedia = event.currentTarget.getAttribute('data-network');
        const shareUrl = getShareUrl(socialMedia, url, title, description);
        window.open(shareUrl, '_blank', 'width=550,height=450');
    }

    toggleMoreShareOptions = () => {
        this.setState({
            moreShareOptions: !this.state.moreShareOptions,
        })
    }

    copyURL = (e) => {
        e.preventDefault();
        const url = this.state.url;
        const textarea = document.createElement("textarea");
        textarea.value = url;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            warning: {
                message: "URL copied to clipboard.",
                type: "success",
                learnmore: false,
                autoclose: true,
            }
        })
    }

    render() {
        const document = this.props.data;
        const parts = document.date.split('/');
        const year = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[0], 10);

        let issue_date = new Date(year, month, day);
        let current_date = new Date();
        let trial = "New"
        let days_left = Math.floor(((current_date.getTime() - issue_date.getTime()) / (1000 * 3600 * 24)));
        if (days_left >= 2) {
            trial = "This document is " + days_left + " days old";
        }

        const formatSize = (sizeInBytes) => (sizeInBytes >= 1024) ? `${(sizeInBytes / (1024)).toFixed(0)} MB` : `${(sizeInBytes).toFixed(0)} KB`;

        return (
            <div className='tile-wrapper'>
                <div className='tile' onClick={this.openTile}>
                    <div className={document.view_type === "public" ? 'view-option public' : 'view-option private'}>
                        <div className='view'>{document.view_type}</div>
                        {document.view_type === "public" ? <i className="fa-solid fa-lock-open"></i> : <i className="fa-solid fa-lock"></i>}
                    </div>
                    <h1>{document.title}<label>{document.date}</label></h1>
                    <p>{document.event}</p>
                    <div className='organisation'>File Size : {formatSize(this.props.data.file_size)}</div>
                    <div className='bottom'>
                        <div className='trial'><i className="fa-solid fa-stopwatch"></i> {trial}</div>
                        <div className='views'><i className="fa-solid fa-eye"></i> {document.view_count}</div>
                    </div>
                </div>
                <div className='action'>
                    <div className='icon-btn'>
                        {/* <button><i className="fa-solid fa-download"></i></button> */}
                        <button onClick={this.copyURL}><i className="fa-solid fa-share"></i></button>
                        {/* {this.state.moreShareOptions ?
                            <button onClick={this.toggleMoreShareOptions}><i className="fa-solid fa-x"></i></button>
                            :
                            <button onClick={this.toggleMoreShareOptions}><i className="fa-solid fa-share"></i></button>
                        } */}
                        {/* <button onClick={this.handleShare} data-network="linkedin"><i className="fa-brands fa-linkedin-in"></i></button> */}
                        {/* {this.state.moreShareOptions && (
                            <button onClick={this.handleShare} data-network="facebook"><i className="fa-brands fa-facebook-f"></i></button>
                        )}
                        {this.state.moreShareOptions && (
                            <button onClick={this.handleShare} data-network="twitter"><i className="fa-brands fa-twitter"></i></button>
                        )} */}
                    </div>
                    {!this.state.moreShareOptions && (
                        <a href={`/doc/${document.email}/${this.props.data.document_id}`}>View Document</a>
                    )}
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
            </div>
        );
    }
}

function getShareUrl(network, url, title, description) {
    switch (network) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
        case 'twitter':
            return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
        case 'linkedin':
            return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`;
        default:
            return null;
    }
}

export default TileComponent;