import React from 'react';
import './css/editor.css';
import EditorNav from './editor/editor_nav';

class EditorSidenavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleCsvToParent.bind(this);
        this.handlePdfToParent.bind(this);
        this.changeStep.bind(this);
        this.handleTemplate.bind(this);
        this.state = {
            step_selection: this.props.step_selection,
            toAdd: {
                type: false,
            },
            selectElement: false,
            toPdf: {
                pdf: false,
                pdfFilename: false,
                pdfSize: false,
                pdfData: false,
            },
            certTemplate: "tem1",
            sideNavActive: false,
        }
    }

    changeStep = (e) => {
        this.props.fetchChildStageSelect(e.currentTarget.id)
    }

    handleSendToParent = (values) => {
        this.setState({ toAdd: values }, () => {
            this.props.fetchChildState(this.state.toAdd)
        })
    }

    handleElementToDelete = (values) => {
        this.props.fetchElementToDelete(values);
    }

    handleCsvToParent = (values) => {
        this.props.fetchCsvState(values)
    }

    handleTemplate = (values) => {
        this.setState({ certTemplate: values }, () => {
            this.props.fetchTemplate(this.state.certTemplate)
        })
    }

    componentDidUpdate() {
        if (this.state.toAdd.type !== false) {
            this.setState({
                toAdd: {
                    type: false,
                },
            })
        }
    }

    handlePdfToParent = (values) => {
        this.setState({
            toPdf: {
                pdf: values.pdf,
                pdfFilename: values.pdfFilename,
                pdfSize: values.pdfSize,
                pdfData: values.pdfData,
            },
        }, () => {
            this.props.fetchPdfState(this.state.toPdf)
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            step_selection: props.step_selection,
        };
    }

    toggleSideNav = () => {
        this.setState({
            sideNavActive: !this.state.sideNavActive
        })
    }

    render() {
        return (
            <React.Fragment>
                <header className={this.state.sideNavActive ? 'editor-sidenav active' : 'editor-sidenav'}>
                    <div className='steps'>
                        <div className={this.state.step_selection === '1' ? 'step active' : 'step'} id="1" onClick={this.changeStep}>
                            <div className='step-number'><div className='round'><div className='blinker'></div>1</div><hr></hr></div>
                            <div className='selection'></div>
                            <div className='icon'>
                                <i className="fa-solid fa-table-cells-large"></i>
                            </div>
                            <p>Choose Template</p>
                        </div>
                        <div className={this.state.step_selection === '2' ? 'step active' : 'step'} id="2" onClick={this.changeStep}>
                            <div className='step-number'><div className='round'><div className='blinker'></div>2</div><hr></hr></div>
                            <div className='selection'></div>
                            <div className='icon'>
                                <i className="fa-solid fa-text-height"></i>
                            </div>
                            <p>Add Text</p>
                        </div>
                        <div className={this.state.step_selection === '3' ? 'step active' : 'step'} id="3" onClick={this.changeStep}>
                            <div className='step-number'><div className='round'><div className='blinker'></div>3</div><hr></hr></div>
                            <div className='selection'></div>
                            <div className='icon'>
                                <i className="fa-solid fa-image"></i>
                            </div>
                            <p>Add Image</p>
                        </div>
                        <div className={this.state.step_selection === '4' ? 'step active' : 'step'} id="4" onClick={this.changeStep}>
                            <div className='step-number'><div className='round'><div className='blinker'></div>4</div><hr></hr></div>
                            <div className='selection'></div>
                            <div className='icon'>
                                <i className="fa-solid fa-paperclip"></i>
                            </div>
                            <p>Attach Document</p>
                        </div>
                        <div className={this.state.step_selection === '5' ? 'step active' : 'step'} id="5" onClick={this.changeStep}>
                            <div className='step-number'><div className='round'><div className='blinker'></div>5</div><hr></hr></div>
                            <div className='selection'></div>
                            <div className='icon'>
                                <i className="fa-solid fa-signature"></i>
                            </div>
                            <p>Add Signature</p>
                        </div>
                        <div className={this.state.step_selection === '6' ? 'step active' : 'step'} id="6" onClick={this.changeStep}>
                            <div className='step-number'><div className='round'><div className='blinker'></div>6</div></div>
                            <div className='selection'></div>
                            <div className='icon'>
                                <i className="fa-solid fa-clipboard-list"></i>
                            </div>
                            <p>Upload Recepients</p>
                        </div>
                    </div>
                    <div className='step-expand'>
                        <EditorNav step={this.state.step_selection} fetchChildState={this.handleSendToParent} elementIndex={this.props.elementIndex} variableIndex={this.props.variableIndex} fetchPdfState={this.handlePdfToParent} fetchTemplate={this.handleTemplate} email={this.props.email} fetchCsvState={this.handleCsvToParent} node_server_axios={this.props.node_server_axios} user_id={this.props.user_id} fetchElementToDelete={this.handleElementToDelete} org_name={this.props.org_name} />
                    </div>
                    {this.state.sideNavActive ?
                        <button className='sidenav-close-btn' onClick={this.toggleSideNav}><i className="fa-solid fa-x"></i></button>
                        :
                        <button className='sidenav-close-btn menu' onClick={this.toggleSideNav}><i className="fa-solid fa-bars"></i></button>
                    }
                </header>
            </React.Fragment>
        );
    }
}

export default EditorSidenavComponent;