import React from 'react';
import './css/home.css';
import PadlockIcon from '../images/icons/Secure.png';
import AIIcon from '../images/icons/AI.png';
import QRIcon from '../images/icons/QR.png';
import ProcessDesign from '../images/icons/Group 330.png';
import ShiedIcon from '../images/icons/98065-security-tick.png';
import logo from '../images/static/Pronetin Logo.png';
// import welcome from '../images/static/welcome.jpg';
import axios from 'axios';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            ui_heading: 'Create',
            oldPronetinModal: false,
        };
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    loading: false,
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                });
                window.location = "./";
            }
        })
        this.containerRef.current.addEventListener('scroll', this.handleScroll);

        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            if (cleanHashValue === 'old') {
                this.setState({
                    oldPronetinModal: true,
                })
            } else {
                const targetElement = document.querySelector('#' + cleanHashValue);
                targetElement.scrollIntoView({ behavior: 'smooth' });
                this.props.toggleMenuNavigation(false);
            }
        }
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const container = this.containerRef.current;
        const element = document.getElementById("footer")
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 30) {
            element.classList.add("active");
        } else if (element.classList.contains("active")) {
            element.classList.remove("active");
        }
    };

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        targetElement.scrollIntoView({ behavior: 'smooth' });
        this.props.toggleMenuNavigation(false);
    };

    toggleOldPronetin = () => {
        this.setState({
            oldPronetinModal: !this.state.oldPronetinModal,
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.menu_navigation && (
                    <div className='menu-nav'>
                        <a href='#foo' data="landing" onClick={this.handleAnchorClick}>Home <i className="fa-solid fa-caret-left"></i></a>
                        <a href='#foo' data="features" onClick={this.handleAnchorClick}>Features <i className="fa-solid fa-caret-left"></i></a>
                        <a href='#foo' data="pricing" onClick={this.handleAnchorClick}>Pricing <i className="fa-solid fa-caret-left"></i></a>
                        <a href='#foo' data="contact" onClick={this.handleAnchorClick}>Contact <i className="fa-solid fa-caret-left"></i></a>
                        <a href='/help'>Help <i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                        <a href='/signin'>Sign In <i className="fa-solid fa-right-to-bracket"></i></a>
                    </div>
                )}
                <div className='landing-page main-landing-page' ref={this.containerRef}>
                    {this.state.oldPronetinModal && (
                        <div className='element-modal old-pronetin'>
                            <div className='content container'>
                                {/* <button className='close-btn' onClick={this.toggleOldPronetin}><i className="fa-solid fa-x"></i></button> */}
                                <p>Welcome to the New Pronetin<span>Where your experience in receiving or distributing certificates is enhanced to a whole new level.</span><span>If you are an <b>Old Pronetin</b> user and would like to backup your data, <a href="https://old.pronetin.com" target='_blank' rel='noreferrer'>Click here</a> to use the Old version of Pronetin.</span></p>
                                <button onClick={this.toggleOldPronetin} className='explore-btn'>Explore New Pronetin<i className="fa-solid fa-chevron-right"></i></button>
                            </div>
                            {/* <img src={welcome} alt='By Vlad Chețan' /> */}
                        </div>
                    )}
                    <div className='full-height landing' id="landing">
                        <div className='flex-column'>
                            <h1><span>{this.state.ui_heading}</span><br></br>perfect certificates<br></br>with <span>Pronetin</span></h1>
                            <p>Effortlessly distribute professional certificates<br></br>to your recipients with our AI-powered platform.</p>
                            <button onClick={() => { window.location = './signin' }}>Find Certificates<i className="fa-solid fa-caret-right"></i></button>
                            <button className='alt' onClick={() => { window.location = './signin?o=organisation' }}>Issue Certificates<i className="fa-solid fa-caret-right"></i></button>
                        </div>
                        <div className='features-container'>
                            <div className='features'>
                                <img src={PadlockIcon} alt="secure" />
                                <h2>Secured</h2>
                                <p>Send and receive blockchain enabled certificates and credentials. Pronetin ensures the confidentiality and integrity of your data.</p>
                            </div>
                            <div className='features'>
                                <img src={AIIcon} alt="secure" />
                                <h2>AI powered</h2>
                                <p>Our advanced AI technology automates the certificate design process, saving you time and effort. Create professional-looking certificates in minutes.</p>
                            </div>
                            <div className='features'>
                                <img src={QRIcon} alt="secure" />
                                <h2>Verified</h2>
                                <p>Increase the credibility and security of your certificates with our QR code verification system. QR code verification adds an extra layer of authenticity and prevents fraudulent activity.</p>
                            </div>

                        </div>
                    </div>
                    <div className='section process' id="features">
                        <div className='flex-column'>
                            <h1>Send your<br></br><span>credentials</span><br></br>hassle free</h1>
                            <p>Automate the certificate distribution process,<br></br>allowing you to focus on other important tasks.</p>
                        </div>
                        <img src={ProcessDesign} alt="process design" />
                        <div className='tagline'>
                            <h2><span>Join Pronetin</span><br></br>"Ready to simplify your certificate distribution process?<br></br>Get started with Pronetin today!"</h2>
                            <div className='features'>
                                <ul>
                                    <li>Save Time and Effort</li>
                                    <li>Professional-Looking Certificates</li>
                                    <li>Increased Credibility</li>
                                    <li>Seamless User Experience</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='full-height pricing' id="pricing">
                        <h1>Pricing</h1>
                        <div className='pricing-wrapper'>
                            <div className='pricing-container t1 white' onClick={() => window.location = "/signup"}>
                                <h2>Free</h2>
                                <div className='sub'>
                                    <p><i className="fa-regular fa-circle-check"></i> Limited free templates</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Certificate editor with limited features</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free 130 Pronetin credits</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Issue up to 120 certificates for free</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Can only use customizable pronetin templates.</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Customer support via Email</p>
                                </div>
                                <button className='buy-btn'><span>₹</span>0/<span>month</span> <i className="fa-solid fa-cart-shopping"></i></button>
                            </div>
                            <div className='pricing-container t2 white' onClick={() => window.location = "/signup"}>
                                <h2>Pro</h2>
                                <div className='sub'>
                                    <p><i className="fa-regular fa-circle-check"></i> All templates free</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Full fledge Certificate Editor</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free 530 credits/month</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Issue up to 520 certificates</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Collaborate design with up to 2 organisation members per design.</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Customizable pronetin templates + additional designs on request with payment</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Premium support</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Scheduled Onboarding & Training*</p>
                                </div>
                                <button className='buy-btn'><span>₹</span>999/<span>month</span> <i className="fa-solid fa-cart-shopping"></i></button>
                            </div>
                            <div className='pricing-container t2 blue' onClick={() => window.location = "/signup"}>
                                <h2>Business</h2>
                                <div className='sub'>
                                    <p><i className="fa-regular fa-circle-check"></i> All templates free</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Full fledge Certificate Editor</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                    <p><i className="fa-regular fa-circle-check"></i> free 1530 credits/month</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Issue up to 1520 certificates</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Collaborate design with up to 5 organisation members</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Customizable pronetin templates + Free 10 custom templates on request + Extra designs with payment</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Premium support</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Scheduled Onboarding & Training</p>
                                </div>
                                <button className='buy-btn'><span>₹</span>2999/<span>month</span> <i className="fa-solid fa-cart-shopping"></i></button>
                            </div>
                            <div className='pricing-container t1 dark' onClick={() => window.location = "/signup"}>
                                <h2>Enterprise</h2>
                                <div className='sub'>
                                    <p><i className="fa-regular fa-circle-check"></i> All templates free</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Full fledge Certificate Editor</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                    <p><i className="fa-regular fa-circle-check"></i> free 2530 credits/month</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Issue up to 2520 certificates</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Collaborate design with 10+ organisation members and 10+ non-organization members.</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Customizable pronetin templates + Free 10 custom templates on request + Extra designs with payment.</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Get pronetin designers for design assistance.</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Premium support</p>
                                    <p><i className="fa-regular fa-circle-check"></i> Any time Onboarding & Training</p>
                                </div>
                                <button className='buy-btn'><span>₹</span>4999/<span>month</span> <i className="fa-solid fa-cart-shopping"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='section moto' id="contact">
                        <h1>Fast</h1>
                        <img src={ShiedIcon} alt="shield icon" />
                        <h1>Secure</h1>
                    </div>

                    <div className='rel-footer-space'></div>
                    <div className='footer' id="footer">
                        <div className='footer-img-container'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                                <path fill="#e4e9ee" fillOpacity="1" d="M 0 0 L 570 0 Q 480 0 420 90 Q 360 180 270 180 L 0 180 L 0 0"></path>
                            </svg>
                            <img src={logo} alt="logo" />
                            <div className='content'>
                                <h2>Pronetin</h2>
                                <p>Authorisation and Distribution of Digital Certificates</p>
                            </div>
                        </div>
                        <div className='footer-wrapper'>
                            <p style={{ width: '100%', textAlign: 'center', color: '#FFF' }}>Email us at: help@pronetin.com</p>
                            <a href='#foo' data="landing" onClick={this.handleAnchorClick}>Home</a>
                            <a href='#foo' data="features" onClick={this.handleAnchorClick}>Features</a>
                            <a href='#foo' data="pricing" onClick={this.handleAnchorClick}>Pricing</a>
                            <a href='/signin'>Sign In</a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default LandingHome;