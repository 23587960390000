import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import QRIcon from '../../images/static/pronetin-logo-s.png';

class QRCodeGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "http://pronetin.in/v/" + this.props.data,
            showQRCode: false,
        };
    }

    componentDidMount() {
        this.setState({
            showQRCode: true
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                value: "http://pronetin.in/v/" + this.props.data,
            });
        }
    }

    render() {
        return (
            <div className="qr-div" id="qr-div">
                {this.state.showQRCode && (
                    <div className="container">
                        <QRCodeCanvas value={this.state.value} size={this.props.size} level={'H'} imageSettings={{ excavate: true, height: 30, width: 30 }} />
                        <img src={QRIcon} alt="QR Icon" id='qr-icon' />
                    </div>
                )}
            </div>
        );
    }
}

export default QRCodeGenerator;