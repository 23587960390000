import React from 'react';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            font: this.props.data,
            tempArray: [],
        };
    }

    replaceElements(newString, matchArray, replaceArray) {
        newString.forEach((subArray, i) => {
            subArray.forEach((str, j) => {
                matchArray.forEach((match) => {
                    if (replaceArray[match] && str === match) {
                        newString[i][j] = replaceArray[match];
                    }
                });
            });
        });
        this.setState({
            tempArray: newString,
        });
    }

    componentDidMount = () => {
        if (this.props.csvData && this.props.csvData.length) {
            const originalString = JSON.parse(JSON.stringify(this.props.data.content));
            const matchArray = this.props.variables;
            const replaceArray = this.props.csvDataIndex ? this.props.csvData[this.props.csvDataIndex] : this.props.csvData[0];
            this.replaceElements(originalString, matchArray, replaceArray)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.csvDataIndex !== this.props.csvDataIndex) {
            const originalString = JSON.parse(JSON.stringify(this.props.data.content));
            const matchArray = this.props.variables;
            const replaceArray = this.props.csvDataIndex ? this.props.csvData[this.props.csvDataIndex] : this.props.csvData[0];
            this.replaceElements(originalString, matchArray, replaceArray)
        }
    }

    render() {
        if (this.state.tempArray && this.state.tempArray.length) {
            return (
                <div className="box wrap viewer-wrap" style={{ top: this.state.font.y + "px", left: this.state.font.x + "px" }}>
                    <div className='table-wrapper' style={{ minHeight: (parseInt(this.state.rows) * 32) - 2 + "px", resize: this.state.controls ? "both" : "none", height: this.state.font.elHeight + "px", width: this.state.font.elWidth + "px", }}>
                        <table id={"table-view-" + this.state.font.timeStamp} style={{
                            fontSize: this.state.font.fontSize + "px",
                            fontFamily: this.state.font.fontFamily,
                            color: this.state.font.fontColor,
                            fontWeight: this.state.font.fontWeight ? "600" : "400",
                            fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                            textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                            textAlign: this.state.font.textAlign,
                            lineHeight: this.state.font.lineHeight,
                        }}
                        >
                            <tbody>
                                {
                                    this.state.tempArray.map((element, index) => (
                                        <tr key={"view-tr-" + index}>
                                            {
                                                element.map((x, i) => (
                                                    <td className='inherit' type="text" id={"view-" + index + i} key={"view-" + index + i}>{x}</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="box wrap viewer-wrap" style={{ top: this.state.font.y + "px", left: this.state.font.x + "px" }}>
                    <div className='table-wrapper' style={{ minHeight: (parseInt(this.state.rows) * 32) - 2 + "px", resize: this.state.controls ? "both" : "none", height: this.state.font.elHeight + "px", width: this.state.font.elWidth + "px", }}>
                        <table id={"table-view-" + this.state.font.timeStamp} style={{
                            fontSize: this.state.font.fontSize + "px",
                            fontFamily: this.state.font.fontFamily,
                            color: this.state.font.fontColor,
                            fontWeight: this.state.font.fontWeight ? "600" : "400",
                            fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                            textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                            textAlign: this.state.font.textAlign,
                            lineHeight: this.state.font.lineHeight,
                        }}
                        >
                            <tbody>
                                {
                                    this.state.font.content.map((element, index) => (
                                        <tr key={"view-tr-" + index}>
                                            {
                                                element.map((x, i) => (
                                                    <td className='inherit' type="text" id={"view-" + index + i} key={"view-" + index + i}>{x}</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }
}

export default ElementComponent;