import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import ProgressLoader from '../components/progress_loader';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            approval_list: [],
            uniqueEvents: [],
            filteredEvent: false,
            filteredEventValue: '',
            moreBtn: 'more',
            logContent: [],
            logNames: [],
            userColors: {},
            progress_loader: false,
            selectedLogMonth: '',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    async componentDidMount() {
        let approval_list = false;
        axios.defaults.withCredentials = true;
        await axios.post(this.props.node_server_axios + 'get_actions_list', {
            params: {
                org_id: this.props.org_id,
            },
        }).then(res => {
            if (res.data.approval_list) {
                this.setState({
                    approval_list: res.data.approval_list,
                    uniqueEvents: [...new Set(res.data.approval_list.map(cert => cert.type))],
                })
                approval_list = true;
            }
        });

        //To get action center history
        const formattedLogMonth = new Date().toISOString().slice(0, 7);

        axios.post(this.props.node_server_axios + 'get_action_center_logs', {
            params: {
                org_id: this.props.org_id,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    logNames: res.data.logs,
                })
            }
        });

        if (!approval_list) {
            this.setState({
                progress_loader: true,
                selectedLogMonth: formattedLogMonth
            });
            try {
                const response = await fetch('https://pronetin-new.s3.ap-south-1.amazonaws.com/history/' + this.props.org_id + '/' + formattedLogMonth + '.log');
                const data = await response.text();
                const logContent = data.split('\n').filter(entry => entry.trim() !== '');
                this.setState({
                    logContent: logContent,
                    progress_loader: false,
                }, () => {
                    this.generateUserColors(); // Call function to generate user colors after state is updated
                });
            } catch (error) {
                console.error('Error fetching log content:', error);
                this.setState({
                    warning: {
                        message: "Sorry, Unable to fetch this months log.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                });
            }
        }
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    acceptBatch = (document_id, email, data, type) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'accept_action_center', {
            params: {
                email: email,
                org_id: this.props.org_id,
                document_id: document_id,
                data: data,
                type: type,
            },
        }).then(async res => {
            if (res.data.message === 'success') {
                this.setState({
                    approval_list: res.data.approval_list,
                    uniqueEvents: [...new Set(res.data.approval_list.map(cert => cert.type))],
                    warning: {
                        message: "Changes approved.",
                        type: "success",
                        learnmore: false,
                        autoclose: false,
                    },
                })
                this.removeNotificationByAction("Action center request from " + email);
                //---------------------Logging Data (Action Center) Start---------------------
                await axios.post(this.props.node_server_axios + 'update_action_center_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        user: email,
                        content: "Accepted name change to " + data,
                    },
                })
                //----------------------Logging Data (Action Center) End----------------------
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: 'help#18_Organisations',
                        autoclose: false,
                    },
                })
            }
        });
    }

    rejectBatch = (email, document_id) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'reject_action_center', {
            params: {
                email: email,
                org_id: this.props.org_id,
                document_id: document_id,
            },
        }).then(async res => {
            if (res.data.message === 'success') {
                this.setState({
                    approval_list: res.data.approval_list,
                    uniqueEvents: [...new Set(res.data.approval_list.map(cert => cert.type))],
                    warning: {
                        message: "Changes rejected.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                })
                this.removeNotificationByAction("Action center request from " + email);
                //---------------------Logging Data (Action Center) Start---------------------
                await axios.post(this.props.node_server_axios + 'update_action_center_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        user: email,
                        content: "Rejected name change request",
                    },
                })
                //----------------------Logging Data (Action Center) End----------------------
            }
        });
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    generateUserColors() {
        const userColors = {};
        this.state.logContent.forEach(entry => {
            const [user] = entry.split('|');
            if (!userColors[user]) {
                userColors[user] = this.getRandomColor();
            }
        });
        this.setState({ userColors });
    }

    changeLogMonth = async (e, element) => {
        e.preventDefault();
        this.setState({
            progress_loader: true,
            selectedLogMonth: element,
        })
        try {
            const response = await fetch('https://pronetin-new.s3.ap-south-1.amazonaws.com/history/' + this.props.org_id + '/' + element + '.log');
            const data = await response.text();
            const logContent = data.split('\n').filter(entry => entry.trim() !== '');
            this.setState({
                logContent: logContent,
                progress_loader: false,
            }, () => {
                this.generateUserColors(); // Call function to generate user colors after state is updated
            });
        } catch (error) {
            console.error('Error fetching log content:', error);
            this.setState({
                warning: {
                    message: "Sorry, Unable to fetch this months log.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                },
                progress_loader: false,
            });
        }
    }

    filterEvent = (e, element) => {
        e.preventDefault();
        this.setState({
            filteredEventValue: element,
            filteredEvent: true,
            logContent: [],
        })
        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    clearFilter = () => {
        this.setState({
            filteredEventValue: '',
            filteredEvent: false,
            logContent: [],
        })
    }

    removeNotificationByAction = (keyword) => {
        axios.post(this.props.node_server_axios + 'delete_notification_by_action', {
            params: {
                keyword: keyword,
            }
        });
    }

    downloadLog = (log) => {
        fetch("https://pronetin-new.s3.ap-south-1.amazonaws.com/history/" + this.props.org_id + "/" + log + ".log")
            .then(response => response.text())
            .then(content => {
                var replacedContent = content.replace(/\|/g, ',');
                var blob = new Blob(['user,action,recepient,timestamp\n' + replacedContent], { type: 'text/csv' });
                var blobUrl = URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = blobUrl;
                link.target = '_blank';
                link.download = "actionCenter_" + log + "_log";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            })
    }

    render() {
        const { userColors } = this.state;

        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Action Center<label>Approve requests from users</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-folder-tree"></i> Actions</h1>
                                <div className='nav-container'>
                                    {this.state.uniqueEvents.length > 0 && (
                                        this.state.uniqueEvents.map((element, index) =>
                                            <a className='nav' href="foo" key={index} onClick={(e) => { this.filterEvent(e, element) }}>
                                                <i className="fa-regular fa-folder-open"></i>
                                                {element}
                                            </a>
                                        )
                                    )}
                                </div>
                                <br></br>
                                <h1><i className="fa-solid fa-folder-tree"></i> History</h1>
                                <div className='nav-container'>
                                    {this.state.logNames.length > 0 ?
                                        this.state.logNames.map((element, index) =>
                                            <a className='nav' href="foo" key={index} onClick={(e) => { this.changeLogMonth(e, element) }}>
                                                <i className="fa-regular fa-folder-open"></i>
                                                {element}
                                            </a>
                                        )
                                        :
                                        <p>No history Yet</p>
                                    }
                                </div>
                            </div>
                            {
                                <div className='approval-wrapper'>
                                    {this.state.logContent && this.state.logContent.length > 0 ?
                                        <div className='activity-logs'>
                                            <div className='selector'>
                                                <p>Activity log for {this.state.selectedLogMonth}</p>
                                                <div className='fg-1'></div>
                                                <button onClick={() => this.downloadLog(this.state.selectedLogMonth)}><p>Download</p> <i className="fa-regular fa-circle-down"></i></button>
                                            </div>
                                            <div className='overflow-table-scroll'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>User</th>
                                                            <th>Action</th>
                                                            <th>Recepient</th>
                                                            <th>Timestamp</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.logContent.map((entry, index) => {
                                                            const [user, action, recepient, timestamp] = entry.split('|');
                                                            const userColor = userColors[user];
                                                            if (recepient !== undefined) {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td><p><span className='user-ico' style={{ backgroundColor: userColor }}>{user.charAt(0)}</span>{user}</p></td>
                                                                        <td>{action}</td>
                                                                        <td><p>{recepient}</p></td>
                                                                        <td><p>{timestamp}</p></td>
                                                                    </tr>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        this.state.approval_list.length > 0 ?
                                            !this.state.filteredEvent ?
                                                this.state.approval_list.map((element, index) => {
                                                    return (
                                                        <div className='container' key={index}>
                                                            <div>
                                                                <h2>{element.type}</h2>
                                                                <h3>Please change my data from <span className='red'>{element.old_data}</span> to <span className='green'>{element.new_data}</span></h3>
                                                            </div>
                                                            <div className='flex-grow'></div>
                                                            <div className='actions'>
                                                                <p>User : {element.email}</p>
                                                                <div className='flex'>
                                                                    <button className='reject' onClick={() => { this.rejectBatch(element.email, element.document_id) }}>Reject</button>
                                                                    <button className='sign' onClick={() => { this.acceptBatch(element.document_id, element.email, element.new_data, element.type) }}>Approve <i className="fa-solid fa-circle-check"></i></button>
                                                                </div>
                                                            </div>

                                                        </div>)
                                                })
                                                :
                                                this.state.approval_list.filter(obj => obj.type === this.state.filteredEventValue).map((element, index) => {
                                                    return (
                                                        <div className='container' key={index}>
                                                            <div>
                                                                <h2>{element.type}</h2>
                                                                <h3>Please change my data from <span className='red'>{element.old_data}</span> to <span className='green'>{element.new_data}</span></h3>
                                                            </div>
                                                            <div className='flex-grow'></div>
                                                            <div className='actions'>
                                                                <p>User : {element.email}</p>
                                                                <div className='flex'>
                                                                    <button className='reject' onClick={() => { this.rejectBatch(element.email, element.document_id) }}>Reject</button>
                                                                    <button className='sign' onClick={() => { this.acceptBatch(element.document_id, element.email, element.new_data, element.type) }}>Approve <i className="fa-solid fa-circle-check"></i></button>
                                                                </div>
                                                            </div>

                                                        </div>)
                                                })
                                            :
                                            <p>All cleaned up here, No actions available</p>
                                    }
                                    {
                                        this.state.filteredEvent && (<button onClick={this.clearFilter}>View All Events</button>)
                                    }
                                </div>
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;