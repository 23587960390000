import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import ProgressLoader from '../components/progress_loader';

class LogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            logContent: [],
            logNames: [],
            userColors: {},
            progress_loader: false,
            selectedLogMonth: '',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    async componentDidMount() {
        const formattedLogMonth = new Date().toISOString().slice(0, 7);

        this.setState({
            progress_loader: true,
            selectedLogMonth: formattedLogMonth
        });
        axios.post(this.props.node_server_axios + 'get_organisation_logs', {
            params: {
                org_id: this.props.org_id,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    logNames: res.data.logs,
                })
            }
        });

        try {
            const response = await fetch('https://pronetin-new.s3.ap-south-1.amazonaws.com/logs/' + this.props.org_id + '/' + formattedLogMonth + '.log');
            const data = await response.text();
            const logContent = data.split('\n').filter(entry => entry.trim() !== '');
            this.setState({
                logContent: logContent,
                progress_loader: false,
            }, () => {
                this.generateUserColors(); // Call function to generate user colors after state is updated
            });
        } catch (error) {
            console.error('Error fetching log content:', error);
            this.setState({
                warning: {
                    message: "Sorry, Unable to fetch this months log.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                },
                progress_loader: false,
            });
        }
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    generateUserColors() {
        const userColors = {};
        this.state.logContent.forEach(entry => {
            const [user] = entry.split('|');
            if (!userColors[user]) {
                userColors[user] = this.getRandomColor();
            }
        });
        this.setState({ userColors });
    }

    changeLogMonth = async (e) => {
        this.setState({
            progress_loader: true,
            selectedLogMonth: e.target.value,
        })
        try {
            const response = await fetch('https://pronetin-new.s3.ap-south-1.amazonaws.com/logs/' + this.props.org_id + '/' + e.target.value + '.log');
            const data = await response.text();
            const logContent = data.split('\n').filter(entry => entry.trim() !== '');
            this.setState({
                logContent: logContent,
                progress_loader: false,
            }, () => {
                this.generateUserColors(); // Call function to generate user colors after state is updated
            });
        } catch (error) {
            console.error('Error fetching log content:', error);
            this.setState({
                warning: {
                    message: "Sorry, Unable to fetch this months log.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                },
                progress_loader: false,
            });
        }
    }

    downloadLog = (log) => {
        fetch("https://pronetin-new.s3.ap-south-1.amazonaws.com/logs/" + this.props.org_id + "/" + log + ".log")
            .then(response => response.text())
            .then(content => {
                var replacedContent = content.replace(/\|/g, ',');
                var blob = new Blob(['user,action,credit,timestamp\n' + replacedContent], { type: 'text/csv' });
                var blobUrl = URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = blobUrl;
                link.target = '_blank';
                link.download = log + "_log";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            })
    }

    render() {
        const { userColors } = this.state;

        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Activity Log<label>All the activities in your organisation will be listed here</label></h1>
                            </div>
                            <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                        </div>
                        <div className='tiles marketplace no-padding-top'>
                            <div className='activity-logs'>
                                <div className='selector'>
                                    <p>Activity log for</p>
                                    <select onChange={this.changeLogMonth} value={this.state.selectedLogMonth}>
                                        {this.state.logNames.length > 0 ?
                                            this.state.logNames.map((item, index) => {
                                                return (<option key={index} value={item}>{item}</option>);
                                            })
                                            :
                                            <option>{this.state.selectedLogMonth}</option>
                                        }
                                    </select>
                                    <div className='fg-1'></div>
                                    <button onClick={() => this.downloadLog(this.state.selectedLogMonth)}><p>Download</p> <i className="fa-regular fa-circle-down"></i></button>
                                </div>
                                <div className='overflow-table-scroll'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Action</th>
                                                <th>Credits</th>
                                                <th>Timestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.logContent.map((entry, index) => {
                                                const [user, action, credits, timestamp] = entry.split('|');
                                                const userColor = userColors[user];
                                                if (credits !== undefined) {
                                                    return (
                                                        <tr key={index}>
                                                            <td><p><span className='user-ico' style={{ backgroundColor: userColor }}>{user.charAt(0)}</span>{user}</p></td>
                                                            <td>{action}</td>
                                                            <td><p style={!credits.includes('+') && !credits.includes('-') ? { color: "#CCC" } : credits.includes('+') ? { color: "#21937B" } : { color: "#D5415A" }}>{credits}</p></td>
                                                            <td><p>{timestamp}</p></td>
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default LogComponent;