import React from 'react';
import Draggable from 'react-draggable';
import Element from './editor/editor';
import Image from './editor/image';
import Table from './editor/table';
import Signee from './editor/signee';
import { SketchPicker } from 'react-color'

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import HelpEditor from './help_editor';

import Warning from '../components/warning';

import domtoimage from 'dom-to-image';

// import { Buffer } from 'buffer';

import axios from 'axios';

// import DragText from './editor/draggable_text'

import './css/editor.css';
import './css/fonts.css';

import emailDomains from '../data/email_domains';

import GuideLines from './guide_lines';

class ColorPickerComponent extends React.Component {

    constructor(props) {
        super(props);
        this.handleChangeComplete.bind(this);
        this.state = {
            background: this.props.currentColor ? this.props.currentColor : '#000',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.dropperColor && prevProps.dropperColor !== this.props.dropperColor) {
            this.setState({ background: this.props.dropperColor });
            this.props.setStateOfParent(this.props.dropperColor);
        } else if (prevProps.currentColor !== this.props.currentColor) {
            this.setState({ background: this.props.currentColor ? this.props.currentColor : '#000' })
        }
    }

    handleChange = (color) => {
        this.setState({ background: color.hex });
    };

    handleChangeComplete = (color) => {
        this.props.setStateOfParent(color.hex);
    }

    render() {
        return <SketchPicker
            color={this.state.background}
            onChange={this.handleChange}
            onChangeComplete={this.handleChangeComplete}
            //-------------------Update Array with recently used color codes------------------------
            presetColors={this.props.colors ? this.props.colors : ['#000000', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8']}
        />
    }
}

class EditorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.setStateOfParent.bind(this);
        this.handleSendToParent.bind(this);
        this.outsideClickRef = React.createRef(null);
        this.saveModalRef = React.createRef();
        this.imageRef = React.createRef();
        this.state = {
            certificate_default_zoom: false,
            certificate_zoom: 1,
            initial: { x: 0, y: 0 },
            move_canvas: true,
            toAdd: this.props.toAdd,
            csvData: this.props.addRecepient,
            history: [],
            historyIndex: 0,
            elements: this.props.elements,
            last_selected_element: 0,
            textAlignDropdown: false,
            lineHeightDropdown: false,
            palette: false,
            enableGrid: false,
            intervalId: null,
            cloudUpdate: false,
            variables: [],
            preview: true,
            screenshot: false,
            imgData: null,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            creditsUsed: 1,
            totalCreditsUsed: 1,
            tagText: '',
            tagContent: false,
            tags: [],
            tagWarning: false,
            tagData: [],
            tagDataDefault: [],
            tagDataFavorites: [],
            tagType: 'pronetin',
            tagModal: false,
            copied: false,
            copiedIndex: false,
            eventName: '',
            subeventName: '',
            eventModal: false,
            eventArray: [],
            subeventArray: [],
            newEvent: false,
            newSubevent: false,
            smartAds: true,
            feedbacks: false,
            feedbacks_mandatory: false,
            feedbacks_anonymous: false,
            elementsCount: 0,
            enableSubevent: false,
            QRSize: 125,
            certWidth: this.props.certWidth,
            certHeight: this.props.certHeight,
            loading_screen: false,
            saveModal: false,
            savedTemplateCount: this.props.savedTemplateCount,
            savedTemplateLimit: 3,
            helpModal: false,
            cacheTs: Date.now(),
            hide_signature: false,
            deleteHistory: false,
            colorPickMode: false,
            colorPickimgData: null,
            showPickerColor: false,
            cursorX: 0,
            cursorY: 0,
            liveDropperColor: '#FFF',
            batchCreation: false,
            csvWarnings: true,
            csvWarningModal: false,
            csvWarningArray: [],
            gridLevel: 5,
            history_update_id: false,
        }
        this.timeoutId = null;
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    toggleGrid = (e) => {
        this.setState({
            enableGrid: e.target.checked,
        })
    }

    handleHistory = (id) => {
        this.setState({
            history_update_id: id,
        })
    }

    historyHandle = (element) => {
        if (element) {
            //handle history clear on midway edit
            let new_history = this.state.history;
            let elemsToDelete = new_history.length - this.state.historyIndex - 1;
            if (elemsToDelete < 0) {
                elemsToDelete = 0;
            }
            while (elemsToDelete--) {
                new_history.pop();
            }

            //length to handle history
            if (this.state.history.length > 30) {
                this.setState({
                    history: [...this.state.history.slice(1), element],
                    historyIndex: this.state.history.length - 1,
                    cloudUpdate: false,
                    history_update_id: false,
                })
            } else {
                //check if movement of element
                if (!this.state.history.some(el => el.timeStamp === element.timeStamp && el.x === element.x && el.y === element.y && el.elWidth === element.elWidth && el.elHeight === element.elHeight)) {
                    this.setState({
                        history: [...this.state.history, element],
                        historyIndex: this.state.history.length,
                        cloudUpdate: false,
                        history_update_id: false,
                    })
                }
            }
        }
    }

    undoHandle = () => {
        //if the condition not present delete can be recovered (bug)
        const element = this.state.history[this.state.historyIndex];
        if (this.state.historyIndex >= 0 && element !== undefined) {
            let indexSlice = this.state.elements.findIndex(el => el.timeStamp === element.timeStamp);
            if (indexSlice >= 0) {
                if (this.state.historyIndex - 1 > 0 && this.state.elements.some(el => el.timeStamp === element.timeStamp && el.x === element.x && el.y === element.y && el.elWidth === element.elWidth && el.elHeight === element.elHeight)) {
                    this.setState({
                        historyIndex: this.state.historyIndex - 1 < 0 ? 0 : this.state.historyIndex - 1,
                    }, () => {
                        this.undoHandle();
                    })
                } else {
                    this.setState({
                        historyIndex: this.state.historyIndex - 1 < 0 ? 0 : this.state.historyIndex - 1,
                        elements: [
                            ...this.state.elements.slice(0, indexSlice),
                            element,
                            ...this.state.elements.slice(indexSlice + 1)
                        ],
                    })
                }
                if (this.state.historyIndex === this.state.history.length - 1 && !this.state.history.some(el => el.timeStamp === this.state.elements[indexSlice].timeStamp && el.x === this.state.elements[indexSlice].x && el.y === this.state.elements[indexSlice].y)) {
                    this.setState({
                        history: [...this.state.history, this.state.elements[indexSlice]],
                    })
                }
            } else {
                //clear history on delete
                this.setState({
                    history: [],
                    historyIndex: 0,
                })
            }
        }
    }

    redoHandle = () => {
        const element = this.state.history[this.state.historyIndex + 1];
        if (this.state.historyIndex <= this.state.history.length && this.state.history[this.state.historyIndex + 1] !== undefined) {
            let indexSlice = this.state.elements.findIndex(el => el.timeStamp === this.state.history[this.state.historyIndex + 1].timeStamp);
            if (this.state.historyIndex + 1 <= this.state.history.length && this.state.elements.some(el => el.timeStamp === element.timeStamp && el.x === element.x && el.y === element.y && el.elWidth === element.elWidth && el.elHeight === element.elHeight)) {
                this.setState({
                    historyIndex: this.state.historyIndex + 1 >= this.state.history.length ? this.state.historyIndex : this.state.historyIndex + 1,
                }, () => {
                    this.redoHandle();
                })
            } else {
                this.setState({
                    elements: [
                        ...this.state.elements.slice(0, indexSlice),
                        this.state.history[this.state.historyIndex + 1],
                        ...this.state.elements.slice(indexSlice + 1)
                    ],
                    historyIndex: this.state.historyIndex + 1 >= this.state.history.length ? this.state.historyIndex : this.state.historyIndex + 1,
                })
            }
        }
    }

    undoDelete = (values) => {
        if (values) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
            this.setState(prevState => {
                const updatedElements = [...prevState.elements, values];
                return {
                    elements: updatedElements,
                    deleteHistory: false,
                };
            }, () => {
                this.indexAttributes()
            });
        }
    }

    handleSendToParent = (values) => {
        if (values.toDelete !== false && this.state.elements[values.id].required === false) {
            this.setState(({ elements }) => ({
                elements: elements.filter(function (obj) {
                    return obj.timeStamp !== values.toDelete;
                }),
                deleteHistory: elements.filter(function (obj) {
                    return obj.timeStamp === values.toDelete;
                }),
                last_selected_element: (values.id - 1),
            }), () => {
                if (values.image.type === 'image') {
                    this.props.fetchChildState({ type: false, timeStamp: false, })
                }
                this.indexAttributes();
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
                const bar = document.querySelector('.undo-delete .bar');
                bar.classList.remove('animated');
                void bar.offsetWidth;
                bar.classList.add('animated');
            });
        } else {
            this.setState(({ elements }) => ({
                elements: [
                    ...elements.slice(0, (values.id)),
                    {
                        ...elements[values.id],
                        content: values.content,
                        rows: values.rows !== undefined ? values.rows : false,
                    },
                    ...elements.slice(values.id + 1)
                ],
                last_selected_element: values.id,
                cloudUpdate: false,
            }), () => {
                this.indexAttributes()
            });
        }
        this.setState({
            textAlignDropdown: false,
            lineHeightDropdown: false,
            palette: false,
        })
    }

    handleDragStop = (values) => {
        this.setState(({ elements }) => ({
            elements: [
                ...elements.slice(0, (values.id)),
                {
                    ...elements[values.id],
                    x: values.x,
                    y: values.y,
                },
                ...elements.slice(values.id + 1)
            ],
            last_selected_element: values.id,
        }));
    }

    handleResize = (values) => {
        this.setState(({ elements }) => ({
            elements: [
                ...elements.slice(0, (values.id)),
                {
                    ...elements[values.id],
                    elHeight: values.textarea.height,
                    elWidth: values.textarea.width,
                },
                ...elements.slice(values.id + 1)
            ],
            last_selected_element: values.id,
        }), () => {
            if (values.font) {
                this.props.fetchChildStageSelect('2')
            } else if (values.image) {
                if (values.image.type === "signature") {
                    this.props.fetchChildStageSelect('5')
                } else {
                    this.props.fetchChildStageSelect('3')
                }
            }
            this.indexAttributes()
        });
    }

    handleResizeTable = (values) => {
        this.setState(({ elements }) => ({
            elements: [
                ...elements.slice(0, (values.id)),
                {
                    ...elements[values.id],
                    elHeight: values.textarea.height,
                    elWidth: values.textarea.width,
                },
                ...elements.slice(values.id + 1)
            ],
        }), () => {
            this.indexAttributes()
        });
    }

    setTableStage = () => {
        this.props.fetchChildStageSelect('2')
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    recenterCertificate = () => {
        this.setState({
            certificate_zoom: this.state.certificate_default_zoom ? this.state.certificate_default_zoom : 1,
            initial: { x: 0, y: 0 }
        });
    }

    toggleMovement = () => {
        this.setState({
            move_canvas: !this.state.move_canvas,
        });
    }

    handleCertificateDrag = (event, dragElement) => {
        this.setState({ initial: { x: dragElement.x, y: dragElement.y } });
    }

    updateFont = (e) => {
        if (e.currentTarget.dataset.type === "value") {
            if (e.target.dataset.value) {
                this.setState(({ elements }) => ({
                    elements: [
                        ...elements.slice(0, this.state.last_selected_element),
                        {
                            ...elements[this.state.last_selected_element],
                            [e.target.id]: e.target.dataset.value,
                        },
                        ...elements.slice(this.state.last_selected_element + 1)
                    ],
                }));
            } else {
                this.setState(({ elements }) => ({
                    elements: [
                        ...elements.slice(0, this.state.last_selected_element),
                        {
                            ...elements[this.state.last_selected_element],
                            [e.target.id]: e.target.value,
                        },
                        ...elements.slice(this.state.last_selected_element + 1)
                    ],
                }));
            }
        } else if (e.currentTarget.dataset.type === "bool") {
            switch (e.target.id) {
                case 'fontWeight':
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                fontWeight: !this.state.elements[this.state.last_selected_element].fontWeight,
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                case 'fontStyle':
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                fontStyle: !this.state.elements[this.state.last_selected_element].fontStyle,
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                case 'textDecorationLine':
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                textDecorationLine: !this.state.elements[this.state.last_selected_element].textDecorationLine,
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                default:
                    break;
            }
        }
    }

    togglePalette = (e) => {
        if (e.currentTarget === e.target) {
            this.setState({
                lineHeightDropdown: false,
                textAlignDropdown: false,
                palette: !this.state.palette,
                colorPickMode: false,
                showPickerColor: false,
            })
        }
    }

    setStateOfParent = (newTitle) => {
        this.setState(({ elements }) => ({
            elements: [
                ...elements.slice(0, this.state.last_selected_element),
                {
                    ...elements[this.state.last_selected_element],
                    fontColor: newTitle,
                },
                ...elements.slice(this.state.last_selected_element + 1)
            ],
        }));
    }

    static getDerivedStateFromProps(props, state) {
        const duplicate = state.elements.find(obj => obj.timeStamp === props.toAdd.timeStamp);
        if (!duplicate && props.toAdd.timeStamp !== state.toAdd.timeStamp && props.toAdd.type !== false) {
            return {
                toAdd: props.toAdd,
                elements: [...state.elements, props.toAdd],
                last_selected_element: state.elements.length,
                csvData: props.addRecepient,
                totalCreditsUsed: state.creditsUsed * props.addRecepient.length,
                savedTemplateCount: props.savedTemplateCount,
            };
        } else {
            if (props.toAdd.timeStamp === false) {
                return {
                    toAdd: props.toAdd,
                    csvData: props.addRecepient,
                    totalCreditsUsed: state.creditsUsed * props.addRecepient.length,
                    savedTemplateCount: props.savedTemplateCount,
                }
            } else {
                return {
                    csvData: props.addRecepient,
                    totalCreditsUsed: state.creditsUsed * props.addRecepient.length,
                    savedTemplateCount: props.savedTemplateCount,
                };
            }
        }
    }

    showtextAlignDropdown = () => {
        this.setState({
            palette: false,
            lineHeightDropdown: false,
            textAlignDropdown: !this.state.textAlignDropdown,
        })
    }

    showlineHeightDropdown = () => {
        this.setState({
            textAlignDropdown: false,
            palette: false,
            lineHeightDropdown: !this.state.lineHeightDropdown,
        })
    }

    textAlignRender = (value) => {
        switch (value) {
            case 'left':
                return <i className="fa-solid fa-align-left"></i>
            case 'right':
                return <i className="fa-solid fa-align-right"></i>
            case 'center':
                return <i className="fa-solid fa-align-center"></i>
            case 'justify':
                return <i className="fa-solid fa-align-justify"></i>
            default:
                return <i className="fa-solid fa-align-left"></i>
        }
    }

    indexAttributes = () => {
        this.props.fetchChildElementIndex(this.state.elements.filter(element => element.type !== "image").map(element => ({ timeStamp: element.timeStamp, type: element.type, required: element.required, readOnly: element.readOnly, content: element.content })))
        this.props.fetchChildElements(this.state.elements)
        let tableVariables = []
        if (this.state.elements.filter(element => element.type === "table").length > 0) {
            tableVariables = this.state.elements.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
        }
        let variableIndexElements = this.state.elements.filter(element => element.type !== "image" && element.type !== "signature" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
        this.props.fetchInlineVariableIndex(variableIndexElements)
        let variablesLocal = variableIndexElements.map(element => element.variables)
        let mergedVariablesLocal = [].concat.apply([], variablesLocal);
        let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
        uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);
        if (this.state.variables !== ["[Email]"].concat(uniqueVariablesLocal)) {
            this.props.clearCsv();
        }
        this.setState({
            variables: ["[Email]"].concat(uniqueVariablesLocal),
            elementsCount: this.state.elements.length,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('update');
        if (prevProps.elements !== this.props.elements || prevProps.certWidth !== this.props.certWidth) {
            // console.log("updated template");
            this.setState({
                elements: this.props.elements,
                certWidth: this.props.certWidth,
                certHeight: this.props.certHeight,
            })
        }

        document.removeEventListener("keydown", this.handleHistoryKeyDown);
        document.removeEventListener("keydown", this.handleArrowKeys);
        document.removeEventListener("mousedown", this.handleClickOutside)

        if (this.state.toAdd.type && this.state.toAdd.type !== "image" && prevProps.toAdd.timeStamp !== this.state.toAdd.timeStamp) {
            this.indexAttributes()
        }
        if (this.props.step_selection === '2') {
            document.addEventListener("mousedown", this.handleClickOutside, false);
        }
        if (this.props.step_selection !== '6' && !this.state.eventModal) {
            document.addEventListener("keydown", this.handleHistoryKeyDown);
            document.addEventListener("keydown", this.handleArrowKeys);
        }
        if (this.props.step_selection !== '6' && this.state.eventModal) {
            this.setState({
                eventModal: false,
            })
        }

        if (this.props.elementToDelete) {
            const elementToDelete = this.props.elementToDelete;
            this.setState(({ elements }) => ({
                elements: elements.filter(function (obj) {
                    return obj.timeStamp !== elementToDelete;
                }),
                last_selected_element: this.state.last_selected_element < 1 ? 0 : (this.state.last_selected_element - 1),
            }), () => {
                this.indexAttributes()
            });
        }

        if (this.state.deleteHistory) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
            this.timeoutId = setTimeout(() => {
                this.setState({
                    deleteHistory: false,
                });
            }, 6300);
        }

        if (this.props.certTemplate && prevProps.certTemplate !== this.props.certTemplate) {
            this.setState({
                cloudUpdate: false,
            });
        }

        if (document.getElementsByClassName('cert-container')[0] !== undefined) {
            let certificate_default_zoom = 1;
            let certElement = parseInt(document.getElementsByClassName('cert-container')[0].getBoundingClientRect().height);
            if (certElement > 670) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.7 : 1;
            } else if (certElement > 590) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.6 : 0.9;
            } else if (certElement > 520) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.5 : 0.8;
            } else if (certElement > 460) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.4 : 0.7;
            } else if (certElement > 390) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.4 : 0.6;
            } else if (certElement > 330) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.3 : 0.5;
            } else if (certElement <= 330) {
                certificate_default_zoom = this.state.certHeight > 700 ? 0.3 : 0.4;
            }
            if (this.state.certificate_default_zoom !== certificate_default_zoom) {
                this.setState({
                    certificate_default_zoom: certificate_default_zoom,
                    certificate_zoom: certificate_default_zoom,
                })
            }
        }

        if (prevState.csvData !== this.state.csvData) {
            this.setState({
                csvWarnings: true,
            })
        }

        if (this.state.history_update_id !== false) {
            this.historyHandle(this.state.elements[this.state.history_update_id]);
        }
    }

    pushToDatabase = (type) => {
        if (type === 'forced') {
            this.setState({
                saveModal: false,
            });
        }

        if (this.state.cloudUpdate === false) {
            const updatedData = this.state.elements.map(element => {
                if (element.type === 'image' && element.content !== 'shape') {
                    return { ...element, content: '' };
                }
                return element;
            });

            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'saved_template_update', {
                params: {
                    save_id: this.props.saveId,
                    save_org_id: this.props.orgId,
                    elements: updatedData,
                    save_email: this.props.save_email,
                    draft_bg: this.props.certTemplate,
                },
            }).then((res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'updated') {
                        this.setState({
                            cloudUpdate: true,
                        })
                        if (type === 'forced') {
                            this.setState({
                                warning: {
                                    message: "Changes Saved",
                                    type: "success",
                                    learnmore: false,
                                    autoclose: true,
                                }
                            })
                        }
                    } else {
                        this.setState({
                            cloudUpdate: false,
                        })
                    }
                } else {
                    window.location = "/signin";
                }
            }).catch(() => {
                window.location = "/signin";
            })
        }

        if (type === 'forced' && this.state.cloudUpdate) {
            const animatedDiv = document.getElementsByClassName("auto-save")[0];
            animatedDiv.classList.add("animate");
            // Remove the "animate" class after the animation is complete
            animatedDiv.addEventListener("animationend", () => {
                animatedDiv.classList.remove("animate");
            }, { once: true });
        }
    }

    saveAsNew = () => {
        if (this.state.cloudUpdate && this.state.savedTemplateCount < this.state.savedTemplateLimit) {
            axios.post(this.props.node_server_axios + 'save_as_from_saved', {
                params: {
                    email: this.props.email,
                    save_id: this.props.saveId,
                    org_id: this.props.orgId,
                },
            }).then(async (res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'saved') {
                        this.setState({
                            warning: {
                                message: "Saved as new. Go to My Designs to see all saved designs.",
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            savedTemplateCount: res.data.saved_templates.length,
                            saveModal: false,
                        })
                        this.props.updateSavedTemplateCount();
                    } else if (res.data.message === 'limit reached') {
                        this.setState({
                            warning: {
                                message: "Limit to save new designs reached.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                            saveModal: false,
                        })
                    } else {
                        this.setState({
                            warning: {
                                message: "Failed to save file.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                            saveModal: false,
                        })
                    }
                }
            })
        } else {
            if (this.state.savedTemplateCount >= this.state.savedTemplateLimit) {
                this.setState({
                    warning: {
                        message: "Delete a slot by going to my designs and save again.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    saveModal: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Please save draft before saving as new.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        }
    }

    getDraft = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_saved_templates_by_id', {
            params: {
                email: this.props.email,
                save_id: this.props.saveId,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved templates') {
                    const updatedData = await Promise.all(JSON.parse(res.data.saved_templates[0].draft).map(async (element) => {
                        if (element.type === 'image' && element.content !== 'shape') {
                            const response = await fetch("https://pronetin-new.s3.amazonaws.com/saved/" + encodeURIComponent(this.props.saveId) + "/" + encodeURIComponent(element.timeStamp + ".png"));
                            const data = await response.arrayBuffer();
                            return new Promise((resolve, reject) => {
                                let reader = new FileReader();
                                reader.readAsDataURL(new Blob([data]));
                                reader.onloadend = () => {
                                    let base64data = reader.result;
                                    resolve({ ...element, content: base64data });
                                }
                            });
                        } else {
                            return element;
                        }
                    }));
                    this.setState({
                        elements: updatedData,
                    })
                }
            }
        })
    }

    handleHistoryKeyDown = (event) => {
        if (event.ctrlKey && event.key === "z") {
            this.undoHandle();
        } else if (event.ctrlKey && event.key === "y") {
            this.redoHandle();
        }
    };

    handleArrowKeys = (e) => {
        let canvasWidth = document.querySelector('.cert-container').offsetWidth;
        let canvasHeight = document.querySelector('.cert-container').offsetHeight;

        let parent = document.getElementsByClassName("elements")[0];
        let result = Array.from(parent.children).some(child => child.classList.contains("pinned"));
        if ((!result && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'SELECT' && this.state.move_canvas) || document.activeElement.getAttribute('name') === 'arrow-controls') {
            switch (e.key) {
                case "Delete":
                    e.preventDefault();
                    if (this.state.last_selected_element && this.state.elements[this.state.last_selected_element].required === false) {
                        let timeStampToDelete = this.state.elements[this.state.last_selected_element].timeStamp
                        this.setState(({ elements }) => ({
                            elements: elements.filter(function (obj) {
                                return obj.timeStamp !== timeStampToDelete;
                            }),
                            deleteHistory: elements.filter(function (obj) {
                                return obj.timeStamp === timeStampToDelete;
                            }),
                            last_selected_element: (this.state.last_selected_element - 1),
                        }), () => {
                            if (this.state.deleteHistory[0].type === 'image') {
                                this.props.fetchChildState({ type: false, timeStamp: false, })
                            }
                            this.indexAttributes();
                            clearTimeout(this.timeoutId);
                            this.timeoutId = null;
                            const bar = document.querySelector('.undo-delete .bar');
                            bar.classList.remove('animated');
                            void bar.offsetWidth;
                            bar.classList.add('animated');
                        });
                    }
                    break;
                case "ArrowRight":
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                x: elements[this.state.last_selected_element].x < canvasWidth ? parseInt(elements[this.state.last_selected_element].x + 1) : parseInt(elements[this.state.last_selected_element].x),
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                case "ArrowLeft":
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                x: elements[this.state.last_selected_element].x > 0 ? parseInt(elements[this.state.last_selected_element].x - 1) : parseInt(elements[this.state.last_selected_element].x),
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                case "ArrowDown":
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                y: elements[this.state.last_selected_element].y < canvasHeight ? parseInt(elements[this.state.last_selected_element].y + 1) : parseInt(elements[this.state.last_selected_element].y),
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                case "ArrowUp":
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                y: elements[this.state.last_selected_element].y > 0 ? parseInt(elements[this.state.last_selected_element].y - 1) : parseInt(elements[this.state.last_selected_element].y),
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                    break;
                default:
                    break;
            }
        }
    }

    componentDidMount = () => {
        //Get default pronetin tag data
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_tag_data', {
            params: {
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'tag data') {
                    this.setState({
                        tagDataDefault: JSON.parse(res.data.tagDataDefault),
                        tagData: res.data.tagData === null ? [] : JSON.parse(res.data.tagData),
                    });
                }
            }
        })

        if (this.props.owner) {
            axios.post(this.props.node_server_axios + 'get_events', {
                params: {
                    query: "all",
                },
            }).then(res => {
                if (res.data.eventList) {
                    this.setState({
                        subeventArray: res.data.eventList,
                        eventArray: Array.from(new Set(res.data.eventList.map(obj => obj.event))),
                    })
                }
            });
        }

        //Insted of timeout use waiting logic for draft load completion to index variables
        setTimeout(() => this.indexAttributes(), 2000)
        //Handle database connection every interval
        const id = setInterval(this.pushToDatabase, 15000);
        this.setState({ intervalId: id });
        //Get data from draft on mount
        // this.getDraft();
        //draft is currently transfered from parent component

        if (this.props.step_selection === '2') {
            document.addEventListener("mousedown", this.handleClickOutside, false);
        }
        this.props.fetchChildStageSelect('1')

        if (this.props.step_selection !== '6' && !this.state.eventModal) {
            document.addEventListener("keydown", this.handleHistoryKeyDown);
            document.addEventListener("keydown", this.handleArrowKeys);
        }

        document.addEventListener('click', this.handleCenterGrid);

        document.addEventListener("mousedown", this.handleClickOutsideSaveModal);

        document.addEventListener('mousemove', this.handleMouseMove);

        if (this.props.org_id) {
            axios.post(this.props.node_server_axios + 'get_organisation_data', {
                params: {
                    org_id: this.props.org_id,
                }
            }).then((res) => {
                if (res.data !== undefined && res.data.message === 'success') {
                    let save_slots = 3;
                    if (res.data.running_plan !== null && res.data.running_plan !== 'free') {
                        save_slots = 6;
                    } else {
                        save_slots = 3;
                    }
                    this.setState({
                        running_plan: res.data.running_plan,
                        savedTemplateLimit: save_slots,
                    });
                } else {
                    window.location = "/signin";
                }
            })
        } else {
            this.setState({
                savedTemplateLimit: 0,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("keydown", this.handleHistoryKeyDown);
        document.removeEventListener("keydown", this.handleArrowKeys);

        document.removeEventListener("mousedown", this.handleClickOutsideSaveModal);

        document.removeEventListener('click', this.handleCenterGrid);

        //for clearing database refresh interval
        clearInterval(this.state.intervalId);

        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleLiveColorDropper = (e, type) => {
        const { offsetX, offsetY } = e.nativeEvent;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = this.imageRef.current;
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        const pixelData = ctx.getImageData(offsetX, offsetY, 1, 1).data;
        // pixelData contains an array of RGBA values (red, green, blue, alpha)
        const [r, g, b] = pixelData;
        const hexR = r.toString(16).padStart(2, '0'); // Convert to 2-digit hex
        const hexG = g.toString(16).padStart(2, '0');
        const hexB = b.toString(16).padStart(2, '0');

        // Create the hexadecimal color code
        const hexColor = `#${hexR}${hexG}${hexB}`;
        if (type === 'click') {
            this.setState({
                dropperColor: hexColor,
                colorPickMode: false,
                colorPickimgData: null,
                showPickerColor: false,
            });
        } else {
            this.setState({
                liveDropperColor: hexColor,
            });
        }
    };

    handleMouseMoveForLiveColorPick = (e, type) => {
        if (this.mouseMoveTimeout) {
            clearTimeout(this.mouseMoveTimeout);
        }
        this.mouseMoveTimeout = setTimeout(() => {
            this.handleLiveColorDropper(e, type)
        }, 100);
    }

    colorPickMode = async () => {
        this.setState({
            cacheTs: Date.now(),
        });
        const div = document.getElementById('colorpick-div');
        const elems = document.querySelector('#colorpick-div .elements');
        const originalOverflow = elems.style.overflow;
        elems.style.overflow = 'hidden';
        const imgData = await domtoimage.toPng(div, { quality: 1 });
        elems.style.overflow = originalOverflow;
        this.setState({
            colorPickimgData: imgData,
            colorPickMode: true,
            showPickerColor: true,
        });
    }

    handleMouseMove = (e) => {
        if (this.state.colorPickMode) {
            this.setState({
                cursorX: e.clientX,
                cursorY: e.clientY,
            });
        }
    };

    handleColorPickerEnter = () => {
        this.setState({ showPickerColor: true });
    };

    handleColorPickerLeave = () => {
        this.setState({ showPickerColor: false });
    };


    handleClickOutside = (e) => {
        if (!this.outsideClickRef.current.contains(e.target) && !this.state.colorPickMode) {
            this.setState({
                cacheTs: Date.now(),
                textAlignDropdown: false,
                palette: false,
                lineHeightDropdown: false,
            })
        }
    }

    handleClickOutsideSaveModal = (e) => {
        if (this.saveModalRef.current && !this.saveModalRef.current.contains(e.target)) {
            this.setState({ saveModal: false });
        }
    }

    toggleSaveModal = () => {
        this.setState({ saveModal: !this.state.saveModal });
    }

    updateImage = (e) => {
        if (e.target.id === 'resizeLock') {
            this.setState(({ elements }) => ({
                elements: [
                    ...elements.slice(0, this.state.last_selected_element),
                    {
                        ...elements[this.state.last_selected_element],
                        resizeLock: !elements[this.state.last_selected_element].resizeLock,
                    },
                    ...elements.slice(this.state.last_selected_element + 1)
                ],
            }));
        } else if (e.target.id === 'increaseZ') {
            if (this.state.elements[this.state.last_selected_element].zIndex <= this.state.elementsCount) {
                this.setState(({ elements }) => ({
                    elements: [
                        ...elements.slice(0, this.state.last_selected_element),
                        {
                            ...elements[this.state.last_selected_element],
                            zIndex: elements[this.state.last_selected_element].zIndex < 999 ? elements[this.state.last_selected_element].zIndex + 1 : 999,
                        },
                        ...elements.slice(this.state.last_selected_element + 1)
                    ],
                }));
            }
        } else if (e.target.id === 'decreaseZ') {
            this.setState(({ elements }) => ({
                elements: [
                    ...elements.slice(0, this.state.last_selected_element),
                    {
                        ...elements[this.state.last_selected_element],
                        zIndex: elements[this.state.last_selected_element].zIndex > 1 ? elements[this.state.last_selected_element].zIndex - 1 : 0,
                    },
                    ...elements.slice(this.state.last_selected_element + 1)
                ],
            }));
        } else {
            if (this.state.elements[this.state.last_selected_element].resizeLock && (e.target.id === 'elWidth' || e.target.id === 'elHeight')) {
                let newWidth = false;
                let newHeight = false;
                if (e.target.id === 'elWidth') {
                    newWidth = parseInt(e.target.value);
                    const height = this.state.elements[this.state.last_selected_element].elHeight;
                    if (newWidth && height) {
                        newHeight = (newWidth * height) / this.state.elements[this.state.last_selected_element].elWidth;
                    }
                } else {
                    newHeight = parseInt(e.target.value);
                    const width = this.state.elements[this.state.last_selected_element].elWidth;
                    if (newHeight && width) {
                        newWidth = (newHeight * width) / this.state.elements[this.state.last_selected_element].elHeight;
                    }
                }
                if (newWidth && newHeight) {
                    this.setState(({ elements }) => ({
                        elements: [
                            ...elements.slice(0, this.state.last_selected_element),
                            {
                                ...elements[this.state.last_selected_element],
                                elWidth: parseFloat(newWidth).toFixed(1),
                                elHeight: parseFloat(newHeight).toFixed(1),
                            },
                            ...elements.slice(this.state.last_selected_element + 1)
                        ],
                    }));
                }
            } else {
                this.setState(({ elements }) => ({
                    elements: [
                        ...elements.slice(0, this.state.last_selected_element),
                        {
                            ...elements[this.state.last_selected_element],
                            [e.target.id]: parseInt(e.target.value),
                        },
                        ...elements.slice(this.state.last_selected_element + 1)
                    ],
                }));
            }
        }
    }

    updateSignature = (e) => {
        this.setState(({ elements }) => ({
            elements: [
                ...elements.slice(0, this.state.last_selected_element),
                {
                    ...elements[this.state.last_selected_element],
                    [e.target.id]: parseInt(e.target.value),
                },
                ...elements.slice(this.state.last_selected_element + 1)
            ],
        }));
    }

    changStep = (e) => {
        this.props.fetchChildStageSelect(e.currentTarget.dataset.step)
    }

    addRowRecepient = () => {
        let keys = this.state.variables;
        let emptyObjects = Array(1).fill().map(() => {
            return Object.assign({}, ...keys.map(key => ({ [key]: "" })));
        });
        this.props.addRowCsv(emptyObjects);
    }

    handleCsvDataChange(e, id) {
        const { csvData } = this.state;
        const index = id;
        csvData[index][e.target.name] = e.target.name === '[Email]' ? e.target.value.trim() : e.target.value;
        this.setState({
            csvData: csvData,
            csvWarnings: true,
        });
    }

    deleteRecepientRow(id) {
        this.props.removeRowCsv(id);
    }

    togglePreview = () => {
        this.setState(prevState => ({
            cacheTs: Date.now(),
            preview: !prevState.preview
        }));
    }

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    imageNotReady = () => {
        console.log("Preview ready!")
    }

    takeScreenshot = async () => {
        if (!this.state.batchCreation) {
            this.setState({
                cacheTs: Date.now(),
                batchCreation: true,
            });
            const node = document.getElementById('screenshot-div');
            const element = document.querySelector('#screenshot-div .elements');
            const qr = document.getElementById('qr-div');
            // Calculate total width and height of the element
            const elementWidth = node.offsetWidth;
            const elementHeight = node.offsetHeight;
            // Scroll the element into view
            node.scrollIntoView();
            // Adjust the size of the captured element
            const originalWidth = node.style.width;
            const originalHeight = node.style.height;
            node.style.width = elementWidth * 2 + 'px';
            node.style.height = elementHeight * 2 + 'px';
            element.style.transform = 'scale(2) translate(25%, 25%)';
            qr.style.transform = 'scale(2) translate(-25%, -25%)';
            const excludedElements = element.querySelectorAll('.exclude-scale');
            excludedElements.forEach((excludedElement) => {
                excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
            })
            const imgData = await domtoimage.toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } });
            this.setState({
                cacheTs: Date.now(),
                imgData: imgData,
                screenshot: false,
                hide_signature: true,
            }, async () => {
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                qr.style.transform = 'scale(1) translate(-0%, -0%)';
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                const previewData = await domtoimage.toPng(node, { quality: 1 });
                this.setState({
                    preview: true,
                    previewData: previewData,
                }, () => {
                    this.createServerBatch()
                });
            });
        }
    }

    createServerBatch = () => {
        var imageTimestamp = Date.now();

        let base64Image = this.state.imgData;
        let byteCharacters = atob(base64Image.split(",")[1]);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });

        let base64Image_preview = this.state.previewData;
        let byteCharacters_preview = atob(base64Image_preview.split(",")[1]);
        let byteNumbers_preview = new Array(byteCharacters_preview.length);
        for (let i = 0; i < byteCharacters_preview.length; i++) {
            byteNumbers_preview[i] = byteCharacters_preview.charCodeAt(i);
        }
        let byteArray_preview = new Uint8Array(byteNumbers_preview);
        const blob_preview = new Blob([byteArray_preview], { type: "image/png" });

        const formData = new FormData();

        this.props.pdf.pdfData && (formData.append('pdfFile', this.props.pdf.pdfData, imageTimestamp + '.pdf'));
        formData.append('cert_attachment', this.props.pdf.pdfData ? imageTimestamp + '.pdf' : null);
        formData.append('cert_bg', blob, imageTimestamp + '.png');
        formData.append('cert_bg_preview', blob_preview, imageTimestamp + '_preview.png');
        formData.append('cert_bg_name', imageTimestamp + '.png');
        formData.append('created_by', this.props.email);
        formData.append('cert_title', this.state.elements.find(obj => obj.placeholder === "Certificate Title").content);
        formData.append('signees', JSON.stringify(this.state.elements.filter(obj => obj.type === "signature").map(obj => obj.content)));
        formData.append('count', this.state.csvData.length);
        formData.append('csv_data', JSON.stringify(this.state.csvData));
        formData.append('cert_preview', this.props.certTemplate);
        formData.append('pronetin_verified', "true");
        formData.append('credits_used', this.state.creditsUsed);
        formData.append('event_name', this.state.eventName);
        formData.append('feedbacks', this.state.feedbacks);
        formData.append('feedbacks_mandatory', this.state.feedbacks_mandatory);
        formData.append('feedbacks_anonymous', this.state.feedbacks_anonymous);
        formData.append('sub_event_name', this.state.subeventName);
        formData.append('elements', JSON.stringify(this.state.elements.filter(obj => obj.type !== "image" || obj.content === "shape")));
        //need to continue from here
        axios.post(this.props.node_server_axios + 'create_batch', formData, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            }
        }).then((res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'batch created') {
                    //Commented so that the transition from creating batch to organisation page is smooth
                    // this.setState({
                    //     loading_screen: false,
                    // });

                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: this.state.creditsUsed + 10,
                            content: "Created batch for " + this.state.subeventName + " in event " + this.state.eventName,
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.props.leavePageWarn(false);
                    setTimeout(function () {
                        window.location.href = '/organisation';
                    }, 2000);
                }
            }
        })
    }

    isSimilar = (str) => {
        function levenshtein(a, b) {
            const m = a.length;
            const n = b.length;
            const dp = new Array(m + 1).fill(null).map(() => new Array(n + 1).fill(0));

            for (let i = 0; i <= m; i++) {
                for (let j = 0; j <= n; j++) {
                    if (i === 0) {
                        dp[i][j] = j;
                    } else if (j === 0) {
                        dp[i][j] = i;
                    } else if (a[i - 1] === b[j - 1]) {
                        dp[i][j] = dp[i - 1][j - 1];
                    } else {
                        dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
                    }
                }
            }

            return dp[m][n];
        }

        let levenshteinCheck = false;
        for (const string of emailDomains) {
            if (string === str) {
                return false;
            }
            const distance = levenshtein(str, string);
            if (distance <= 3) {
                levenshteinCheck = string
            }
        }

        if (levenshteinCheck) {
            return levenshteinCheck
        } else {
            return false;
        }
    }

    createCertificateBatch = () => {
        function findEmptyValues(data) {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            for (let i = 0; i < data.length; i++) {
                const object = data[i];
                for (let key in object) {
                    if (object[key] === "") {
                        return i;
                    }
                }
            }
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const email = item["[Email]"];
                if (!emailRegex.test(email)) {
                    return i;
                }
            }
            return false;
        }

        let warningArray = [];
        for (let i = 0; i < this.state.csvData.length; i++) {
            const item = this.state.csvData[i];
            const email = item["[Email]"];
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            let suggested = "Invalid Email";
            if (emailRegex.test(email)) {
                suggested = this.isSimilar(email.split('@')[1])
            }
            if (suggested) {
                warningArray.push({ index: i, suggested: suggested });
            }
        }

        if (this.state.csvWarnings && warningArray.length > 0) {
            this.setState({
                csvWarningModal: true,
                csvWarningArray: warningArray,
            });
        } else {
            let emptyPosition = findEmptyValues(this.state.csvData)

            if (emptyPosition !== false) {
                this.setState({
                    warning: {
                        message: "Invalid value at row " + (parseInt(emptyPosition) + 1),
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            } else {
                if (this.state.csvData && this.state.csvData.length) {
                    if (this.state.eventName !== '' && this.state.subeventName !== '') {
                        this.setState({
                            preview: false,
                            screenshot: true,
                            loading_screen: true,
                            certificate_zoom: 1,
                        }, () => {
                            //Changed to inline image loading check
                            //setTimeout is for the images to load
                            // setTimeout(() => this.takeScreenshot(), 3000)
                        });
                    } else {
                        this.setState({
                            eventModal: true,
                        });
                    }
                } else {
                    this.setState({
                        warning: {
                            message: "Add atleast one recepient before issuing certificate.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            }
        }
    }

    centerGrid = (x, y) => {
        if (this.props.step_selection !== '6') {
            if (x) {
                document.getElementById("centerX").style.display = "block";
            } else {
                document.getElementById("centerX").style.display = "none";
            }
            if (y) {
                document.getElementById("centerY").style.display = "block";
            } else {
                document.getElementById("centerY").style.display = "none";
            }
        }
    }

    handleTagContents = (e) => {
        if (e === this.state.tagContent) {
            this.setState({
                tagContent: false,
                tagModal: !this.state.tagModal,
            });
        } else {
            this.setState({
                tagContent: e,
                tagModal: true,
            });
        }
    }

    handleCloseTagContent = () => {
        this.setState({
            tagModal: false,
        });
    }

    handleTagContentDisplay = (e) => {
        this.setState({
            tagContent: false,
            tagType: e,
        });
    }

    handleTagText = (e) => {
        this.setState({ tagText: e.target.value });
    };

    handleTagEnterPress = (e) => {
        const { tagText, tags } = this.state;
        if (e.key === 'Enter' && tagText.trim().length > 0) {
            e.preventDefault();
            if (this.state.tags.includes(tagText.trim())) {
                this.setState({ tagWarning: 'Tag already exists' });
                setTimeout(() => {
                    this.setState({ tagWarning: false });
                }, 3000);
            } else if (this.state.tags.length > 5) {
                this.setState({ tagWarning: 'Tag limit reached' });
                setTimeout(() => {
                    this.setState({ tagWarning: false });
                }, 3000);
            } else if (tagText.trim().length > 20) {
                this.setState({ tagWarning: 'Tag length should be < 20' });
                setTimeout(() => {
                    this.setState({ tagWarning: false });
                }, 3000);
            }
            else {
                this.setState({
                    tagText: '',
                    tags: [...tags, tagText.trim()],
                });
            }
        }
    };

    deleteTag = (value) => {
        this.setState((prevState) => ({
            tags: prevState.tags.filter((tag) => tag !== value),
        }));
    }

    saveTag = () => {
        if (this.state.tags.length > 0) {
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'save_tag_data', {
                params: {
                    email: this.props.email,
                    elements: this.state.tagData.concat([{ tags: this.state.tags, content: this.state.tagContent, favorite: false }])
                },
            }).then(async (res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'saved') {
                        this.setState({
                            tagDataDefault: JSON.parse(res.data.tagDataDefault),
                            tagData: res.data.tagData === null ? [] : JSON.parse(res.data.tagData),
                            tagModal: false,
                        });
                    }
                }
            })
        } else {
            this.setState({ tagWarning: 'Atleast 1 tag is required' });
            setTimeout(() => {
                this.setState({ tagWarning: false });
            }, 3000);
        }
    }

    toggleTagToFavoritesMytags = (index) => {
        axios.defaults.withCredentials = true;
        let updatedData = this.state.tagData;
        updatedData[index].favorite = !updatedData[index].favorite;
        axios.post(this.props.node_server_axios + 'save_tag_data', {
            params: {
                email: this.props.email,
                elements: updatedData,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved') {
                    this.setState({
                        tagDataDefault: JSON.parse(res.data.tagDataDefault),
                        tagData: res.data.tagData === null ? [] : JSON.parse(res.data.tagData),
                    });
                }
            }
        })
    }

    addTagToFavoritesPronetin = (index) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'save_tag_data', {
            params: {
                email: this.props.email,
                elements: this.state.tagData.concat({ ...this.state.tagDataDefault[index], favorite: false }),
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved') {
                    this.setState({
                        tagDataDefault: JSON.parse(res.data.tagDataDefault),
                        tagData: res.data.tagData === null ? [] : JSON.parse(res.data.tagData),
                    });
                }
            }
        })
    }

    deleteTagFromMytags = (n) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'save_tag_data', {
            params: {
                email: this.props.email,
                elements: this.state.tagData.filter((item, index) => index !== n),
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved') {
                    this.setState({
                        tagDataDefault: JSON.parse(res.data.tagDataDefault),
                        tagData: res.data.tagData === null ? [] : JSON.parse(res.data.tagData),
                    });
                }
            }
        })
    }

    handleCopyToClipboard = (event, index) => {
        const text = event.target.closest(".content-description").querySelector("p").textContent;

        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({ copied: true, copiedIndex: index });
        setTimeout(() => {
            this.setState({ copied: false, copiedIndex: false });
        }, 2000);
    }

    tagContentRender = (value) => {
        let tagArray = this.state.tagDataDefault;
        let deletable = false;
        if (value === 'mytags') {
            tagArray = this.state.tagData;
            deletable = true
        } else if (value === 'favorites') {
            tagArray = this.state.tagData.filter((tag) => tag.favorite === true);
        }
        return (
            <div className='content'>
                {tagArray.map((item, index) => (
                    <div className='content-wrapper' key={index}>
                        {this.state.copied && index === this.state.copiedIndex && (
                            <div className='copied-message'>
                                Copied !
                            </div>
                        )}
                        <div className='tag-wrapper'>
                            {item.tags.map((tag, tagIndex) => (
                                <div className='tag' key={tagIndex}><div className='dot'></div><p>{tag}</p></div>
                            ))}
                        </div>
                        <div className='content-description'>
                            <button className='copy' onClick={((e) => { this.handleCopyToClipboard(e, index) })}><i className="fa-regular fa-copy"></i></button>
                            <p>{item.content}</p>
                            {value === 'pronetin' ?
                                this.state.tagData.some(tag => tag.content === item.content) ?
                                    <button className='add active'><i className="fa-regular fa-circle-check"></i></button>
                                    :
                                    <button className='add tooltip' onClick={() => { this.addTagToFavoritesPronetin(index) }}><i className="fa-solid fa-plus"></i><span className="tooltiptext">Add to My Tags</span></button>
                                :
                                value === 'favorites' ?
                                    <button className='favorite active' onClick={() => { this.toggleTagToFavoritesMytags(index) }}><i className="fa-solid fa-star"></i></button>
                                    :
                                    <button className={item.favorite ? 'favorite active' : 'favorite'} onClick={() => { this.toggleTagToFavoritesMytags(index) }}><i className="fa-solid fa-star"></i></button>
                            }
                            {deletable && (<button className='deletable' onClick={() => { this.deleteTagFromMytags(index) }}><i className="fa-regular fa-trash-can"></i></button>)}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    tagSearch = (e) => {
        const contentWrappers = document.querySelectorAll(".content-wrapper");
        const searchValue = e.currentTarget.value;

        contentWrappers.forEach((contentWrapper) => {
            const tags = contentWrapper.querySelectorAll(".tag p");
            let match = false;

            tags.forEach((tag) => {
                if (tag.textContent.toLowerCase().includes(searchValue.toLowerCase())) {
                    match = true;
                }
            });

            if (!match) {
                contentWrapper.style.display = "none";
            } else {
                contentWrapper.style.display = "flex";
            }
        });
    }

    bottomSecRender = (value) => {
        if (this.props.owner) {
            switch (value) {
                case '1':
                    return (<div className='container'><p>1/6</p><button className='next' data-step="2" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '2':
                    return (<div className='container'><button className='previous' data-step="1" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Choose Templates</p></button><p>2/6</p><button className='next' data-step="3" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '3':
                    return (<div className='container'><button className='previous' data-step="2" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Add Text</p></button><p>3/6</p><button className='next' data-step="4" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '4':
                    return (<div className='container'><button className='previous' data-step="3" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Add Image</p></button><p>4/6</p><button className='next' data-step="5" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '5':
                    return (<div className='container'><button className='previous' data-step="4" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Attach Document</p></button><p>5/6</p><button className='next' data-step="6" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '6':
                    if (this.state.eventName !== '' && this.state.subeventName !== '') {
                        return (<div className='container'><button className='previous' data-step="5" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Add Signature</p></button><p>6/6</p><button className='next' onClick={this.createCertificateBatch}>Create Batch<i className="fa-solid fa-caret-right"></i></button></div>)
                    } else {
                        return (<div className='container'><button className='previous' data-step="5" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Add Signature</p></button><p>6/6</p><button className='next' onClick={this.createCertificateBatch}>Choose Event<i className="fa-solid fa-caret-right"></i></button></div>)
                    }
                default:
                    return (<div className='container'><p>1/5</p><button className='next' data-step="2" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
            }
        } else {
            switch (value) {
                case '1':
                    return (<div className='container'><p>1/5</p><button className='next' data-step="2" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '2':
                    return (<div className='container'><button className='previous' data-step="1" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Choose Templates</p></button><p>2/5</p><button className='next' data-step="3" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '3':
                    return (<div className='container'><button className='previous' data-step="2" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Add Text</p></button><p>3/5</p><button className='next' data-step="4" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '4':
                    return (<div className='container'><button className='previous' data-step="3" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Add Image</p></button><p>4/5</p><button className='next' data-step="5" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
                case '5':
                    return (<div className='container'><button className='previous' data-step="4" onClick={this.changStep}><i className="fa-solid fa-caret-left"></i><p>Attach Document</p></button><p>5/5</p></div>)
                default:
                    return (<div className='container'><p>1/5</p><button className='next' data-step="2" onClick={this.changStep}><p>Go to next step</p><i className="fa-solid fa-caret-right"></i></button></div>)
            }
        }
    }

    topSecRender = (value) => {
        switch (value) {
            case "1":
                return (
                    <div className="top-sec" ref={this.outsideClickRef}>
                        <div className='container'>
                            <div>Orientation</div>
                            {/* <button className='image-edit'><i className="fa-solid fa-caret-up"></i></button>
                            <button className='image-edit'><i className="fa-solid fa-caret-down"></i></button> */}
                            {this.state.certWidth > this.state.certHeight ? <input type="text" value="Landscape" readOnly /> : <input type="text" value="Portrait" readOnly />}
                        </div>
                    </div>
                )
            case "2":
                return (
                    <div className="top-sec" ref={this.outsideClickRef}>
                        <div className='container'>
                            <select value={this.state.elements[this.state.last_selected_element].fontFamily} onChange={this.updateFont} data-type='value' id='fontFamily'>
                                {/* <option value="Arial ,sans-serif">Arial ,sans-serif</option>
                                <option value="Verdana ,sans-serif">Verdana ,sans-serif</option>
                                <option value="Helvetica ,sans-serif">Helvetica ,sans-serif</option>
                                <option value="Tahoma ,sans-serif">Tahoma ,sans-serif</option>
                                <option value="Trebuchet MS ,sans-serif">Trebuchet MS ,sans-serif</option>
                                <option value="Times New Roman ,serif">Times New Roman ,serif</option>
                                <option value="Georgia ,serif">Georgia ,serif</option>
                                <option value="Garamond ,serif">Garamond ,serif</option>
                                <option value="Courier New ,monospac">Courier New ,monospace</option>
                                <option value="Brush Script MT ,cursive">Brush Script MT ,cursive</option> */}
                                <option value="Arimo, sans-serif" style={{ fontFamily: "Arimo, sans-serif" }}>Ariel</option>
                                <option value="Tinos, serif" style={{ fontFamily: "Tinos, serif" }}>Times New Romen</option>
                                <option value="Bungee Spice, cursive" style={{ fontFamily: "Bungee Spice, cursive" }}>Bungee Spice</option>
                                <option value="IBM Plex Sans, sans-serif" style={{ fontFamily: "IBM Plex Sans, sans-serif" }} >IBM Plex Sans</option>
                                <option value="Josefin Sans, sans-serif" style={{ fontFamily: "Josefin Sans, sans-serif" }} >Josefin Sans</option>
                                <option value="Lexend, sans-serif" style={{ fontFamily: "Lexend, sans-serif" }} >Lexend</option>
                                <option value="Libre Franklin, sans-serif" style={{ fontFamily: "Libre Franklin, sans-serif" }} >Libre Franklin</option>
                                <option value="Lora, serif" style={{ fontFamily: "Lora, serif" }} >Lora</option>
                                <option value="Mali, cursive" style={{ fontFamily: "Mali, cursive" }} >Mali</option>
                                <option value="Montserrat, sans-serif" style={{ fontFamily: "Montserrat, sans-serif" }} >Montserrat</option>
                                <option value="MuseoModerno, cursive" style={{ fontFamily: "MuseoModerno, cursive" }} >MuseoModerno</option>
                                <option value="Noto Serif, serif" style={{ fontFamily: "Noto Serif, serif" }} >Noto Serif</option>
                                <option value="Nunito Sans, sans-serif" style={{ fontFamily: "Nunito Sans, sans-serif" }} >Nunito Sans</option>
                                <option value="Open Sans, sans-serif" style={{ fontFamily: "Open Sans, sans-serif" }} >Open Sans</option>
                                <option value="Playfair Display, serif" style={{ fontFamily: "Playfair Display, serif" }} >Playfair Display</option>
                                <option value="Poppins, sans-serif" style={{ fontFamily: "Poppins, sans-serif" }} >Poppins</option>
                                <option value="Raleway, sans-serif" style={{ fontFamily: "Raleway, sans-serif" }} >Raleway</option>
                                <option value="Roboto Mono, monospace" style={{ fontFamily: "Roboto Mono, monospace" }} >Roboto Mono</option>
                                <option value="Spectral, serif" style={{ fontFamily: "Spectral, serif" }} >Spectral</option>
                                <option value="Spectral SC, serif" style={{ fontFamily: "Spectral SC, serif" }} >Spectral SC</option>
                                <option value="Taviraj, serif" style={{ fontFamily: "Taviraj, serif" }} >Taviraj</option>
                                <option value="Tourney, cursive" style={{ fontFamily: "Tourney, cursive" }} >Tourney</option>
                            </select>
                            <input type="number" min="12" max="72" onChange={this.updateFont} value={this.state.elements[this.state.last_selected_element].fontSize} data-type='value' id='fontSize' />
                            <button className='relative font-edit' style={{ border: `4px solid ${this.state.elements[this.state.last_selected_element].fontColor}` }} onClick={this.togglePalette}><i className="fa-solid fa-font colorBox"></i>
                                <div className='color-picker-modal' style={this.state.palette ? { display: "block" } : { display: "none" }}>
                                    <div className='close-btn' onClick={this.togglePalette}><i className="fa-solid fa-circle-xmark"></i></div>
                                    <div className='color-picker-component'>
                                        <div className='dropper' onClick={this.colorPickMode}><i className="fa-solid fa-eye-dropper"></i></div>
                                        <ColorPickerComponent setStateOfParent={this.setStateOfParent} colors={this.props.colors} dropperColor={this.state.dropperColor} currentColor={this.state.elements[this.state.last_selected_element].fontColor} />
                                    </div>
                                </div>
                            </button>
                            <button className={this.state.elements[this.state.last_selected_element].fontWeight ? 'font-edit active' : 'font-edit'} onClick={this.updateFont} data-type='bool' id='fontWeight'><i className="fa-solid fa-bold"></i></button>
                            <button className={this.state.elements[this.state.last_selected_element].fontStyle ? 'font-edit active' : 'font-edit'} onClick={this.updateFont} data-type='bool' id='fontStyle'><i className="fa-solid fa-italic"></i></button>
                            <button className={this.state.elements[this.state.last_selected_element].textDecorationLine ? 'font-edit active' : 'font-edit'} onClick={this.updateFont} data-type='bool' id='textDecorationLine'><i className="fa-solid fa-underline"></i></button>
                            <button className={this.state.elements[this.state.last_selected_element].textAlign ? 'font-edit active' : 'font-edit'} onClick={this.showtextAlignDropdown}>
                                {
                                    this.textAlignRender(this.state.elements[this.state.last_selected_element].textAlign)
                                }
                                <ul className='dropdown' style={{ display: this.state.textAlignDropdown ? 'block' : 'none' }}>
                                    <li data-type='value' id='textAlign' onClick={this.updateFont} data-value="left"><i className="fa-solid fa-align-left"></i>Left</li>
                                    <li data-type='value' id='textAlign' onClick={this.updateFont} data-value="right"><i className="fa-solid fa-align-right"></i>Right</li>
                                    <li data-type='value' id='textAlign' onClick={this.updateFont} data-value="center"><i className="fa-solid fa-align-center"></i>Center</li>
                                    <li data-type='value' id='textAlign' onClick={this.updateFont} data-value="justify"><i className="fa-solid fa-align-justify"></i>Justify</li>
                                </ul>
                            </button>
                            {/* {
                                this.state.elements[this.state.last_selected_element].textAlign === 'justify' && (<button className='font-edit' id='wordBreak' onClick={this.updateFont} data-value="break-all"><i className="fa-solid fa-paragraph"></i></button>)
                            } */}
                            <button className={this.state.elements[this.state.last_selected_element].lineHeight ? 'font-edit active' : 'font-edit'} onClick={this.showlineHeightDropdown}><i className="fa-solid fa-text-height"></i>
                                <ul className='dropdown' style={{ display: this.state.lineHeightDropdown ? 'block' : 'none' }}>
                                    <li data-type='value' id='lineHeight' onClick={this.updateFont} data-value="1">
                                        {this.state.elements[this.state.last_selected_element].lineHeight === '1' ? <i className="fa-solid fa-check"></i> : <i className="fa-regular fa-circle"></i>}Single</li>
                                    <li data-type='value' id='lineHeight' onClick={this.updateFont} data-value="1.15">
                                        {this.state.elements[this.state.last_selected_element].lineHeight === '1.15' ? <i className="fa-solid fa-check"></i> : <i className="fa-regular fa-circle"></i>}1.15</li>
                                    <li data-type='value' id='lineHeight' onClick={this.updateFont} data-value="1.5">
                                        {this.state.elements[this.state.last_selected_element].lineHeight === '1.5' ? <i className="fa-solid fa-check"></i> : <i className="fa-regular fa-circle"></i>}1.5</li>
                                    <li data-type='value' id='lineHeight' onClick={this.updateFont} data-value="2">
                                        {this.state.elements[this.state.last_selected_element].lineHeight === '2' ? <i className="fa-solid fa-check"></i> : <i className="fa-regular fa-circle"></i>}Double</li>
                                </ul>
                            </button>
                        </div>
                        {/* <form method='POST' className='search'>
                            <input type='text' name='search' placeholder='Search' />
                            <button type='submit'><i className="fa-solid fa-magnifying-glass"></i></button>
                        </form> */}
                        <button className='saved-content-btn' onClick={() => { this.handleTagContents(false) }}><i className="fa-regular fa-rectangle-list"></i><p>Saved Contents</p></button>
                    </div>
                )
            case "3":
                let last_selected_element = this.state.elements[this.state.last_selected_element];
                return (
                    <div className="top-sec" ref={this.outsideClickRef}>
                        <div className='container'>
                            <div className='res-del'>Size</div>
                            <div>
                                <label>W</label>
                                <input type="number" className='image-number' id="elWidth" value={last_selected_element.type === "image" ? parseInt(isNaN(last_selected_element.elWidth) ? 0 : last_selected_element.elWidth) : 0} onChange={this.updateImage}></input>
                            </div>
                            <button className='font-edit active' id="resizeLock" onClick={this.updateImage}>
                                {
                                    last_selected_element.resizeLock ? <i className="fa-solid fa-lock"></i> : <i className="fa-solid fa-lock-open"></i>
                                }
                            </button>
                            <div>
                                <input type="number" className='image-number' id="elHeight" value={last_selected_element.type === "image" ? parseInt(last_selected_element.elHeight) : 0} onChange={this.updateImage}></input>
                                <label>H</label>
                            </div>
                            <div className='w-10px'></div>
                            <div className='res-del'>Position</div>
                            <div>
                                <label>X</label>
                                <input type="number" className='image-number' id="x" value={last_selected_element.type === "image" ? parseInt(last_selected_element.x) : 0} onChange={this.updateImage}></input>
                            </div>
                            <div>
                                <input type="number" className='image-number' id="y" value={last_selected_element.type === "image" ? parseInt(last_selected_element.y) : 0} onChange={this.updateImage}></input>
                                <label>Y</label>
                            </div>
                            <div className='w-10px'></div>
                            <div className='res-del'>Layer Position</div>
                            <button className='image-edit' id='increaseZ' onClick={this.updateImage}><i className="fa-solid fa-caret-up"></i></button>
                            <input type="number" className='image-number' id="z-index" value={parseInt(last_selected_element.zIndex)} readOnly></input>
                            <button className='image-edit' id='decreaseZ' onClick={this.updateImage}><i className="fa-solid fa-caret-down"></i></button>
                        </div>
                    </div>
                )
            case "4":
                return (
                    <div className="top-sec" ref={this.outsideClickRef}>
                        <div className='container'>
                            <label>File Name:</label>
                            <div>{this.props.pdf.pdf !== undefined && this.props.pdf.pdf !== false ? this.props.pdf.pdfFilename : "Nil"}</div>
                        </div>
                        <div className='container'>
                            <div>
                                <label>Size</label>
                                <input type="text" value={this.props.pdf.pdf !== undefined && this.props.pdf.pdf !== false ? this.props.pdf.pdfSize : "0 mb"} readOnly={true}></input>
                            </div>
                        </div>
                    </div>
                )
            case "5":
                return (
                    <div className="top-sec" ref={this.outsideClickRef}>
                        <div className='container'>
                            <div>Position</div>
                            <div>
                                <label>X</label>
                                <input type="number" className='image-number' id="x" value={this.state.elements[this.state.last_selected_element].type === "signature" ? parseInt(this.state.elements[this.state.last_selected_element].x) : 0} onChange={this.updateSignature}></input>
                            </div>
                            <div>
                                <input type="number" className='image-number' id="y" value={this.state.elements[this.state.last_selected_element].type === "signature" ? parseInt(this.state.elements[this.state.last_selected_element].y) : 0} onChange={this.updateSignature}></input>
                                <label>Y</label>
                            </div>
                            <div className='w-10px'></div>
                            <div>Layer Position</div>
                            <button className='image-edit' id='increaseZ' onClick={this.updateSignature}><i className="fa-solid fa-caret-up"></i></button>
                            <button className='image-edit' id='decreaseZ' onClick={this.updateSignature}><i className="fa-solid fa-caret-down"></i></button>
                        </div>
                    </div>
                )
            case "6":
                return (
                    <div className="top-sec recepient-header" ref={this.outsideClickRef}>
                        <div className='container'>
                            <div>Recepient Count</div>
                            <input type="number" className='image-number' id="recepient_count" value={this.state.csvData.length} readOnly></input>
                            <div className='flex-grow'></div>
                            <div className='event-details' onClick={this.toggleEditor}>
                                <label>Event</label>
                                <p>{this.state.eventName !== '' ? this.state.eventName : "Not Selected"}</p>
                                <p>{this.state.subeventName !== '' && (this.state.subeventName)}</p>
                            </div>
                            {this.state.preview ?
                                <button className='preview-btn' onClick={this.togglePreview}><i className="fa-regular fa-image"></i> Preview</button>
                                :
                                <button className='preview-btn' onClick={this.togglePreview}><i className="fa-solid fa-list"></i> Recepients</button>
                            }
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    handleEventChange = (e) => {
        if (!/[^A-Za-z0-9@:._,~\s]/.test(e)) {
            e = e.replace(/ +/g, " ").trimLeft();
            if (e === '' || this.state.eventArray.includes(e)) {
                if (this.state.enableSubevent) {
                    this.setState({ eventName: e, newEvent: false, subeventName: '' });
                } else {
                    this.setState({ eventName: e, newEvent: false, subeventName: e });
                }
            } else {
                if (this.state.enableSubevent) {
                    this.setState({ eventName: e, newEvent: true, subeventName: '' });
                } else {
                    this.setState({ eventName: e, newEvent: true, subeventName: e });
                }
            }
        }
    }

    handleSubeventChange = (e) => {
        if (!/[^A-Za-z0-9@:._,~\s]/.test(e)) {
            e = e.replace(/ +/g, " ").trimLeft();
            if (e === '' || this.state.subeventArray.filter(obj => { return obj.event === this.state.eventName }).find(item => item.subevent === e)) {
                this.setState({ subeventName: e, newSubevent: false });
            } else {
                this.setState({ subeventName: e, newSubevent: true });
            }
        }
    }

    toggleEditor = () => {
        if (this.state.eventModal && (this.state.eventName === '' || this.state.subeventName === '')) {
            this.setState({
                warning: {
                    message: "Event and Subevent names are required to create batch.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        }
        this.setState({
            eventModal: !this.state.eventModal,
            subeventName: this.state.subeventName.trim(),
            eventName: this.state.eventName.trim(),
        });
    }

    handleSmartAds = (e) => {
        this.setState({ smartAds: e.target.checked });
    }

    handleEnableSubevent = (e) => {
        if (!this.state.enableSubevent) {
            this.setState({
                enableSubevent: e.target.checked,
                subeventName: '',
            });
        } else {
            this.setState({
                enableSubevent: e.target.checked,
                subeventName: this.state.eventName,
            });
        }
    }

    handleFeedbacks = (e) => {
        this.setState({
            feedbacks: e.target.checked,
            feedbacks_mandatory: !e.target.checked ? false : this.state.feedbacks_mandatory,
            feedbacks_anonymous: !e.target.checked ? false : this.state.feedbacks_anonymous,
        });
    }

    handleMandatoryFeedbacks = (e) => {
        this.setState({ feedbacks_mandatory: e.target.checked, feedbacks: e.target.checked ? true : this.state.feedbacks });
    }

    handleAnonymousFeedbacks = (e) => {
        this.setState({ feedbacks_anonymous: e.target.checked, feedbacks: e.target.checked ? true : this.state.feedbacks });
    }

    openDropDown = (e) => {
        if (e === 'event') {
            this.setState({ eventName: '', newEvent: !this.state.newEvent });
        } else {
            this.setState({ subeventName: '', newSubevent: !this.state.newSubevent });
        }
    }

    incrementCanvasZoom = () => {
        if (this.state.certificate_zoom < 2.8) {
            this.setState({ certificate_zoom: (parseFloat(this.state.certificate_zoom) + 0.1).toString() });
        }
    }

    decrementCanvasZoom = () => {
        if (this.state.certificate_zoom > 0.8) {
            this.setState({ certificate_zoom: (parseFloat(this.state.certificate_zoom) - 0.1).toString() });
        }
    }

    handleCenterGrid = (event) => {
        if (event.target.alt === 'certificate template') {
            this.centerGrid(false, false)
        }
    }

    toggleHelpModal = () => {
        this.setState({ helpModal: !this.state.helpModal });
    }

    compareAndHighlightStrings(str1, str2) {
        if (str2 !== undefined) {
            let result = '';
            const maxLength = Math.max(str1.length, str2.length);

            for (let i = 0; i < maxLength; i++) {
                if (str1[i] !== str2[i]) {
                    result += str1[i] !== undefined ? `<span style="color: green; text-decoration: underline;">${str1[i]}</span>` : '';
                } else {
                    result += str1[i] !== undefined ? str1[i] : '';
                }
            }
            return result;
        } else {
            return null;
        }
    }

    autoCorrectCsvValue = (i, id, suggestedCorrection) => {
        const { csvData } = this.state;
        const index = id;
        csvData[index]['[Email]'] = suggestedCorrection;
        this.setState(prevState => ({
            csvData: csvData,
            csvWarningArray: prevState.csvWarningArray.filter((_, index) => index !== i),
            csvWarningModal: prevState.csvWarningArray.length === 1 ? false : true,
            csvWarnings: prevState.csvWarningArray.length === 1 ? false : true,
        }));
    }

    rejectAutoCorrectCsvValue = (i) => {
        this.setState(prevState => ({
            csvWarningArray: prevState.csvWarningArray.filter((_, index) => index !== i),
            csvWarningModal: prevState.csvWarningArray.length === 1 ? false : true,
            csvWarnings: prevState.csvWarningArray.length === 1 ? false : true,
        }));
    }

    rejectAutoCorrectAll = () => {
        this.setState({
            csvWarningArray: [],
            csvWarningModal: false,
            csvWarnings: false,
        });
    }

    toggleCsvWarningModal = () => {
        this.setState({ csvWarningModal: !this.state.csvWarningModal });
    }

    updateGridLevels = (e) => {
        this.setState({ gridLevel: e.target.value });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.csvWarningModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleCsvWarningModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>We have detected a few anomalies</h1>
                            </div>
                            <div className='email-list-container'>
                                {this.state.csvWarningArray.map((element, index) => {
                                    let csvValueFromArray = this.state.csvData[element.index]['[Email]'].split('@');
                                    const highlightedString = this.compareAndHighlightStrings(element.suggested, csvValueFromArray[1]);
                                    let suggestedCorrection = csvValueFromArray[0] + "@" + element.suggested;
                                    return (
                                        <div className='email-list' key={index}>
                                            <div className='row'>{element.index}</div>
                                            {element.suggested !== 'Invalid Email' ?
                                                <div className='email-wrap'>
                                                    <div className='email-original'>{this.state.csvData[element.index]['[Email]']}</div>
                                                    <div className='email'>{csvValueFromArray[0]}@<p dangerouslySetInnerHTML={{ __html: highlightedString }}></p></div>
                                                </div>
                                                :
                                                <div className='email-wrap invalid'>
                                                    <div className='email-original'>{this.state.csvData[element.index]['[Email]']}</div>
                                                    <div className='email'>Invalid Email</div>
                                                </div>
                                            }
                                            {element.suggested !== 'Invalid Email' && (
                                                <div className='btn-container'>
                                                    <button onClick={() => this.rejectAutoCorrectCsvValue(index)}><i className="fa-regular fa-circle-xmark"></i></button>
                                                    <button onClick={() => this.autoCorrectCsvValue(index, element.index, suggestedCorrection)}><i className="fa-regular fa-circle-check"></i></button>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                            <p style={{ color: '#818181' }}>If these are intended, then you can reject the suggestions</p>
                            <button className='email-list-reject-all' onClick={this.rejectAutoCorrectAll}>Reject All</button>
                        </div>
                    </div>
                )}
                {this.state.eventModal ?
                    <div className='event-modal'>
                        <h2>Few finishing touches left...</h2>
                        <label>Event Name</label>
                        <div className='input-wrapper'>
                            <input name='eventName' placeholder='Pronetin 2023' type='text' onChange={(e) => { this.handleEventChange(e.target.value) }} value={this.state.eventName} required autoComplete='off' />
                            {this.state.newEvent && (
                                <ul>
                                    {this.state.eventArray.filter(item => item.toLowerCase().includes(this.state.eventName.toLowerCase())).map((element, index) => {
                                        return (<li key={index} onClick={() => { this.handleEventChange(element) }}>{element}</li>)
                                    })}
                                </ul>)}
                            {this.state.newEvent ? <div className='new'>New</div> : <div className='all' onClick={() => { this.openDropDown('event') }}>Choose&nbsp;Event&nbsp;<i className="fa-solid fa-chevron-down"></i></div>}
                        </div>

                        <p className='warn-red-color'>special characters allowed in event and subevent names are <b>@ : . _ , ~</b></p>

                        <label>Sub Event</label>
                        <div className='input-wrapper'>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.enableSubevent} onChange={(e) => { this.handleEnableSubevent(e) }} />
                                <span className="slider"></span>
                            </label>
                            <p>I have sub events</p>
                        </div>
                        {this.state.enableSubevent ?
                            <div className='input-wrapper'>
                                <input name='subeventName' placeholder='Introductory Workshop' type='text' onChange={(e) => { this.handleSubeventChange(e.target.value) }} value={this.state.subeventName} required autoComplete='off' />
                                {this.state.newSubevent && (
                                    <ul>
                                        {this.state.subeventArray.filter(obj => { return obj.event === this.state.eventName && obj.subevent.toLowerCase().includes(this.state.subeventName.toLowerCase()) }).map((element, index) => {
                                            return (<li key={index} onClick={() => { this.handleSubeventChange(element.subevent) }}>{element.subevent}</li>)
                                        })}
                                    </ul>)}
                                {this.state.newSubevent ? <div className='new'>New</div> : <div className='all' onClick={() => { this.openDropDown('subevent') }}>Choose&nbsp;Subevent&nbsp;<i className="fa-solid fa-chevron-down"></i></div>}
                            </div>
                            :
                            <input name='subeventName' type='text' defaultValue={this.state.eventName} required hidden />
                        }

                        <label>Advertisements</label>

                        <div className='input-wrapper'>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.smartAds} onChange={(e) => { this.handleSmartAds(e) }} />
                                <span className="slider"></span>
                            </label>
                            <p>Smart Ads</p>
                        </div>

                        <label>Feedback Collection</label>

                        <div className='input-wrapper'>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.feedbacks} onChange={(e) => { this.handleFeedbacks(e) }} />
                                <span className="slider"></span>
                            </label>
                            <p>Feedbacks</p>
                        </div>

                        <div className='input-wrapper sub'>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.feedbacks_mandatory} onChange={(e) => { this.handleMandatoryFeedbacks(e) }} />
                                <span className="slider"></span>
                            </label>
                            <p>Mandatory</p>
                        </div>

                        <div className='input-wrapper sub'>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.feedbacks_anonymous} onChange={(e) => { this.handleAnonymousFeedbacks(e) }} />
                                <span className="slider"></span>
                            </label>
                            <p>Anonymous</p>
                        </div>

                        <div className='flex'>
                            <button onClick={this.toggleEditor}><i className="fa-solid fa-arrow-left"></i> Back</button>
                            <button onClick={this.toggleEditor}>Continue</button>
                        </div>
                    </div>
                    :
                    <div className="editor-element">
                        {this.topSecRender(this.props.step_selection)}
                        {this.props.step_selection === '6' ?
                            this.state.preview ?
                                this.state.csvData && this.state.csvData.length ?
                                    <div className='recepient-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sl.</th>
                                                    {Object.keys(this.state.csvData[0]).map((key, index) => (
                                                        <th key={index}>{key}</th>
                                                    ))}
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.csvData.map((row, id) => (
                                                    <tr key={this.props.updateCsv + '-' + id + '-' + this.state.csvData.length}>
                                                        <td>{id + 1}</td>
                                                        {Object.values(row).map((value, index) => (
                                                            <td key={index}><input type="text" placeholder='Type here...' value={value} name={this.state.variables[index]} onChange={e => this.handleCsvDataChange(e, id)} /></td>
                                                        ))}
                                                        <td><button className='del-btn' onClick={() => { this.deleteRecepientRow(id) }}><i className="fa-solid fa-x"></i></button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button onClick={this.addRowRecepient}><i className="fa-solid fa-plus"></i> Add Row</button>
                                    </div>
                                    :
                                    <div className='recepient-table'>
                                        <table>
                                            <thead>
                                                <tr key={Math.floor(Math.random() * (999 - 100 + 1) + 100)}>
                                                    {this.state.variables.map((key, index) => (
                                                        <th key={index}>{key}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                        <button onClick={this.addRowRecepient}><i className="fa-solid fa-plus"></i> Add Row</button>
                                    </div>
                                :
                                <div className='cert-container'>
                                    <div className='certificate'>
                                        {/* if needed create loading for screenshot effect here */}
                                        <div className='wrapper' id="screenshot-div" style={{ transform: "scale(" + this.state.certificate_zoom + ")", width: this.state.certWidth, height: this.state.certHeight }}>
                                            <div className='elements overflow-hidden'>
                                                <img src={this.props.certTemplate.includes("_") ? `https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/` + this.props.certTemplate + `.png?cacheblock=` + this.state.cacheTs : `https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/` + this.props.certTemplate + `.png?cacheblock=` + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template' onLoad={this.state.screenshot ? this.takeScreenshot : this.imageNotReady} className='exclude-scale'></img>
                                                {
                                                    this.state.elements.map((element, index) => {
                                                        if (element.type === "image") {
                                                            return <ViewImage element_id={"view-"
                                                                + index} key={"view-"
                                                                    + element.timeStamp + "-" + index} data={element} />
                                                        } else if (element.type === "table" && !this.state.screenshot) {
                                                            return <ViewTable element_id={"view-"
                                                                + index} key={"view-"
                                                                    + element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                        } else if (element.type === "signature" && !this.state.screenshot) {
                                                            return <ViewSignee element_id={"view-"
                                                                + index} key={"view-"
                                                                    + element.timeStamp + "-" + index} data={element} hide_signature={this.state.hide_signature} />
                                                        } else if (!this.state.screenshot) {
                                                            return <ViewElement element_id={"view-"
                                                                + index} key={"view-"
                                                                    + element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                }
                                                {
                                                    <ViewQR data={'edit'} size={this.state.QRSize} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            <div className='cert-container'>
                                <GuideLines />
                                {this.state.enableGrid && (
                                    <div className='grid-level'>
                                        <p>Adjust grid level</p>
                                        <input type='range' step='1' min='1' max='6' value={this.state.gridLevel} onChange={this.updateGridLevels} />
                                    </div>
                                )}
                                {this.state.tagModal && (
                                    <Draggable cancel=".no-drag">
                                        <div className='tags-modal'>
                                            <div className='search-container'>
                                                <input type="text" placeholder='Search Tag' className='no-drag' onKeyUp={(e) => { this.tagSearch(e) }} />
                                                <button className='no-drag' onClick={this.handleCloseTagContent}><i className="fa-solid fa-xmark"></i></button>
                                            </div>
                                            <div className='type-container'>
                                                <button className={this.state.tagType === 'pronetin' ? 'no-drag active' : 'no-drag'} onClick={() => { this.handleTagContentDisplay('pronetin') }}>Pronetin</button>
                                                <button className={this.state.tagType === 'mytags' ? 'no-drag active' : 'no-drag'} onClick={() => { this.handleTagContentDisplay('mytags') }}>My Tags</button>
                                                <button className={this.state.tagType === 'favorites' ? 'no-drag active' : 'no-drag'} onClick={() => { this.handleTagContentDisplay('favorites') }}>Favorites</button>
                                            </div>
                                            {!this.state.tagContent ?
                                                this.tagContentRender(this.state.tagType)
                                                :
                                                <div className='content'>
                                                    <div className='save-wrapper'>
                                                        <p>Tags <b>(Press 'Enter' to add tag)</b></p>
                                                        <div className='tag-input-wrapper'>
                                                            <input
                                                                className='no-drag'
                                                                value={this.state.tagText}
                                                                onChange={this.handleTagText}
                                                                onKeyPress={this.handleTagEnterPress}>
                                                            </input>
                                                            {this.state.tagWarning && (
                                                                <div className="popup-message">
                                                                    {this.state.tagWarning}
                                                                    <i className="fa-solid fa-circle-exclamation"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='tag-wrapper'>
                                                            {this.state.tags.map((tag) => (
                                                                <div className='tag' key={tag}><div className='dot'></div>{tag}<div className='delete-btn' onClick={() => { this.deleteTag(tag) }}><i className="fa-regular fa-circle-xmark"></i></div></div>
                                                            ))}
                                                        </div>
                                                        <p>Content</p>
                                                        <textarea className='no-drag'>{this.state.tagContent}</textarea>
                                                        <button className='save-btn no-drag' onClick={this.saveTag}><i className="fa-regular fa-floppy-disk"></i> Save</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Draggable>
                                )}
                                <Draggable onStop={this.handleCertificateDrag} position={this.state.initial} disabled={this.state.move_canvas}>
                                    <div className='certificate'>
                                        {this.state.colorPickMode ?
                                            <div className='wrapper' style={{ transform: "scale(" + this.state.certificate_zoom + ")", width: this.state.certWidth, height: this.state.certHeight }}>
                                                <div className='elements cursor-crosshair' onMouseEnter={this.handleColorPickerEnter} onMouseLeave={this.handleColorPickerLeave}>
                                                    <img src={this.state.colorPickimgData} alt="for-color-pick" ref={this.imageRef} onClick={(e) => this.handleLiveColorDropper(e, 'click')} onMouseMove={(e) => this.handleMouseMoveForLiveColorPick(e, 'drag')} />
                                                </div>
                                            </div>
                                            :
                                            <div className='wrapper' id="colorpick-div" style={{ transform: "scale(" + this.state.certificate_zoom + ")", width: this.state.certWidth, height: this.state.certHeight }}>
                                                {this.state.enableGrid && (<div className={'grid p' + this.state.gridLevel}></div>)}
                                                <div className='elements'>
                                                    <img src={this.props.certTemplate.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + this.props.certTemplate + '.png?cacheblock=' + this.state.cacheTs : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + this.props.certTemplate + '.png?cacheblock=' + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template'></img>
                                                    <hr id='centerX'></hr>
                                                    <hr id='centerY'></hr>
                                                    {
                                                        this.state.elements.map((element, index) => {
                                                            if (element.type === "image") {
                                                                return <Image element_id={index} key={element.timeStamp + "-" + index} fetchChildState={this.handleSendToParent} fetchChildDrag={this.handleDragStop} fetchChildSize={this.handleResize} data={element} enableGrid={this.state.enableGrid} fetchHistory={this.handleHistory} fetchCenterGrid={this.centerGrid} last_selected_element={this.state.last_selected_element} certificate_zoom={parseFloat(this.state.certificate_zoom)} certWidth={this.state.certWidth} certHeight={this.state.certHeight} move_canvas={this.state.move_canvas} grid_level={this.state.gridLevel} />
                                                            } else if (element.type === "table") {
                                                                return <Table element_id={index} key={element.timeStamp + "-" + index} fetchTableStage={this.setTableStage} fetchChildState={this.handleSendToParent} fetchChildDrag={this.handleDragStop} data={element} enableGrid={this.state.enableGrid} fetchHistory={this.handleHistory} fetchChildSize={this.handleResizeTable} certificate_zoom={parseFloat(this.state.certificate_zoom)} move_canvas={this.state.move_canvas} grid_level={this.state.gridLevel} />
                                                            } else if (element.type === "signature") {
                                                                return <Signee element_id={index} key={element.timeStamp + "-" + index} fetchChildState={this.handleSendToParent} fetchChildDrag={this.handleDragStop} fetchChildSize={this.handleResize} data={element} enableGrid={this.state.enableGrid} fetchHistory={this.handleHistory} fetchCenterGrid={this.centerGrid} last_selected_element={this.state.last_selected_element} certificate_zoom={parseFloat(this.state.certificate_zoom)} certWidth={this.state.certWidth} certHeight={this.state.certHeight} move_canvas={this.state.move_canvas} grid_level={this.state.gridLevel} />
                                                            } else {
                                                                return <Element element_id={index} key={element.timeStamp + "-" + index} fetchChildState={this.handleSendToParent} fetchChildDrag={this.handleDragStop} fetchChildSize={this.handleResize} data={element} enableGrid={this.state.enableGrid} fetchHistory={this.handleHistory} fetchCenterGrid={this.centerGrid} last_selected_element={this.state.last_selected_element} openTagContents={this.handleTagContents} certificate_zoom={parseFloat(this.state.certificate_zoom)} generative={this.props.generative} certWidth={this.state.certWidth} certHeight={this.state.certHeight} toggleHelpModal={this.toggleHelpModal} move_canvas={this.state.move_canvas} grid_level={this.state.gridLevel} />
                                                            }
                                                        })
                                                    }
                                                    {
                                                        <ViewQR data={'edit'} size={this.state.QRSize} />
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Draggable>
                            </div>
                        }

                        <div className="bottom-sec">
                            {this.state.deleteHistory && (
                                <div className='undo-delete' onClick={() => this.undoDelete(this.state.deleteHistory[0])}>
                                    Undo Delete <i className="fa-solid fa-rotate-left"></i>
                                    <div className='bar animated'></div>
                                </div>
                            )}
                            <div className='container'>
                                {this.props.step_selection === '6' ?
                                    this.props.owner ?
                                        <div className='grid-wrapper' style={{ "paddingRight": "10px" }}>
                                            <h1>Credits Used</h1>
                                            <h2>{this.state.totalCreditsUsed + 10}/<span>{localStorage.getItem('displyCredits')}</span></h2>
                                            <button className='buy-credit-btn' onClick={() => window.location = '/premium'}>Buy</button>
                                        </div>
                                        :
                                        <div className='grid-wrapper' style={{ "paddingRight": "10px" }}>
                                            <h1>Credits Used</h1>
                                            <h2>{this.state.totalCreditsUsed + 10}</h2>
                                        </div>
                                    :
                                    <div className='grid-wrapper'>
                                        <h1>History</h1>
                                        <button className='history-btn' onClick={this.undoHandle}><i className="fa-solid fa-rotate-left"></i></button>
                                        <button className='history-btn' onClick={this.redoHandle}><i className="fa-solid fa-rotate-right"></i></button>
                                        <h1>Grid</h1>
                                        <label className="toggle-switch">
                                            <input type="checkbox" onChange={this.toggleGrid} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                }
                                {this.state.cloudUpdate ?
                                    <div className='container save-container active'>
                                        {this.state.saveModal && (
                                            <div className='save-modal' ref={this.saveModalRef}>
                                                <p>Choose Save</p>
                                                <button onClick={() => this.pushToDatabase("forced")}><label>Save</label><i className="fa-solid fa-repeat"></i></button>
                                                <button onClick={this.saveAsNew}><label>Save As New</label><i className="fa-solid fa-plus"></i></button>
                                            </div>
                                        )}
                                        <div className='manual-save' onClick={this.toggleSaveModal}>
                                            <i className="fa-regular fa-floppy-disk"></i>
                                            <p>Save</p>
                                        </div>
                                        <div className='auto-save'>
                                            <i className="fa-solid fa-cloud fs-24"></i>
                                            <p>Saved</p>
                                        </div>
                                    </div>
                                    :
                                    <div className='container save-container'>
                                        {this.state.saveModal && (
                                            <div className='save-modal' ref={this.saveModalRef}>
                                                <p>Choose Save</p>
                                                <button onClick={() => this.pushToDatabase("forced")}><label>Save</label><i className="fa-solid fa-repeat"></i></button>
                                                <button onClick={this.saveAsNew}><label>Save As New</label><i className="fa-solid fa-plus"></i></button>
                                            </div>
                                        )}
                                        <div className='manual-save' onClick={this.toggleSaveModal}>
                                            <i className="fa-regular fa-floppy-disk"></i>
                                            <p>Save</p>
                                        </div>
                                        <div className='auto-save'>
                                            <i className="fa-solid fa-cloud fs-24"></i>
                                            <p className='italic'>Saving...</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            {this.bottomSecRender(this.props.step_selection)}
                        </div>
                    </div>
                }
                <div className='help-sec'>
                    {this.props.step_selection === '6' || this.state.eventModal ? <div className='flex-grow'></div> :
                        <div className="range-slider">
                            <button className='recenter' onClick={this.recenterCertificate}><i className="fa-solid fa-arrows-to-circle"></i>Canvas Recenter</button>
                            <div className='zoom-icon' onClick={this.incrementCanvasZoom}>+</div>
                            <input className="input-range" type="range" name="certificate_zoom" step="0.1" value={this.state.certificate_zoom} min="0.8" max="2.8" onChange={this.handleChange} />
                            <div className='zoom-icon' onClick={this.decrementCanvasZoom}>-</div>
                            <button className='move' onClick={this.toggleMovement}>
                                {this.state.move_canvas ?
                                    <div className="inactive">Object</div> :
                                    <div className="active">Object</div>
                                }<i className="fa-solid fa-arrows-up-down-left-right"></i>
                                {this.state.move_canvas ?
                                    <div className="active">Canvas</div> :
                                    <div className="inactive">Canvas</div>
                                }</button>
                        </div>
                    }
                    <button onClick={this.toggleHelpModal}><i className="fa-solid fa-circle-question"></i> Help</button>
                    {this.state.helpModal && (
                        <HelpEditor toggleHelpModal={this.toggleHelpModal} step={parseInt(this.props.step_selection)} />
                    )}
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.loading_screen && (
                        <div className='loading-screen'>
                            <div className='content'>
                                <h2>Loading</h2>
                                <div className="loader"></div>
                                <p>Creating batch, Do not reload or exit this page.</p>
                            </div>
                        </div>
                    )
                }
                {this.state.showPickerColor && (
                    <div
                        style={{
                            position: 'fixed',
                            top: this.state.cursorY - 47, // Adjust as needed
                            left: this.state.cursorX - 15, // Adjust as needed
                            width: '30px',
                            height: '30px',
                            background: this.state.liveDropperColor,
                            borderRadius: '50%',
                            pointerEvents: 'none',
                            border: '1px solid #000'
                        }}
                    >
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default EditorComponent;