import React from 'react';
import './css/element.css';
import './css/organisation.css';
import feedbackGif from '../images/static/feedback.gif';
import axios from 'axios';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';
import sampleEmail from '../images/sampleEmail.png';
import ProgressLoader from '../components/progress_loader';

class BatchList extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            subeventList: [],
            emailTemplate: [{}],
            organiserModal: false,
            newOrganiserEmail: '',
            organiserList: [],
            deleteBatchRunning: false,
            emailPreviewModal: false,
            feedbackView: false,
            feedbacks: [],
            progress_loader: false,
            percentageRatings: [0, 0, 0, 0, 0],
            averageRating: 0,
            moreBtn: 'more',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_events_by_batch', {
            params: {
                //Future when organisation specific custom templates can be created
                org_id: '1d9de790-194c-11ed-80a1-3b912b942e91',
                event: this.props.eventName,
                subevent: this.props.subeventName,
            },
        }).then(res => {
            let subeventListArray = res.data.eventList.filter(obj => obj.subevent === this.props.subeventName);
            subeventListArray = subeventListArray.filter(obj => obj.event === this.props.eventName);
            this.setState({
                subeventList: subeventListArray,
                emailTemplate: res.data.template,
                organiserList: subeventListArray[0].organisers,
                emailPreviewModal: window.location.href.endsWith("#email") ? true : false,
            })

            if (window.location.href.endsWith("#feedbacks")) {
                this.toggleFeedbackViewOnMount(subeventListArray[0].batches);
            }

            if (subeventListArray.length > 0) {
                //Calculate pie diagram
                var canvas = document.getElementById("can");
                var ctx = canvas.getContext("2d");
                var lastend = 0;

                let count = {};
                for (let i = 0; i < subeventListArray[0].batches_status.length; i++) {
                    count[subeventListArray[0].batches_status[i]] = (count[subeventListArray[0].batches_status[i]] || 0) + 1;
                }
                let elements = Object.keys(count);
                let elementCounts = Object.values(count);
                let totalCount = subeventListArray.length;

                let elementPercentages = elementCounts.map(count => (count / totalCount) * 100 * 3.6);

                let orderedElements = ['pending', 'sent', 'rejected'];
                let orderedElementPercentages = [];
                for (let i = 0; i < orderedElements.length; i++) {
                    let index = elements.indexOf(orderedElements[i]);
                    if (index !== -1) {
                        orderedElementPercentages.push(elementPercentages[index]);
                    } else {
                        orderedElementPercentages.push(0);
                    }
                }

                var data = orderedElementPercentages;
                var myTotal = 0;
                //pending, ready, rejected
                var myColor = ['#FFCB74', '#2BCEAD', '#DF7184'];
                var labels = data.map(function (num) {
                    return (Math.round(num / 3.6 / 100));
                });

                for (var e = 0; e < data.length; e++) {
                    myTotal += data[e];
                }
                var off = 10
                var w = (canvas.width - off) / 2
                var h = (canvas.height - off) / 2
                for (var i = 0; i < data.length; i++) {
                    ctx.fillStyle = myColor[i];
                    ctx.strokeStyle = 'white';
                    ctx.lineWidth = 2;
                    ctx.beginPath();
                    ctx.moveTo(w, h);
                    var len = (data[i] / myTotal) * 2 * Math.PI
                    var r = h - off / 2
                    ctx.arc(w, h, r, lastend, lastend + len, false);
                    ctx.lineTo(w, h);
                    ctx.fill();
                    ctx.stroke();
                    ctx.fillStyle = 'white';
                    ctx.font = "16px Arial";
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    var mid = lastend + len / 2
                    ctx.fillText(labels[i], w + Math.cos(mid) * (r / 2), h + Math.sin(mid) * (r / 2));
                    lastend += Math.PI * 2 * (data[i] / myTotal);
                }
            }
        }).catch(err => { console.log(err) })
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    toggleOrganiserModal = () => {
        this.setState({
            organiserModal: !this.state.organiserModal,
        })
    }

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    addOrganiser = () => {
        this.setState({
            addOrganiserProcess: true,
        })
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (this.state.organiserList !== null && this.state.organiserList.length > 50) {
            this.setState({
                warning: {
                    message: "Only 50 Organisers can be added.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                addOrganiserProcess: false,
            })
        }
        else if (this.state.organiserList !== null && this.state.organiserList.includes(this.state.newOrganiserEmail)) {
            this.setState({
                warning: {
                    message: "Organiser Already Exists.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                addOrganiserProcess: false,
            })
        } else if (!emailPattern.test(this.state.newOrganiserEmail)) {
            this.setState({
                warning: {
                    message: "Invalid email for organiser.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                addOrganiserProcess: false,
            })
        } else if (!this.state.addOrganiserProcess) {
            console.log(this.state.newOrganiserEmail)
            axios.post(this.props.node_server_axios + 'add_organiser_to_event', {
                params: {
                    email: this.state.newOrganiserEmail,
                    event: this.props.eventName,
                    subevent: this.props.subeventName,
                },
            }).then(res => {
                if (res.data.message === 'updated') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Added " + this.state.newOrganiserEmail + " as an organiser in event " + this.props.eventName,
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState(prevState => ({
                        warning: {
                            message: "Organiser Added.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        newOrganiserEmail: '',
                        organiserModal: true,
                        organiserList: prevState.organiserList !== null && prevState.organiserList.length > 0
                            ? [...prevState.organiserList, prevState.newOrganiserEmail]
                            : [prevState.newOrganiserEmail],
                        addOrganiserProcess: false,
                    }));
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        addOrganiserProcess: false,
                    })
                }
            });
        }
    }

    deleteOrganiser = (toDelete) => {
        axios.post(this.props.node_server_axios + 'delete_organiser_from_event', {
            params: {
                email: toDelete,
                event: this.props.eventName,
                subevent: this.props.subeventName,
            },
        }).then(res => {
            if (res.data.message === 'deleted') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Removed " + toDelete + " from organisers from event " + this.props.eventName,
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState(prevState => ({
                    warning: {
                        message: "Organiser Removed.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    newOrganiserEmail: '',
                    organiserModal: true,
                    organiserList: prevState.organiserList.filter(element => element !== toDelete),
                }));
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        });
    }

    updateOrganiserEmail = (e) => {
        this.setState({
            newOrganiserEmail: e.target.value.slice(0, 128),
        })
    }

    deleteBatch = async (e, batchId) => {
        e.stopPropagation();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Batch",
                subtext: "Once deleted this batch cannot be recovered. Continue to delete?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        if (!this.state.deleteBatchRunning) {
            this.setState({
                deleteBatchRunning: true,
                progress_loader: true,
            })
            axios.post(this.props.node_server_axios + 'delete_batch', {
                params: {
                    org_id: this.props.org_id,
                    batch_id: batchId,
                    event: this.props.eventName,
                    subevent: this.props.subeventName,
                },
            }).then(res => {
                if (res.data.message === 'deleted') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Deleted batch for " + this.props.subeventName + " in event " + this.props.eventName,
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    if (res.data.del_event === 'true') {
                        window.location = '/organisation';
                    } else {
                        window.location = '/subevents/' + this.props.eventName + '/' + this.props.subeventName;
                    }
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        deleteBatchRunning: false,
                        progress_loader: false,
                    })
                }
            });
        }
    }

    openEmailPreview = () => {
        this.setState({
            emailPreviewModal: true,
        })
    }

    closeEmailPreview = (e) => {
        if (e.target.classList.contains('email-preview-modal')) {
            this.setState({
                emailPreviewModal: false,
            })
        }
    }

    toggleFeedbackView = () => {
        this.setState({
            progress_loader: true,
        })
        if (!this.state.feedbackView) {
            axios.post(this.props.node_server_axios + 'list_feedbacks', {
                params: {
                    batches: this.state.subeventList[0].batches,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    let totalRatings = 0;
                    const ratingCounts = {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0
                    };
                    res.data.feedbacks.forEach(feedback => {
                        const rating = feedback.rating;
                        if (rating >= 1 && rating <= 5) {
                            ratingCounts[rating]++;
                            totalRatings++;
                        }
                    });
                    const percentageRatings = {};
                    for (let i = 1; i <= 5; i++) {
                        percentageRatings[i] = parseInt((ratingCounts[i] / totalRatings) * 100);
                    }
                    let sumOfRatings = 0;
                    for (let i = 1; i <= 5; i++) {
                        sumOfRatings += i * ratingCounts[i];
                    }
                    const averageRating = sumOfRatings / totalRatings;

                    this.setState({
                        feedbacks: res.data.feedbacks,
                        feedbackView: true,
                        progress_loader: false,
                        percentageRatings: percentageRatings,
                        averageRating: averageRating.toFixed(1),
                    });
                } else {
                    this.setState({
                        feedbackView: true,
                        feedbacks: [],
                        progress_loader: false,
                    });
                }
            });
        } else {
            this.setState({
                feedbackView: !this.state.feedbackView,
                progress_loader: false,
            })
        }
        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    toggleFeedbackViewOnMount = (subeventList) => {
        this.setState({
            progress_loader: true,
        })
        if (!this.state.feedbackView) {
            axios.post(this.props.node_server_axios + 'list_feedbacks', {
                params: {
                    batches: subeventList,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    let totalRatings = 0;
                    const ratingCounts = {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0
                    };
                    res.data.feedbacks.forEach(feedback => {
                        const rating = feedback.rating;
                        if (rating >= 1 && rating <= 5) {
                            ratingCounts[rating]++;
                            totalRatings++;
                        }
                    });
                    const percentageRatings = {};
                    for (let i = 1; i <= 5; i++) {
                        percentageRatings[i] = parseInt((ratingCounts[i] / totalRatings) * 100);
                    }
                    let sumOfRatings = 0;
                    for (let i = 1; i <= 5; i++) {
                        sumOfRatings += i * ratingCounts[i];
                    }
                    const averageRating = sumOfRatings / totalRatings;

                    this.setState({
                        feedbacks: res.data.feedbacks,
                        feedbackView: true,
                        progress_loader: false,
                        percentageRatings: percentageRatings,
                        averageRating: averageRating.toFixed(1),
                    });
                } else {
                    this.setState({
                        feedbackView: true,
                        feedbacks: [],
                        progress_loader: false,
                    });
                }
            });
        } else {
            this.setState({
                feedbackView: !this.state.feedbackView,
                progress_loader: false,
            })
        }
        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const hasHalfStar = rating - fullStars >= 0.5;

        const starIcons = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= fullStars) {
                starIcons.push(<i key={i} className="fas fa-star"></i>);
            } else if (hasHalfStar && i === Math.ceil(rating)) {
                starIcons.push(<i key={i} className="fas fa-star-half-alt"></i>);
            } else {
                starIcons.push(<i key={i} className="far fa-star"></i>);
            }
        }

        return starIcons;
    }

    convertDate = (inputDate) => {
        const year = Math.floor(inputDate / 10000);
        const month = Math.floor((inputDate % 10000) / 100);
        const day = inputDate % 100;

        const monthsArray = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const monthName = monthsArray[month - 1];
        return `${day} ${monthName} ${year}`;
    }

    convertToCSV = (data) => {
        const header = ['name', 'email', 'feedback', 'rating'];
        const csvData = [header.join(',')];
        data.forEach((item) => {
            const row = [
                item.name || 'Anonymous',
                item.email,
                item.feedback.replace(/[\r\n,]/g, ' '),
                item.rating,
            ];
            csvData.push(row.join(','));
        });
        return csvData.join('\n');
    };

    downloadCSV = () => {
        const feedbackData = this.state.feedbacks;
        const csvContent = this.convertToCSV(feedbackData);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'feedback_data.csv';
        link.click();
    };

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    {this.state.emailPreviewModal && (
                        <div className='email-preview-modal' onClick={this.closeEmailPreview}>
                            <div className='email-template'>
                                {/* Make the data dynamic (To-Do) */}
                                <div style={{ margin: '0', padding: '10px', fontFamily: 'sans-serif', background: '#F5F3EF', borderRadius: '10px' }}>
                                    <a className='btn-sub-nav' href={'../../email/' + this.props.eventName.replace(/\s+/g, '-').replace(/-+/g, '-').trim() + '/' + this.props.subeventName.replace(/\s+/g, '-').replace(/-+/g, '-').trim()}><i className="fa-solid fa-pen-to-square"></i> Change Email Template</a>
                                    <table style={{ width: '100%', maxWidth: '600px', display: 'block', margin: 'auto' }}>
                                        <tbody style={{ display: 'block' }}>
                                            <tr style={{ display: 'block', backgroundColor: '#F5F3EF' }}>
                                                <td style={{ display: 'block', padding: '15px' }}>
                                                    <img style={{ height: '60px', display: 'block', margin: 'auto' }} src="https://cdn-aerobits-old.s3.ap-south-1.amazonaws.com/pronetin/images/pronetin-med-full.png" alt="Logo" />
                                                </td>
                                            </tr>
                                            <tr style={{ display: 'block', backgroundColor: '#FFF', borderRadius: '10px' }}>
                                                <td style={{ display: 'block', padding: '30px', border: '1px solid #CCC', borderRadius: '10px' }}>

                                                    <p dangerouslySetInnerHTML={{ __html: this.state.emailTemplate[0].body }} style={{ lineHeight: '1.25', fontSize: '18px', fontWeight: '400', color: '#586069', wordBreak: 'normal', textAlign: 'justify', margin: '0 0 30px' }}></p>

                                                    <a style={{ padding: '15px 30px', width: 'fit-content', border: '1px solid #2977C9', backgroundColor: '#2977C9', color: '#FFF', borderRadius: '5px', display: 'block', textDecoration: 'none', margin: '0 0 30px' }} href="#foo">Sign In</a>
                                                    <p style={{ lineHeight: '1.25', fontSize: '16px', fontWeight: '400', color: '#586069', wordBreak: 'normal', textAlign: 'left', margin: '0 0 30px' }}>
                                                        Do not have an account?
                                                    </p>
                                                    <a style={{ padding: '15px 30px', width: 'fit-content', border: '1px solid #2977C9', backgroundColor: '#2977C9', color: '#FFF', borderRadius: '5px', display: 'block', textDecoration: 'none', margin: '0 0 30px' }} href="#foo">Sign Up</a>
                                                    <p style={{ lineHeight: '1.25', fontSize: '14px', fontWeight: '400', color: '#586069', wordBreak: 'normal', textAlign: 'left', margin: '0 0 30px' }}>
                                                        <b>If it's not meant for you, ignore this mail</b><br />
                                                        Please Sign up / Sign in to pronetin.com to view the certificates.
                                                        New users make sure to Sign Up initially with the same email id to which you received this email.
                                                        Kindly Accept the certificate in your Pronetin profile to view your name on the certificate and to download it.
                                                    </p>
                                                    <hr style={{ margin: '0 0 30px', backgroundColor: '#CCC', border: 'none', height: '1px' }} />
                                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <a style={{ fontSize: '12px', color: '#CCC', textDecoration: 'none', textAlign: 'center', display: 'inline-block', margin: 'auto' }} href="#foo">Privacy Policy.&nbsp;&nbsp;</a>
                                                        <a style={{ fontSize: '12px', color: '#CCC', textDecoration: 'none', textAlign: 'center', display: 'inline-block', margin: 'auto' }} href="#foo">Sign Up to Pronetin.&nbsp;&nbsp;</a>
                                                        <a style={{ fontSize: '12px', color: '#CCC', textDecoration: 'none', textAlign: 'center', display: 'inline-block', margin: 'auto' }} href="#foo">Sign In to Pronetin.</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style={{ display: 'block', backgroundColor: '#F5F3EF' }}>
                                                <td style={{ display: 'block', padding: '15px' }}>
                                                    <img style={{ height: '60px', display: 'block', margin: 'auto' }} src="https://cdn-aerobits-old.s3.ap-south-1.amazonaws.com/pronetin/images/aerobits-logo.png" alt="Logo" />
                                                    <p style={{ lineHeight: '1.25', fontSize: '14px', fontWeight: '300', color: '#CCC', wordBreak: 'normal', textAlign: 'center', margin: '10px 0px' }}>
                                                        Sent with ❤️ by Pronetin.<br />
                                                        Aerobits Developers Pvt. Limited, India<br />
                                                        &copy;Pronetin 2021
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Batch List<label>View all your issued batches</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                {this.state.feedbackView ?
                                    <React.Fragment>
                                        <h1><i className="fa-solid fa-chart-pie"></i> Ratings</h1>
                                        {Array.isArray(this.state.percentageRatings) ?
                                            <p>No ratings yet</p>
                                            :
                                            <div className='rating-bar-graph'>
                                                <div className='wrap'>
                                                    <label>5 Star</label><div className='bar'><div className='filled' style={{ width: this.state.percentageRatings[5] + '%' }}></div></div>
                                                </div>
                                                <div className='wrap'>
                                                    <label>4 Star</label><div className='bar'><div className='filled' style={{ width: this.state.percentageRatings[4] + '%' }}></div></div>
                                                </div>
                                                <div className='wrap'>
                                                    <label>3 Star</label><div className='bar'><div className='filled' style={{ width: this.state.percentageRatings[3] + '%' }}></div></div>
                                                </div>
                                                <div className='wrap'>
                                                    <label>2 Star</label><div className='bar'><div className='filled' style={{ width: this.state.percentageRatings[2] + '%' }}></div></div>
                                                </div>
                                                <div className='wrap'>
                                                    <label>1 Star</label><div className='bar'><div className='filled' style={{ width: this.state.percentageRatings[1] + '%' }}></div></div>
                                                </div>
                                            </div>
                                        }
                                        <br></br>
                                        <p>Average : {this.state.averageRating} <i className="fas fa-star" style={{ color: '#FFCB74' }}></i></p>
                                        <p>{this.state.feedbacks.length} Feedbacks</p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <h1><i className="fa-solid fa-chart-pie"></i> Statistics</h1>
                                        <canvas id="can" height="200" />
                                        <div className='pie-explanation'>
                                            <div className='exp'><div className='color-box accepted'></div> Sent</div>
                                            <div className='exp'><div className='color-box rejected'></div> Rejected</div>
                                            <div className='exp'><div className='color-box pending'></div> Pending</div>
                                        </div>
                                        <br></br>
                                        <h1><i className="fa-solid fa-envelope"></i> Email Template</h1>
                                        <div className='batch-data-email-preview' onClick={this.openEmailPreview}>
                                            <img src={sampleEmail} alt="email preview" />
                                            <button>Edit Template <i className="fa-solid fa-pen-to-square"></i></button>
                                        </div>
                                    </React.Fragment>
                                }
                                <br></br>
                                <h1><i className="fa-solid fa-envelope"></i> Manage Organisers</h1>
                                <button className='btn-sub-nav' onClick={this.toggleOrganiserModal}>Add Organiser <i className="fa-solid fa-plus"></i></button>
                                <br></br>
                                <h1><i className={this.state.feedbackView ? "fa-solid fa-layer-group" : "fa-regular fa-comment-dots"}></i> {this.state.feedbackView ? 'Batches' : 'Feedbacks'}</h1>
                                <button className='btn-sub-nav' onClick={this.toggleFeedbackView}>View {this.state.feedbackView ? 'Batches' : 'Feedbacks'} <i className="fa-solid fa-arrow-right-long"></i></button>
                                {this.state.feedbacks.length > 0 && (
                                    <button className='btn-sub-nav download-btn' onClick={this.downloadCSV}>Download <i className="fa-regular fa-circle-down"></i></button>
                                )}
                            </div>
                            <div className='wrapper scroll-bar'>
                                {this.state.organiserModal && (
                                    <div className='element-modal'>
                                        <div className='content'>
                                            <button className='close-btn' onClick={this.toggleOrganiserModal}><i className="fa-solid fa-x"></i></button>
                                            <div className='container'>
                                                <h1>Add Organiser</h1>
                                            </div>
                                            <div className='container'>
                                                <input type='text' placeholder='Email' value={this.state.newOrganiserEmail} onChange={(e) => this.updateOrganiserEmail(e)} required />
                                                <button type="submit" onClick={this.addOrganiser}>Add</button>
                                            </div>
                                            <br></br>
                                            <h1>Current Organisers</h1>
                                            <div className='organisers-list'>
                                                {this.state.organiserList !== null && this.state.organiserList.length > 0 ?
                                                    this.state.organiserList.map((element, index) =>
                                                        <div className='container' key={index}>
                                                            <p title={element}>{element}</p>
                                                            <button className='del-btn' onClick={() => this.deleteOrganiser(element)}><i className="fa-regular fa-trash-can"></i> remove</button>
                                                        </div>
                                                    )
                                                    :
                                                    <p>No organisers for this event</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {
                                    this.state.subeventList.map((element, index) => {
                                        return (
                                            <div className='subevent-wrapper' key={index}>
                                                <div className='subevent-head jc-sb'>
                                                    <a className='prev-btn' href='/organisation'><i className="fa-solid fa-arrow-left"></i> <p>Events</p></a>
                                                    <div className='sub'>
                                                        <h2>{element.event}</h2>
                                                        <h3>{element.subevent}</h3>
                                                        <p>{element.batches.length} Batches | {element.batches_count.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)} Certificates</p>
                                                    </div>
                                                </div>
                                                <div className='batch-container'>
                                                    {this.state.feedbackView && this.state.feedbacks.length === 0 && (
                                                        <div className='feedback-wrapper'>
                                                            <img src={feedbackGif} alt='feedback gif' />
                                                            <hr></hr>
                                                            <div className='container'>
                                                                <div className='date'>Today</div>
                                                                <div className='content'>No feedback available. Feedbacks will appear when a certificate receipient enters a feedback</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.feedbackView ?
                                                        this.state.feedbacks.map((feedback, i) => {
                                                            return (
                                                                <div key={i} className='feedback-wrapper'>
                                                                    <div className='container'>
                                                                        <div className="star-rating-view">
                                                                            {this.renderStars(feedback.rating)}
                                                                        </div>
                                                                        <div className='name'>{feedback.name === null ? 'Anonymous' : feedback.name}</div>
                                                                        <div className='email'>{/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(feedback.email) ? "*********@gmail.com" : feedback.email}</div>
                                                                    </div>
                                                                    <hr></hr>
                                                                    <div className='container'>
                                                                        <div className='date'>{this.convertDate(feedback.itime)}</div>
                                                                        <div className='content'>{feedback.feedback.length > 0 ? feedback.feedback : "No feedback given"}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        :
                                                        element.batches.map((batch, i) => {
                                                            return (
                                                                <div className='batch-tile' key={i} onClick={() => { window.location.href = '/batch/' + batch }}>
                                                                    {(element.batches_status[i] === 'pending' || element.batches_status[i] === 'rejected') && (
                                                                        <div className='batch-delete' onClick={(e) => this.deleteBatch(e, batch)}><i className="fa-regular fa-trash-can"></i></div>
                                                                    )}
                                                                    <div className='data'>
                                                                        <h2>Batch {i + 1}</h2>
                                                                        <div className='cert-count'>{element.batches_count[i]} Certificates</div>
                                                                        <div className={'status ' + element.batches_status[i]}>{element.batches_status[i]}</div>
                                                                    </div>
                                                                    <div className='btn-container'>
                                                                        <div className='btn modified-on'><label>Batch Id</label><p>{batch.split("-")[0]}</p></div>
                                                                        <button className='btn'>View</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment >
        );
    }
}

export default BatchList;