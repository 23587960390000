import React from 'react';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        let element_id = isNaN(this.props.element_id) ? this.props.element_id.split("-")[1] : this.props.element_id;
        this.state = {
            image: false,
            font: this.props.data,
            originalString: this.props.data.content,
            matchArray: this.props.variables,
            replaceArray: this.props.csvDataIndex ? this.props.csvData[this.props.csvDataIndex] : this.props.csvData[0],
            zIndex: this.props.data.zIndex > element_id ? this.props.data.zIndex : element_id,
            fontSize: this.props.data.fontSize,
        };
        this.containerRef = React.createRef();
    }

    componentDidMount = () => {
        if (this.props.csvData && this.props.csvData.length) {
            this.handlePreviewCreation()
        }
        const pattern = /\[[^\]]+\]/;
        if (pattern.test(this.props.data.content)) {
            this.updateFontSize();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.csvDataIndex !== this.props.csvDataIndex) {
            this.setState({
                originalString: this.props.data.content,
                replaceArray: this.props.csvData[this.props.csvDataIndex],
            }, () => {
                this.handlePreviewCreation();
            });
        }
    }

    handlePreviewCreation = () => {
        const { originalString, matchArray, replaceArray } = this.state;
        let newString = originalString;
        matchArray.forEach((match) => {
            if (replaceArray[match]) {
                newString = newString.replace(match, replaceArray[match]);
            }
        });
        this.setState({ originalString: newString });
    }

    updateFontSize = () => {
        const container = this.containerRef.current;
        const { elWidth, elHeight } = this.props.data;

        if (container) {
            requestAnimationFrame(() => {
                let minFontSize = 1;
                let maxFontSize = parseInt(this.props.data.fontSize);
                while (minFontSize < maxFontSize - 1) {
                    const fontSize = Math.ceil((minFontSize + maxFontSize) / 2);
                    container.style.fontSize = fontSize + "px";
                    const containerWidth = container.scrollWidth;
                    const containerHeight = container.scrollHeight;
                    if (containerWidth <= elWidth && containerHeight <= elHeight) {
                        minFontSize = fontSize;
                    } else {
                        maxFontSize = fontSize;
                    }
                }
                this.setState({ fontSize: minFontSize });
            });
        }
    };

    extractUuidFromUrl() {
        var url = window.location.href;
        var parts = url.split("/");
        var lastPart = parts.pop();
        var uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
        console.log(lastPart);
        var isValidUUID = uuidRegex.test(lastPart);
        if (isValidUUID) {
            return lastPart;
        } else {
            return 'XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX';
        }
    }

    render() {
        return (
            this.state.font.dataType === "textarea" ?
                <div className="wrap viewer-wrap" style={{ top: this.state.font.y + "px", left: this.state.font.x + "px", zIndex: this.state.zIndex }}>
                    <div
                        ref={this.containerRef}
                        style={{
                            fontSize: this.state.fontSize + "px",
                            fontFamily: this.state.font.fontFamily,
                            color: this.state.font.fontColor,
                            fontWeight: this.state.font.fontWeight ? "600" : "400",
                            fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                            textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                            textAlign: this.state.font.textAlign,
                            lineHeight: this.state.font.lineHeight,
                            height: this.state.font.elHeight + "px",
                            width: this.state.font.elWidth + "px",
                            padding: "1px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-all",
                        }}
                        readOnly={true}
                        disabled={true}
                    >{this.state.originalString}</div>
                </div>
                :
                <div className="wrap viewer-wrap" style={{ top: this.state.font.y + "px", left: this.state.font.x + "px" }}>
                    {this.state.font.span !== undefined && this.state.font.span !== false && (
                        <span className='field-label' style={{ textAlign: this.state.font.textAlign }}>{this.state.font.span}</span>
                    )}
                    {
                        this.state.font.span === "URL" ?
                            <a
                                style={{
                                    fontSize: this.state.font.fontSize + "px",
                                    fontFamily: this.state.font.fontFamily,
                                    color: this.state.font.fontColor,
                                    fontWeight: this.state.font.fontWeight ? "600" : "400",
                                    fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                    textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                    textAlign: this.state.font.textAlign,
                                    lineHeight: this.state.font.lineHeight < 30 ? "30px" : this.state.font.lineHeight,
                                    height: this.state.autoResize ? this.state.textarea.height + "px" : this.state.font.readOnly ? "auto" : this.state.font.elHeight + "px",
                                    width: this.state.font.elWidth,
                                    padding: '1px',
                                    pointerEvents: "all",
                                    display: "block",
                                }}
                                href={this.state.originalString.split("@")[1]}
                                target="_blank"
                                rel='noreferrer'
                            >{this.state.originalString.split("@")[0]}</a>
                            :
                            <input type="text"
                                placeholder={this.state.font.placeholder}
                                style={{
                                    fontSize: this.state.font.fontSize + "px",
                                    fontFamily: this.state.font.fontFamily,
                                    color: this.state.font.fontColor,
                                    fontWeight: this.state.font.fontWeight ? "600" : "400",
                                    fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                    textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                    textAlign: this.state.font.textAlign,
                                    lineHeight: this.state.font.lineHeight,
                                    height: this.state.font.elHeight + "px",
                                    width: this.state.font.elWidth + "px",
                                }}
                                value={this.state.font.span === 'Certificate ID' ? this.extractUuidFromUrl() : this.state.originalString}
                                readOnly={true}
                            />
                    }
                </div>
        );
    }
}

export default ElementComponent;