import React from 'react';
import Draggable from 'react-draggable';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleDragStop.bind(this);
        this.setTableStage.bind(this);
        this.outsideClickRef = React.createRef(null)
        this.clickTimeout = null
        this.state = {
            image: false,
            table: true,
            font: this.props.data,
            notDraggable: false,
            controls: false,
            id: this.props.element_id,
            toDelete: false,
            content: this.props.data.content,
            x: this.props.data.x,
            y: this.props.data.y,
            autoResize: false,
            zIndex: this.props.element_id,
            unnamedVariable: 1,
            keyStamp: Date.now(),
            columns: this.props.data.columns,
            rows: this.props.data.rows,
            enableGrid: this.props.enableGrid,
            textarea: {
                height: this.props.data.elHeight,
                width: this.props.data.elWidth,
            },
            gridLevel: parseInt(this.props.grid_level) * 10,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.font.content === state.content) {
            return {
                font: props.data,
                id: state.id,
                content: props.data.content,
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        } else {
            return {
                font: props.data,
                id: state.id,
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        }
    }

    hideControls = () => {
        this.setState({
            controls: false,
            notDraggable: false,
            zIndex: this.props.element_id,
        })
    }

    handleSendToParent = (e) => {
        function convertAllHtmlEntities(htmlString) {
            const parser = new DOMParser();
            const decodedString = parser.parseFromString(htmlString.replace(/<br\s*\/?>/g, '\n'), 'text/html').documentElement.textContent;
            return decodedString;
        }

        if (e !== 'update' && e.currentTarget.id === "delete" && this.state.toDelete !== 'required') {
            this.setState({
                toDelete: this.state.font.timeStamp,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        } else {
            const table = document.getElementById("table-" + this.state.font.timeStamp);
            let tableData = [];
            for (let i = 1; i < table.rows.length; i++) {
                let rowData = [];
                for (let j = 0; j < table.rows[i].cells.length - 1; j++) {
                    rowData.push(convertAllHtmlEntities(table.rows[i].cells[j].innerHTML));
                }
                tableData.push(rowData);
            }
            this.setState(({
                content: tableData
            }), () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    addRow = () => {
        let columns = document.getElementById("table-" + this.state.font.timeStamp).rows[0].cells.length;
        this.setState({
            content: [
                ...this.state.content,
                Array(columns).fill(""),
            ],
            rows: parseInt(this.state.rows) + 1,
        }, () => {
            this.props.fetchChildState(this.state)
        })
    }

    addColumn = () => {
        let arr = this.state.content;
        let updatedArr = arr.map((innerArr) => {
            return innerArr.concat("");
        });
        this.setState({
            content: updatedArr,
            columns: parseInt(this.state.columns) + 1,
        }, () => {
            this.props.fetchChildState(this.state)
        })
    }

    deleteRow = (index) => {
        if (parseInt(this.state.rows) > 1) {
            let injected_array = this.state.content
            injected_array.splice(index, 1)
            this.setState({
                content: injected_array,
                keyStamp: Date.now(),
                rows: parseInt(this.state.rows) - 1,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    deleteColumn = (index) => {
        if (parseInt(this.state.columns) > 1) {
            let arr = this.state.content;
            let updatedArr = arr.map((innerArr) => {
                return innerArr.filter((_, i) => parseInt(i) !== parseInt(index));
            });
            this.setState({
                content: updatedArr,
                keyStamp: Date.now(),
                columns: parseInt(this.state.columns) - 1,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    formatPasteValue = (e) => {
        var content = e.clipboardData.getData('text/plain');
        content = content.replace(/<\/?[^>]+(>|$)/g, "");
        document.execCommand('insertHTML', false, content);
        e.preventDefault();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener('paste', this.formatPasteValue);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.addEventListener('paste', this.formatPasteValue);
    }

    handleClickOutside = (e) => {
        if (this.state.controls && !this.outsideClickRef.current.contains(e.target)) {
            this.hideControls();
            this.handleSendToParent('update');
        }

        this.setState(({
            textarea: {
                height: document.getElementById("table-" + this.state.font.timeStamp).offsetHeight,
                width: document.getElementById("table-" + this.state.font.timeStamp).offsetWidth,
            },
        }), () => {
            this.props.fetchChildSize(this.state)
        })
    }

    handleDragStop = (event, dragElement) => {
        this.setState({
            x: dragElement.x,
            y: dragElement.y,
        }, () => {
            this.props.fetchChildDrag(this.state)
        })
    };

    handleHistory = (event, dragElement) => {
        this.props.fetchHistory(this.state.id)
    };

    handleClicks = (e) => {
        if (this.clickTimeout !== null && !this.state.font.required) {
            this.setState({
                controls: true,
                notDraggable: true,
                zIndex: 999,
            });
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else if (!this.state.notDraggable) {
            this.clickTimeout = setTimeout(() => {
                e.preventDefault()
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 500)
        }
    }

    setTableStage = (e) => {
        this.props.fetchTableStage(this.state)
    }

    render() {
        return (
            <Draggable position={{ x: this.state.font.x, y: this.state.font.y }} bounds="parent" disabled={this.state.notDraggable} cancel={this.state.notDraggable ? 'table-wrapper,select,button' : 'select,button'} onStop={this.handleDragStop} grid={this.state.enableGrid ? [this.state.gridLevel, this.state.gridLevel] : null} onStart={this.handleHistory} scale={this.state.enableGrid ? 1 : this.props.certificate_zoom}>
                <div className={this.state.notDraggable ? 'box pinned' : 'box'} ref={this.outsideClickRef} style={{ zIndex: this.state.zIndex, pointerEvents: this.props.move_canvas ? "all" : "none" }} id={"box" + this.state.font.timeStamp}>
                    {this.state.controls && (
                        <div className='variable-controls'>
                            {
                                this.state.font.required ? "" : <button className='delete' id="delete" onClick={this.handleSendToParent}><i className="fa-solid fa-trash pe-none"></i></button>
                            }
                        </div>
                    )}
                    {
                        <div className="wrap">
                            <div className="pull-tab"></div>
                            <div className='table-wrapper' style={{
                                minHeight: (parseInt(this.state.rows + 1) * 32) + "px",
                                resize: this.state.controls ? "both" : "none",
                                overflow: "hidden",
                                height: this.state.font.elHeight + "px",
                                width: this.state.font.elWidth + "px",
                            }}>

                                <table id={"table-" + this.state.font.timeStamp} style={{
                                    fontSize: this.state.font.fontSize + "px",
                                    fontFamily: this.state.font.fontFamily,
                                    color: this.state.font.fontColor,
                                    fontWeight: this.state.font.fontWeight ? "600" : "400",
                                    fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                    textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                    textAlign: this.state.font.textAlign,
                                    lineHeight: this.state.font.lineHeight,
                                }}
                                    onMouseDown={this.handleClicks}
                                    onMouseUp={this.setTableStage}>
                                    <tbody>
                                        {this.state.controls &&
                                            (<tr key="del-col">
                                                {
                                                    this.state.content[0].map((x, i) => (
                                                        <td id={i} key={i} className='td-delete'><i className="fa-solid fa-circle-xmark" onClick={() => this.deleteColumn(i)}></i></td>
                                                    ))
                                                }
                                            </tr>)
                                        }
                                        {
                                            this.state.content.map((element, index) => (
                                                <tr key={"tr-" + index}>
                                                    {
                                                        element.map((x, i) => (
                                                            <td suppressContentEditableWarning={true} contentEditable={this.state.controls} key={"td-" + index + i + this.state.keyStamp} className='inherit' type="text" value={x} onBlur={this.handleSendToParent}>{x}</td>
                                                        ))
                                                    }
                                                    {this.state.controls && (<td id={index} className='td-delete'><i className="fa-solid fa-circle-xmark" onClick={() => this.deleteRow(index)}></i></td>)}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        this.state.controls && (
                            <div className="table-controls">
                                <button className='addRow-btn' onClick={this.addRow}>Add Row <i className="fa-solid fa-circle-plus"></i></button>
                                <button className='addRow-btn' onClick={this.addColumn}>Add Column <i className="fa-solid fa-circle-plus"></i></button>
                            </div>
                        )
                    }
                </div>
            </Draggable>
        );
    }
}

export default ElementComponent;