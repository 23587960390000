import React from 'react';
import Draggable from 'react-draggable';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleDragStop.bind(this);
        this.handleResize.bind(this);
        this.outsideClickRef = React.createRef(null)
        this.clickTimeout = null
        this.state = {
            image: false,
            font: this.props.data,
            notDraggable: false,
            controls: false,
            id: this.props.element_id,
            toDelete: false,
            content: this.props.generative ? '' : this.props.data.content,
            x: this.props.data.x,
            y: this.props.data.y,
            autoResize: false,
            textarea: {
                height: this.props.data.elHeight,
                width: this.props.data.elWidth,
            },
            zIndex: this.props.data.zIndex > this.props.element_id ? this.props.data.zIndex : this.props.element_id,
            unnamedVariable: 1,
            enableGrid: this.props.enableGrid,
            validityDays: 0,
            currentIndex: 0,
            oneTime_1: false,
            gridLevel: parseInt(this.props.grid_level) * 10,
            gridSnapped: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.font.content === state.content) {
            return {
                font: props.data,
                id: state.id,
                content: props.data.content,
                width: state.font.readOnly && document.getElementById("span" + state.font.timeStamp) !== null ? (document.getElementById("span" + state.font.timeStamp).offsetWidth + 14) + "px" : "",
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        } else {
            return {
                font: props.data,
                id: state.id,
                width: state.font.readOnly && document.getElementById("span" + state.font.timeStamp) !== null ? (document.getElementById("span" + state.font.timeStamp).offsetWidth + 14) + "px" : "",
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        }
    }

    hideControls = () => {
        this.setState({
            controls: false,
            notDraggable: false,
            zIndex: this.props.element_id === 0 ? 1 : this.props.element_id,
        })
    }

    updateValidity = (e) => {
        if (this.state.font.type === "validity") {
            let today = new Date();
            let otherDate = new Date(e.currentTarget.value);
            let timeDiff = Math.abs(otherDate.getTime() - today.getTime());
            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            this.setState({
                validityDays: diffDays,
            })
        }
    }

    handleSendToParent = (e) => {
        if (e.currentTarget.id === "delete" && this.state.toDelete !== 'required') {
            this.setState({
                toDelete: this.state.font.timeStamp,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        } else {
            if (e.target.scrollHeight > e.target.offsetHeight) {
                this.setState({
                    toDelete: false,
                    content: e.target.value,
                    autoResize: true,
                    textarea: {
                        height: e.target.scrollHeight,
                        width: e.target.offsetWidth,
                    },
                }, () => {
                    this.props.fetchChildState(this.state)
                })
            } else {
                this.setState({
                    toDelete: false,
                    content: e.target.value,
                }, () => {
                    this.props.fetchChildState(this.state)
                })
            }
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (this.props.generative) {
            this.typeText();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.state.controls && !this.outsideClickRef.current.contains(e.target)) {
            this.hideControls();
        }
    }

    handleDragStop = (event, dragElement) => {
        this.setState({
            x: dragElement.x,
            y: dragElement.y,
        }, () => {
            this.props.fetchChildDrag(this.state)
        })
    };

    handleHistory = (event, dragElement) => {
        this.props.fetchHistory(this.state.id)
    };

    handleClicks = (e) => {
        // if (this.clickTimeout !== null && !this.state.font.required) {
        // Removed required from check condition to togggle control 
        // So that edit function accessible by required inputs
        if (this.clickTimeout !== null) {
            this.setState({
                controls: true,
                notDraggable: true,
                zIndex: 999,
            });
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null

            //handle help pop-up for Recepient Name edit
            if (this.state.content === '[Recepient Name]' && !this.state.oneTime_1) {
                this.props.toggleHelpModal(2, 3) //2,3 are stage and intex of help within that stage
                this.setState({
                    oneTime_1: true
                });
            }

        } else if (!this.state.notDraggable) {
            this.clickTimeout = setTimeout(() => {
                e.preventDefault()
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 500)
        }
    }

    handleResize = (e) => {
        this.setState({
            textarea: {
                height: e.target.offsetHeight,
                width: e.target.offsetWidth,
            },
        }, () => {
            this.props.fetchChildSize(this.state)
            //To add to history when resized
            this.handleHistory();
        })

        setTimeout(() => {
            this.props.fetchCenterGrid(false, false)
        }, 1000);
    }

    addVariable = () => {
        this.setState({
            content: this.state.content + "[variable " + this.state.id + "." + this.state.unnamedVariable + "]",
            unnamedVariable: this.state.unnamedVariable + 1,
        }, () => {
            this.props.fetchChildState(this.state)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.last_selected_element === this.state.id) {
            this.findGrid(0, { x: this.state.font.x, y: this.state.font.y })
        }
        if (prevState.gridLevel !== this.state.gridLevel) {
            this.setState({
                gridSnapped: false,
            });
        }
    }

    findGrid = (event, dragElement) => {

        if (Math.abs(dragElement.deltaX) > 2 || Math.abs(dragElement.deltaY) > 2) {
            this.snapElement(dragElement);
        }

        let centerGridX = false;
        let centerGridY = false;
        if (Math.ceil(parseInt(dragElement.x + (parseInt(this.state.textarea.width / 2))) / 2) === this.props.certWidth / 4) {
            centerGridX = true
        }
        if (Math.ceil(parseInt(dragElement.y + (parseInt(this.state.textarea.height / 2))) / 2) === this.props.certHeight / 4) {
            centerGridY = true
        }
        if (this.state.controls) {
            this.props.fetchCenterGrid(false, false)
        } else {
            this.props.fetchCenterGrid(centerGridX, centerGridY)
        }
    }

    snapElement = (dragElement) => {
        if (this.state.enableGrid && !this.state.gridSnapped) {
            const gridLevel = this.state.gridLevel;
            const roundedX = Math.round(dragElement.x / gridLevel) * gridLevel;
            const roundedY = Math.round(dragElement.y / gridLevel) * gridLevel;
            this.setState({
                x: roundedX,
                y: roundedY,
                gridSnapped: true,
            }, () => {
                this.props.fetchChildDrag(this.state)
            })
        }
    }

    typeText = () => {
        const { currentIndex } = this.state;
        const { content } = this.props.data;
        if (currentIndex < content.length) {
            setTimeout(() => {
                this.setState((prevState) => ({
                    content: currentIndex === 0 ? content[currentIndex] : prevState.content + content[currentIndex],
                    currentIndex: prevState.currentIndex + 1,
                }));
                this.typeText();
            }, 60); // Adjust typing speed here
        }
    };

    render() {
        return (
            <Draggable position={{ x: this.state.font.x, y: this.state.font.y }} bounds="parent" disabled={this.state.notDraggable} cancel={this.state.notDraggable ? 'textarea,select,button' : 'select,button'} onStop={this.handleDragStop} grid={this.state.enableGrid ? [this.state.gridLevel, this.state.gridLevel] : null} onStart={this.handleHistory} onDrag={this.findGrid} scale={this.state.enableGrid ? 1 : this.props.certificate_zoom}>
                <div className={this.state.notDraggable ? 'box pinned' : 'box'} ref={this.outsideClickRef} style={{ zIndex: this.state.zIndex, pointerEvents: this.props.move_canvas ? "all" : "none" }} id={"box" + this.state.font.timeStamp}>
                    {this.state.controls && (
                        <div className='variable-controls'>
                            {
                                this.state.font.dataType === "textarea" && !this.state.font.readOnly ?
                                    <button className='variable' onClick={this.addVariable}>variable&nbsp;+</button>
                                    :
                                    this.state.font.readOnly ? <button className='readonly'>Read-only</button> : ""
                            }
                            {
                                this.state.font.required ? "" : <button className='delete' id="delete" onClick={this.handleSendToParent}><i className="fa-solid fa-trash pe-none"></i></button>
                            }
                            {
                                this.state.font.readOnly && this.state.content === '[Recepient Name]' && (<button className='help' onClick={() => this.props.toggleHelpModal(2, 3)}>?</button>)
                            }
                        </div>
                    )}
                    {
                        this.state.font.dataType === "textarea" ?
                            <div className="wrap">
                                <div className="pull-tab"></div>
                                <textarea
                                    placeholder={this.state.font.placeholder}
                                    style={{
                                        fontSize: this.state.font.fontSize + "px",
                                        fontFamily: this.state.font.fontFamily,
                                        color: this.state.font.fontColor,
                                        fontWeight: this.state.font.fontWeight ? "600" : "400",
                                        fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                        textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                        textAlign: this.state.font.textAlign,
                                        lineHeight: this.state.font.lineHeight,
                                        height: this.state.autoResize ? this.state.textarea.height + "px" : this.state.font.readOnly ? (parseInt(this.state.font.fontSize) + 20) + "px" : this.state.font.elHeight + "px",
                                        // width: this.state.font.readOnly ? "fit-content" : "",
                                        width: this.state.font.readOnly && this.state.content !== '[Recepient Name]' ? "fit-content" : this.state.font.elWidth + "px",
                                    }}
                                    onMouseDown={this.handleClicks}
                                    onMouseUp={this.handleResize}
                                    onChange={this.handleSendToParent}
                                    value={this.state.content}
                                    readOnly={this.state.notDraggable ? this.state.font.readOnly : true}
                                />
                            </div>
                            :
                            <div>
                                {this.state.font.readOnly && (
                                    <span className="hide" id={"span" + this.state.font.timeStamp}
                                        style={{
                                            fontSize: this.state.font.fontSize + "px",
                                            fontFamily: this.state.font.fontFamily,
                                            fontWeight: this.state.font.fontWeight ? "600" : "400",
                                            fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                            textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                            lineHeight: this.state.font.lineHeight,
                                        }}
                                    >{this.state.content}</span>
                                )}
                                {this.state.font.span !== undefined && this.state.font.span !== false && (
                                    <span className='field-label' style={{ textAlign: this.state.font.textAlign }}>{this.state.font.span}</span>
                                )}
                                {
                                    this.state.font.span === "URL" && !this.state.notDraggable ?
                                        <input type={this.state.font.dataType}
                                            name='arrow-controls'
                                            placeholder={this.state.font.placeholder}
                                            style={{
                                                fontSize: this.state.font.fontSize + "px",
                                                fontFamily: this.state.font.fontFamily,
                                                color: this.state.font.fontColor,
                                                fontWeight: this.state.font.fontWeight ? "600" : "400",
                                                fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                                textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                                textAlign: this.state.font.textAlign,
                                                lineHeight: this.state.font.lineHeight,
                                                height: this.state.autoResize ? this.state.textarea.height + "px" : this.state.font.readOnly ? "auto" : this.state.font.elHeight + "px",
                                                width: this.state.font.readOnly ? this.state.width : "",
                                            }}
                                            onMouseDown={this.handleClicks}
                                            onMouseUp={this.handleResize}
                                            value={this.state.content.split("@")[0]}
                                            readOnly
                                        />
                                        :
                                        <input type={this.state.font.dataType}
                                            placeholder={this.state.font.placeholder}
                                            name='arrow-controls'
                                            style={{
                                                fontSize: this.state.font.fontSize + "px",
                                                fontFamily: this.state.font.fontFamily,
                                                color: this.state.font.fontColor,
                                                fontWeight: this.state.font.fontWeight ? "600" : "400",
                                                fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                                textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                                textAlign: this.state.font.textAlign,
                                                lineHeight: this.state.font.lineHeight,
                                                height: this.state.autoResize ? this.state.textarea.height + "px" : this.state.font.readOnly ? "auto" : this.state.font.elHeight + "px",
                                                width: this.state.font.readOnly ? this.state.width : "",
                                            }}
                                            onMouseDown={this.handleClicks}
                                            onMouseUp={this.handleResize}
                                            onChange={e => { this.handleSendToParent(e); this.updateValidity(e) }}
                                            value={this.state.content}
                                            readOnly={this.state.font.dataType === "date" ? !this.state.controls : this.state.font.readOnly}
                                            min={this.state.font.dataType === "date" ? new Date().toISOString().split('T')[0] : ""}
                                        />
                                }
                                {
                                    this.state.font.type === 'validity' && this.state.controls && (<p id='validity-days'>{this.state.validityDays} Days</p>)
                                }
                            </div>
                    }
                    {!this.state.font.readOnly && this.state.controls && this.state.font.dataType === "textarea" && (
                        <div className='content-controls'>
                            {/* <div className='container'>
                                <input type="text" placeholder='Tag Name' />
                                <button><i className="fa-solid fa-plus"></i></button>
                            </div> */}
                            <button onClick={(e) => { this.props.openTagContents(this.state.content) }}><i className="fa-regular fa-floppy-disk"></i> Save&nbsp;Content</button>
                        </div>
                    )}
                </div>
            </Draggable>
        );
    }
}

export default ElementComponent;