import React from 'react';
import './css/element.css';
import axios from 'axios';

import userImage from '../images/defaultorganisation.png';

import Warning from './warning';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ProgressLoader from '../components/progress_loader';

import countryCodes from '../data/country_codes'

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            org_name: '',
            sector: '',
            phone: '',
            linkedin: '',
            adl1: '',
            adl2: '',
            country: '',
            pincode: '',
            domain: '',
            promo_code: '',
            inviteeEmail: '',
            organisers: [],
            organiserSettings: false,
            join_requests: [],
            noAccess: true,
            file: null,
            verification_file: null,
            organisationLogo: false,
            shareModal: false,
            imagePreviewUrl: '',
            refreshKey: 0,
            profileImageIcon: userImage,
            progress_loader: false,
            selectedCountryCode: '+91',
            changesDetected: false,
            running_plan: 'free',
            transferModal: false,
            transferTo: '',
            transferToValid: false,
        };
        this.cropperRef = React.createRef();
    }

    handleVerificationFileChange = (e) => {
        this.setState({ verification_file: e.target.files[0] });
    };

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    handleVerificationSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            progress_loader: true,
        })
        var imageTimestamp = Date.now();
        const formData = new FormData();
        if (this.state.verification_file) {
            (formData.append("file", this.state.verification_file, imageTimestamp + '.pdf'));
            axios.post(this.props.node_server_axios + 'upload_verification_file', formData, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            }).then(res => {
                if (res.data.message !== 'success') {
                    this.setState({
                        warning: {
                            message: res.data.message,
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Verification documents for organisation submitted",
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState({
                        warning: {
                            message: 'Verification document uploaded',
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        verification_file: null,
                        verified: 'pending',
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Please choose a pdf file to upload",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    };

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'organisation_info', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            axios.post(this.props.node_server_axios + 'get_organisation_data', {
                params: {
                    org_id: this.props.org_id,
                }
            }).then((res) => {
                if (res.data !== undefined && res.data.message === 'success') {
                    this.setState({
                        running_plan: res.data.running_plan,
                    });
                } else {
                    window.location = "/signin";
                }
            })
            if (res.data.length > 0) {
                this.setState({
                    org_name: res.data[0].org_name,
                    sector: res.data[0].sector || '',
                    linkedin: res.data[0].linkedin || '',
                    phone: res.data[0].phone === null ? '' : res.data[0].phone.split("-").pop(),
                    selectedCountryCode: res.data[0].phone === null ? '' : res.data[0].phone.split("-")[0],
                    adl1: res.data[0].adl1 || '',
                    adl2: res.data[0].adl2 || '',
                    country: res.data[0].country || '',
                    pincode: res.data[0].pincode || '',
                    last_reset: res.data[0].last_reset,
                    domain: res.data[0].domain,
                    join_requests: res.data[0].join_requests,
                    organisers: res.data[0].organisers,
                    inviteeEmail: '',
                    verified: res.data[0].verified,
                    noAccess: false,
                })

                fetch("https://pronetin-new.s3.amazonaws.com/organisation/" + res.data[0].domain + "/ogranisationLogo.png?cacheblock=" + Date.now())
                    .then((response) => {
                        if (response.ok) {
                            this.setState({
                                organisationLogo: true,
                                profileImageIcon: "https://pronetin-new.s3.amazonaws.com/organisation/" + res.data[0].domain + "/ogranisationLogo.png?cacheblock=" + Date.now(),
                            })
                        } else {
                            this.setState({
                                organisationLogo: false,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                if (this.props.user_type === 'organisation') {
                    this.setState({
                        noAccess: true,
                    })
                } else {
                    window.location = '/';
                }
            }
        });

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if (this.state.changesDetected) {
            event.preventDefault();
            const customMessage = 'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = customMessage;
        }
    };

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    changePhone = (e) => {
        this.setState({
            phone: e.target.value,
            changesDetected: true,
        })
    }
    changeName = (e) => {
        this.setState({
            org_name: e.target.value,
        })
    }
    changeSector = (e) => {
        this.setState({
            sector: e.target.value,
            changesDetected: true,
        })
    }
    changeAdl1 = (e) => {
        this.setState({
            adl1: e.target.value,
            changesDetected: true,
        })
    }
    changeAdl2 = (e) => {
        this.setState({
            adl2: e.target.value,
            changesDetected: true,
        })
    }
    changeCountry = (e) => {
        this.setState({
            country: e.target.value,
            changesDetected: true,
        })
    }
    changeLinkedin = (e) => {
        this.setState({
            linkedin: e.target.value,
            changesDetected: true,
        })
    }
    changePincode = (e) => {
        this.setState({
            pincode: e.target.value,
            changesDetected: true,
        })
    }
    changeInviteeEmail = (e) => {
        this.setState({
            inviteeEmail: e.target.value,
        })
    }
    changePromoCode = (e) => {
        this.setState({
            promo_code: e.target.value,
        })
    }
    handleCountryCodeChange = (e) => {
        this.setState({
            selectedCountryCode: e.target.value,
            changesDetected: true,
        });
    };

    updateProfile = () => {
        axios.post(this.props.node_server_axios + 'update_organisation_info', {
            params: {
                sector: this.state.sector,
                linkedin: this.state.linkedin,
                phone: this.state.selectedCountryCode + "-" + this.state.phone,
                adl1: this.state.adl1,
                adl2: this.state.adl2,
                country: this.state.country,
                pincode: this.state.pincode,
            },
        }).then(res => {
            if (res.data.message === 'updated') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Organisation profile updated",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState({
                    warning: {
                        message: "Profile Updated.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    changesDetected: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            }
        });
    }

    requestNameChange = () => {
        let currentDate = new Date();
        let year = currentDate.getFullYear().toString();
        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        let day = currentDate.getDate().toString().padStart(2, '0');
        let formattedDate = year + month + day;

        if (this.state.last_reset === null || parseInt(formattedDate) - parseInt(this.state.last_reset) > 90) {
            axios.post(this.props.node_server_axios + 'update_organisation_name', {
                params: {
                    org_name: this.state.org_name,
                    last_reset: formattedDate,
                },
            }).then(res => {
                if (res.data.message === 'updated') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Organisation name updated to " + this.state.org_name,
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState({
                        warning: {
                            message: "Organisation Name Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                    this.props.fetchDataUpdate({ type: "org_name", value: this.state.org_name });
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Sorry, Name can be change only after " + (90 - (parseInt(formattedDate) - parseInt(this.state.last_reset))) + " days.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    toggleAllOrganisers = (event) => {
        event.preventDefault();
        this.setState({
            organiserSettings: !this.state.organiserSettings,
        })
    }

    rejectJoinRequest = (request_email) => {
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'reject_join_request', {
            params: {
                email: request_email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Join request from " + request_email + " was rejected",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState({
                    warning: {
                        message: request_email + " has been rejected.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    join_requests: this.state.join_requests.filter(element => element !== request_email),
                    progress_loader: false,
                })
                this.removeNotificationByAction(request_email + " would like to join");
            }
        });
    }

    acceptJoinRequest = (request_email) => {
        this.setState({
            progress_loader: true,
        });
        if (this.state.organisers !== null && this.state.organisers.includes(request_email)) {
            axios.post(this.props.node_server_axios + 'reject_join_request', {
                params: {
                    email: request_email,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: request_email + " was already added to this organisation.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        join_requests: this.state.join_requests.filter(element => element !== request_email),
                        progress_loader: false,
                    })
                }
            });
        } else {
            axios.post(this.props.node_server_axios + 'accept_join_request', {
                params: {
                    email: request_email,
                },
            }).then(res => {
                console.log(res.data.message);
                if (res.data.message === 'success') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Join request from " + request_email + " was accepted",
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState({
                        warning: {
                            message: request_email + " has been accepted.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        join_requests: this.state.join_requests.filter(element => element !== request_email),
                        organisers: this.state.organisers !== null && this.state.organisers !== '' ? this.state.organisers.push(request_email) : [request_email],
                        progress_loader: false,
                    })
                    this.removeNotificationByAction(request_email + " would like to join");
                } else {
                    this.setState({
                        warning: {
                            message: "This user cannot join your organisation.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        join_requests: this.state.join_requests.filter(element => element !== request_email),
                        progress_loader: false,
                    })
                }
            });
        }
    }

    requestInvitee = () => {
        this.setState({
            progress_loader: true,
        });
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(this.state.inviteeEmail)) {
            this.setState({
                warning: {
                    message: "Invalid organiser email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
            return null;
        }
        axios.post(this.props.node_server_axios + 'request_invitee', {
            params: {
                email: this.state.inviteeEmail,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Invitation request to join organisation has been send to " + this.state.inviteeEmail,
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                this.setState({
                    warning: {
                        message: "Invitation to " + this.state.inviteeEmail + " has been send.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    inviteeEmail: '',
                    progress_loader: false,
                })
            } else if (res.data.message === 'user does not exist') {
                this.setState({
                    warning: {
                        message: "User does not exist.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    requestInviteModal: true,
                    progress_loader: false,
                })
            } else if (res.data.message === 'invitation already send') {
                this.setState({
                    warning: {
                        message: "Invitation already send to this user.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'already in organisation') {
                this.setState({
                    warning: {
                        message: "User already in organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'user requested') {
                this.setState({
                    warning: {
                        message: "User has already requested to join this organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    deleteOrganiser = (request_email) => {
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'remove_organiser', {
            params: {
                email: request_email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: request_email + " has been removed from organisation",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
                let updatedOrganisers = this.state.organisers;
                let index = updatedOrganisers.indexOf(request_email);
                if (index !== -1) {
                    updatedOrganisers.splice(index, 1);
                }
                this.setState({
                    warning: {
                        message: request_email + " has been removed.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    join_requests: this.state.join_requests !== null && this.state.join_requests !== '' ? this.state.join_requests.filter(element => element !== request_email) : [],
                    organisers: updatedOrganisers,
                    progress_loader: false,
                })
            }
        });
    }

    toggleSharePanel = () => {
        this.setState({
            shareModal: !this.state.shareModal,
        })
    }

    handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        // check file type
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.setState({
                warning: {
                    message: "Please select a JPG or PNG image file.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        } else if (file.size / 1024 > 3000) {
            this.setState({
                warning: {
                    message: "Max file size allowed is 3Mb.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        }

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };


    handleSubmit = async (e) => {
        this.setState({
            progress_loader: true,
        })
        e.preventDefault();
        let reader = new FileReader();
        const cropper = this.cropperRef.current.cropper;
        if (!cropper) {
            return;
        }

        const croppedCanvas = cropper.getCroppedCanvas();
        croppedCanvas.toBlob((blob) => {
            const croppedFile = new File([blob], this.state.domain + '.png', { type: 'image/png' });
            this.setState({ file: croppedFile });
            const file = croppedFile;
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('image', file);
            axios.post(this.props.node_server_axios + 'update_organisation_logo', formData
            ).then(res => {
                if (res.data.message === 'updated') {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Organisation logo updated",
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState((prevState) => ({
                        warning: {
                            message: "Organisation Logo Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        shareModal: false,
                        refreshKey: prevState.refreshKey + 1,
                        profileImageIcon: reader.result,
                        progress_loader: false,
                    }))
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        }, 'image/png');
    };

    applyPromo = () => {
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    org_id: res.data.org_id,
                });
                axios.post(this.props.node_server_axios + 'apply_promo', {
                    params: {
                        email: res.data.email,
                        user_type: res.data.user_type,
                        org_id: res.data.org_id,
                        promo_code: this.state.promo_code,
                    }
                }).then((res) => {
                    if (res.data !== undefined && res.data.message === 'success') {
                        //---------------------Logging Data (Organisation) Start---------------------
                        axios.post(this.props.node_server_axios + 'update_organisation_log', {
                            params: {
                                email: this.props.email,
                                dateTS: this.logFormattedDate(new Date()),
                                creditsConsumed: '+' + this.state.promo_code.match(/\d+$/)[0],
                                content: "Promo Code: " + this.state.promo_code + " was applied",
                            },
                        })
                        //----------------------Logging Data (Organisation) End----------------------
                        this.setState({
                            warning: {
                                message: res.data.value,
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            }
                        })
                        this.props.fetchDataUpdate({ type: "credits", value: res.data.new_credits });
                    } else if (res.data !== undefined) {
                        this.setState({
                            warning: {
                                message: res.data.value,
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            }
                        })
                    }
                })
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })
    }

    handleCopyToClipboard = (event, index) => {
        const text = event.target.closest(".copy-container").querySelector(".no-view-copy").textContent;

        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            copied: true,
            copiedIndex: index,
            warning: {
                message: "Organisation ID copied !",
                type: "success",
                learnmore: false,
                autoclose: true,
            }
        });
        setTimeout(() => {
            this.setState({ copied: false, copiedIndex: false });
        }, 2000);
    }

    deleteOrganisationPermanently = () => {
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'delete_organisation_permanently').then(async res => {
            if (res.data.message === 'organisation deleted') {
                await axios.post(this.props.node_server_axios + 'force_update_session');
                window.location = '/';
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    transferAdminPermanently = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.transferTo !== this.props.email && this.state.transferToValid) {
            axios.post(this.props.node_server_axios + 'transfer_admin_permanently', {
                params: {
                    transferTo: this.state.transferTo,
                },
            }).then(async res => {
                if (res.data.message === 'user transfered') {
                    await axios.post(this.props.node_server_axios + 'force_update_session');
                    window.location = '/';
                } else if (res.data.message === 'user not found') {
                    this.setState({
                        warning: {
                            message: "No user found at this email address.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else if (res.data.message === 'user in organisation') {
                    this.setState({
                        warning: {
                            message: "This user is already part of an organisation.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Cannot transfer to this email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    toggleTransferModal = (e) => {
        this.setState({
            transferModal: !this.state.transferModal,
        })
    }

    updateTransferToEmail = (e) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.setState({
            transferTo: e.target.value,
            transferToValid: emailRegex.test(e.target.value),
        })
    }

    removeNotificationByAction = (keyword) => {
        axios.post(this.props.node_server_axios + 'delete_notification_by_action', {
            params: {
                keyword: keyword,
            }
        });
    }

    toggleRequestInviteModal = () => {
        this.setState({
            requestInviteModal: !this.state.requestInviteModal,
        })
    }

    inviteToPronetin = () => {
        this.setState({
            progress_loader: true,
            requestInviteModal: false,
        })
        axios.post(this.props.node_server_axios + 'invite_user_to_pronetin', {
            params: {
                email: this.props.email,
                invitee: this.state.inviteeEmail,
            },
        }).then(async res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "User has been invited via email to Pronetin.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    inviteeEmail: '',
                    progress_loader: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    render() {
        let { imagePreviewUrl } = this.state;

        return (
            <React.Fragment>
                {this.state.requestInviteModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleRequestInviteModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Invite User</h1>
                            </div>
                            <p>This user is not registered with Rronetin. Proceed to invite user to Pronetin</p>
                            <button type="button" onClick={this.inviteToPronetin}>Invite <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                )}
                {this.state.noAccess ?
                    <div className="element">
                        <div className="container">
                            <div className='header'>
                                <div className='container-left'>
                                    <h1>Edit<label>Organisation profile</label></h1>
                                </div>
                                <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                            </div>
                            <div className='tiles settings'>
                                <div className='sub-nav'>
                                    <div className='profile-storage'>
                                        Plan Details
                                        <label>{this.state.running_plan.replace("-y", "")}<b>plan</b></label>
                                        <p className='plan-info'>{this.state.running_plan.includes("-y") ? "Yearly Subscription" : "Monthly Subscription"}</p>
                                        <br></br>
                                        <a href='./premium' className='button'>Upgrade Plan <i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>
                                <div className='wrapper'>
                                    <div className='column-container'>
                                        <h1>No Access</h1>
                                        <div className='row-container'>
                                            <p>Only admin of this organisation have access to this page.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="element">
                        <div className="container settings-container">
                            <div className='header'>
                                <div className='container-left'>
                                    <h1>Edit<label>Organisation profile</label></h1>
                                </div>
                                <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                            </div>
                            <div className='tiles settings'>
                                {this.state.shareModal && (
                                    <div className='element-modal'>
                                        <div className='content'>
                                            <button className='close-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-x"></i></button>
                                            <div className='container'>
                                                <h1>Upload Image</h1>
                                            </div>
                                            {imagePreviewUrl ?
                                                <Cropper
                                                    style={{ height: 400, width: "100%" }}
                                                    ref={this.cropperRef}
                                                    src={imagePreviewUrl}
                                                    aspectRatio={1} // Square shape
                                                    guides={true}
                                                    dragMode="crop"
                                                />
                                                :
                                                <p>Choose your organisation logo</p>
                                            }
                                            <div className='container'>
                                                <input type='file' placeholder='Email' accept=".jpg,.png,.jpeg" onChange={this.handleImageChange} />
                                                <button type="button" onClick={this.handleSubmit}>Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.transferModal && (
                                    <div className='element-modal transfer-modal'>
                                        <div className='content'>
                                            <button className='close-btn' onClick={this.toggleTransferModal}><i className="fa-solid fa-x"></i></button>
                                            <div className='container'>
                                                <h1>Transfer Organisation Ownership To</h1>
                                            </div>
                                            <form className='container'>
                                                <input type='text' placeholder='Email' value={this.state.transferTo} onChange={this.updateTransferToEmail} required />
                                                {this.state.transferToValid && (<p className='email-validated'><i className="fa-solid fa-circle-check"></i> Email validated</p>)}
                                                <p>Transferring organisation ownership will move all admin privilages to the above account. Current account will be removed from organisation. This action cannot be reverted.</p>
                                                <button type="button" onClick={this.transferAdminPermanently}>Transfer <i className="fa-solid fa-arrow-right"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                <div className='sub-nav'>
                                    <div className='profile-storage'>
                                        Plan Details
                                        <label>{this.state.running_plan.replace("-y", "")}<b>plan</b></label>
                                        <p className='plan-info'>{this.state.running_plan.includes("-y") ? "Yearly Subscription" : "Monthly Subscription"}</p>
                                        <br></br>
                                        <a href='./premium' className='button'>Upgrade Plan <i className="fa-solid fa-plus"></i></a>
                                        <br></br>
                                        Join Requests
                                        {this.state.join_requests !== null && this.state.join_requests.length > 0 ?
                                            this.state.join_requests.map((element, index) =>
                                                <div className='join-req-tile' key={index}>
                                                    <p>{element}</p>
                                                    <div className='action-btns'>
                                                        <button className='accept' onClick={() => this.acceptJoinRequest(element)}><i className="fa-solid fa-circle-check"></i></button>
                                                        <button className='reject' onClick={() => this.rejectJoinRequest(element)}><i className="fa-solid fa-circle-xmark"></i></button>
                                                    </div>
                                                </div>
                                            ) :
                                            <p>No requests available</p>
                                        }
                                        <br></br>
                                        {!this.state.organiserSettings ?
                                            <button onClick={this.toggleAllOrganisers}>Manage Organisers <i className="fa-solid fa-user-plus"></i></button>
                                            :
                                            <button onClick={this.toggleAllOrganisers}>Settings <i className="fa-solid fa-gear"></i></button>
                                        }
                                    </div>
                                </div>
                                {this.state.organiserSettings ?
                                    <div className='wrapper'>
                                        <div className='column-container'>
                                            <a href='foo' className='view-btn' onClick={this.toggleAllOrganisers}><i className="fa-solid fa-arrow-left"></i> Settings</a>
                                        </div>
                                        <div className='column-container'>
                                            <p>Invite Organiser</p>
                                            <div className='row-container'>
                                                <input type='text' placeholder='Email Id' value={this.state.inviteeEmail} onChange={this.changeInviteeEmail} />
                                                <button onClick={this.requestInvitee}>Invite</button>
                                            </div>
                                        </div>
                                        <div className='column-container'>
                                            <p>Organiser List</p>
                                            <div className='row-container'>
                                                <table className='organiser-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Email</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.organisers !== null && this.state.organisers.length > 0 && (this.state.organisers.map((element, index) =>
                                                            <tr key={index}>
                                                                <td>{element}</td>
                                                                <td><button className='del-btn' onClick={() => this.deleteOrganiser(element)}>Remove&nbsp;<i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='wrapper'>
                                        <div className='row-container'>
                                            <div className='profile-icon-edit'>
                                                <img key={this.state.refreshKey} src={this.state.profileImageIcon} alt="user icon"></img>
                                                <button className='edit-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-pen"></i></button>
                                            </div>
                                            <div className='column-container'>
                                                <p>{this.state.org_name}</p>
                                                <div className='copy-container'>
                                                    <p className='no-view-copy'>{this.props.org_id}</p>
                                                    <p onClick={this.handleCopyToClipboard}>Org. ID:<b><i className="fa-solid fa-asterisk"></i><i className="fa-solid fa-asterisk"></i><i className="fa-solid fa-asterisk"></i>-<i className="fa-solid fa-asterisk"></i><i className="fa-solid fa-asterisk"></i>-{this.props.org_id.split("-").pop()}</b><span><i className="fa-regular fa-copy"></i></span></p>
                                                </div>
                                                <h1>{this.state.organisers !== null ? this.state.organisers.length : 0} Organisers</h1>
                                                <a href='foo' className='view-btn' onClick={this.toggleAllOrganisers}>View all <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Organisation Name</p>
                                            <div className='row-container'>
                                                <input className='gray' type='text' placeholder='Organisation Name' name='name' value={this.state.org_name} onChange={this.changeName}></input>
                                                <button onClick={this.requestNameChange}>Request Change</button>
                                            </div>
                                            <p className='info'>Name can be only changed once in 90 days</p>
                                            {this.state.verified !== 'verified' ?
                                                <div className='verification-container'>
                                                    {this.state.verified !== 'pending' ? <p className='warn'>This organisation is not yet verified</p> : <p className='warn'>Your account will be verified in 1-3 business days after analysis of the document submitted</p>}
                                                    <p>Verification will provide</p>
                                                    <ul>
                                                        <li>Pronetin Verified status to all issued certificate</li>
                                                        <li>Provides validity to certificate</li>
                                                        <li>Organisation Name will not have duplicates</li>
                                                    </ul>
                                                    {this.state.verified !== 'pending' && (
                                                        <div className='row-container'>
                                                            <input type='file' name='incorporation_certificate' accept="application/pdf" onChange={this.handleVerificationFileChange} />
                                                            <button type='submit' onClick={this.handleVerificationSubmit}>Upload Document</button>
                                                        </div>
                                                    )}
                                                    {this.state.verified !== 'pending' && (<p className='info'>To verify your organisation please upload the incorporation certificate (.pdf)</p>)}
                                                </div>
                                                :
                                                <div className='verified-doc-badge'>
                                                    <i className="fa-solid fa-building-circle-check"></i> Pronetin Verified Organisation
                                                </div>
                                            }
                                        </div>

                                        <div className='column-container'>
                                            <p>Sector</p>
                                            <div className='row-container'>
                                                <input type='text' placeholder='Sector' name='designation' value={this.state.sector} onChange={this.changeSector}></input>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Organisation Contact</p>
                                            <div className='row-container'>
                                                <select
                                                    value={this.state.selectedCountryCode}
                                                    onChange={this.handleCountryCodeChange}
                                                >
                                                    <option value="" disabled>Select Country Code</option>
                                                    {countryCodes.map((country) => (
                                                        <option key={country.code} value={country.code}>
                                                            {`${country.name} (${country.code})`}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input type='text' placeholder='Phone' name='phone' value={this.state.phone} onChange={this.changePhone}></input>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Organisation Social</p>
                                            <div className='row-container'>
                                                <input type='text' placeholder='Linkedin URL' name='linkedin' value={this.state.linkedin} onChange={this.changeLinkedin}></input>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Address</p>
                                            <div className='row-container'>
                                                <input type='text' placeholder='Address Line 1' name='adl1' value={this.state.adl1} onChange={this.changeAdl1}></input>
                                                <input type='text' placeholder='Address Line 2' name='adl2' value={this.state.adl2} onChange={this.changeAdl2}></input>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <div className='row-container'>
                                                <input type='text' placeholder='Country' name='country' value={this.state.country} onChange={this.changeCountry}></input>
                                                <input type='text' placeholder='Pin Code' name='pincode' value={this.state.pincode} onChange={this.changePincode}></input>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Registered Domain</p>
                                            <div className='row-container'>
                                                <input className='gray' type='text' placeholder='domain.com' value={this.state.domain} name='domain' readOnly></input>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Offers</p>
                                            <div className='row-container'>
                                                <input type='text' placeholder='Promo Code' name='promoCode' onChange={this.changePromoCode}></input>
                                                <button onClick={this.applyPromo}>Redeem</button>
                                            </div>
                                        </div>

                                        <div className='row-container danger-zone'>
                                            <p>Warning</p><hr></hr>
                                        </div>

                                        <div className='column-container'>
                                            <p>Delete Organisation</p>
                                            <div className='row-container'>
                                                <p className='text'>Once deleted, it will be gone forever.<br></br>Please be certain.</p>
                                                <button className='red-btn' onClick={this.deleteOrganisationPermanently}>Delete Organisation</button>
                                            </div>
                                        </div>

                                        <div className='column-container'>
                                            <p>Transfer Organisation</p>
                                            <div className='row-container'>
                                                <p className='text'>Once transfered, it will only be linked to new account.<br></br>Please be certain.</p>
                                                <button className='red-btn' onClick={this.toggleTransferModal}>Transfer Organisation</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                                }
                            </div>
                            <div className='apply-btn'>
                                <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> <p>Save</p></button>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;