import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';

// import isReachable from 'is-reachable';
import ProgressLoader from '../components/progress_loader';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            invitations: [],
            organisationId: '',
            organisationName: '',
            domainName: '',
            domainChecks: {
                format: false,
                active: false,
                emailmatch: false,
            },
            verificationStatus: {
                domain: false,
                date: false,
                time: false,
                status: false,
                message: false,
            },
            noWorkEmail: false,
            progress_loader: true,
            existing_org_data: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    changeOrganisationId = (e) => {
        this.setState({
            organisationId: e.target.value,
        })
    }

    changeOrganisationName = (e) => {
        this.setState({
            organisationName: e.target.value,
        })
    }

    changeDomainName = (e) => {
        this.setState({
            domainName: e.target.value,
        })
    }

    sendJoinRequest = () => {
        this.setState({
            progress_loader: true,
        });
        function isUUID(str) {
            const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
            return uuidRegex.test(str);
        }

        if (isUUID(this.state.organisationId)) {
            if (!this.state.invitations.some(org => org.org_id === this.state.organisationId)) {
                axios.post(this.props.node_server_axios + 'send_join_request', {
                    params: {
                        email: this.props.email,
                        org_id: this.state.organisationId,
                    },
                }).then(res => {
                    if (res.data.message === 'request send') {
                        this.setState({
                            warning: {
                                message: "Request send to organisation (" + res.data.organisation + ").",
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            organisationId: '',
                            progress_loader: false,
                        })
                    } else if (res.data.message === 'no organisation') {
                        this.setState({
                            warning: {
                                message: "Sorry, Organisation does not exist.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                            progress_loader: false,
                        })
                    } else if (res.data.message === 'already send') {
                        this.setState({
                            warning: {
                                message: "Request already send to this organisation.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                            progress_loader: false,
                        })
                    } else {
                        this.setState({
                            warning: {
                                message: "Sorry, Failed to send join request.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                            progress_loader: false,
                        })
                    }
                });
            } else {
                this.setState({
                    warning: {
                        message: "Invitation from this organisation already exist in your account.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        } else {
            this.setState({
                warning: {
                    message: "Invalid Organisation ID.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    componentDidMount() {
        if (this.props.user_type === 'organisation') {
            window.location = "/settings";
        }
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_invitation_list', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                invitations: res.data.length > 0 ? res.data : [],
                progress_loader: false,
            })
        });

        axios.post(this.props.node_server_axios + 'get_manual_verification_status', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message !== 'failed') {
                this.setState({
                    verificationStatus: {
                        domain: res.data.domain,
                        date: res.data.date,
                        time: res.data.time,
                        status: res.data.status,
                        message: res.data.message,
                    },
                    noWorkEmail: false,
                })
            } else {
                this.setState({
                    noWorkEmail: true,
                })
            }
        });
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    rejectOrganisation = (org_id) => {
        this.setState({
            progress_loader: true,
        });
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'reject_organisation', {
            params: {
                org_id: org_id,
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    invitations: this.state.invitations.filter(element => element.org_id !== org_id),
                    warning: {
                        message: "Removed invitation request.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
                this.removeNotificationByAction("join their organisation");
            }
        });
    }

    acceptOrganisation = (org_id) => {
        this.setState({
            progress_loader: true,
        });
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'accept_organisation', {
            params: {
                org_id: org_id,
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                // this.setState({
                //     invitations: [],
                //     warning: {
                //         message: "Invitation accepted.",
                //         type: "success",
                //         learnmore: false,
                //         autoclose: true,
                //     }
                // })
                this.removeNotificationByAction("join their organisation");
                window.location = './organisation'
            } else {
                this.setState({
                    progress_loader: false,
                })
            }
        });
    }

    getDomainFromEmail(email) {
        const regex = /@([A-Za-z0-9-]+\.[A-Za-z0-9-]+)/;
        const match = email.match(regex);
        if (match) {
            return match[1];
        } else {
            return null;
        }
    }

    createOrganisation = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'create_organisation', {
            params: {
                org_name: this.state.organisationName,
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                window.location = './settings';
            } else if (res.data.message === 'invalid domain') {
                this.setState({
                    invitations: [],
                    warning: {
                        message: "Sign In from an account with work email to create an organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'already registered') {
                this.setState({
                    invitations: [],
                    warning: {
                        message: "Organisation already registered.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'deleted organisation') {
                this.setState({
                    invitations: [],
                    existing_org_data: res.data.org_data,
                    progress_loader: false,
                })
            } else {
                this.setState({
                    invitations: [],
                    warning: {
                        message: "Failed to create Organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    checkEmailToDomainValidity = async () => {
        //Check url validity
        const websiteFormatRegex = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        if (websiteFormatRegex.test(this.state.domainName)) {
            this.setState({
                domainChecks: {
                    // 3 Stage checking
                    // format: 1,
                    // active: false,
                    // emailmatch: false,
                    format: 1,
                    active: 1,
                    emailmatch: 1,
                }
            })
        } else {
            this.setState({
                domainChecks: {
                    format: 0,
                    active: false,
                    emailmatch: false,
                },
                warning: {
                    message: "This domain or email does not meet the requirements, please contact us at help@pronetin.com",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                }
            })
            return null
        }

        //Check url response
        // try {
        //     const reachable = await isReachable(this.state.domainName);
        //     if (reachable) {
        //         this.setState({
        //             domainChecks: {
        //                 format: 1,
        //                 active: 1,
        //                 emailmatch: false,
        //             }
        //         })
        //     } else {
        //         this.setState({
        //             domainChecks: {
        //                 format: 1,
        //                 active: 0,
        //                 emailmatch: false,
        //             },
        //             warning: {
        //                 message: "This domain or email does not meet the requirements, please contact us at help@pronetin.com",
        //                 type: "warning",
        //                 learnmore: false,
        //                 autoclose: false,
        //             }
        //         })
        //         return null
        //     }
        // } catch (error) {
        //     console.log(error);
        //     this.setState({
        //         domainChecks: {
        //             format: 1,
        //             active: 0,
        //             emailmatch: false,
        //         },
        //         warning: {
        //             message: "This domain or email does not meet the requirements, please contact us at help@pronetin.com",
        //             type: "warning",
        //             learnmore: false,
        //             autoclose: false,
        //         }
        //     })
        //     return null
        // }

        //Check similarity of email and domain
        // const d1 = this.state.domainName.split(".")[1];
        // const d2 = this.props.email.match(/^([^@]+)/)[1];

        // if (d2.includes(d1)) {
        //     this.setState({
        //         domainChecks: {
        //             format: 1,
        //             active: 1,
        //             emailmatch: 1,
        //         }
        //     })
        // } else {
        //     this.setState({
        //         domainChecks: {
        //             format: 1,
        //             active: 1,
        //             emailmatch: 0,
        //         },
        //         warning: {
        //             message: "This domain or email does not meet the requirements, please contact us at help@pronetin.com",
        //             type: "warning",
        //             learnmore: false,
        //             autoclose: false,
        //         }
        //     })
        //     return null
        // }

        //Axios request to forward for manual verification
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'manual_verification_request', {
            params: {
                domain: this.state.domainName.split("www.").pop(),
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    invitations: [],
                    warning: {
                        message: "Request send for verification, we will contact you soon.",
                        type: "success",
                        learnmore: false,
                        autoclose: false,
                    },
                    verificationStatus: {
                        domain: this.state.domainName.split("www.").pop(),
                        status: "pending",
                        message: "Pending manual verification, we will contact you soon",
                    },
                    domainChecks: {
                        format: false,
                        active: false,
                        emailmatch: false,
                    },
                    domainName: '',
                    noWorkEmail: false,
                })
            } else {
                this.setState({
                    invitations: [],
                    warning: {
                        message: "This domain or email does not meet the requirements, please contact us at help@pronetin.com",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    }
                })
            }
        })
    }

    toggleExistingOrgModal = (e) => {
        this.setState({
            existing_org_data: false,
        })
    }

    recoverOrganisation = (org_id) => {
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'recover_organisation', {
            params: {
                org_name: this.state.organisationName,
                org_id: org_id,
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                window.location = './settings';
            } else {
                this.setState({
                    invitations: [],
                    warning: {
                        message: "Failed to recover Organisation.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    removeNotificationByAction = (keyword) => {
        axios.post(this.props.node_server_axios + 'delete_notification_by_action', {
            params: {
                keyword: keyword,
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Join / Create Organisation<label>To issue certificates</label></h1>
                            </div>
                        </div>
                        <div className='tiles settings'>
                            {this.state.existing_org_data && (
                                <div className='element-modal recover-modal'>
                                    <div className='content'>
                                        <button className='close-btn' onClick={this.toggleExistingOrgModal}><i className="fa-solid fa-x"></i></button>
                                        <i className="fa-solid fa-box-archive big-icon"></i>
                                        <div className='container'>
                                            <h1>An Archived Organisation Found</h1>
                                        </div>
                                        <p>We have found an organisation that matches your domain which was previously owned by <b>{this.state.existing_org_data.admin}</b>, Would you like to recover it</p>
                                        <button type="button" onClick={() => this.recoverOrganisation(this.state.existing_org_data.org_id)}>Recover <i className="fa-regular fa-circle-check"></i></button>
                                    </div>
                                </div>
                            )}
                            <div className='sub-nav'>
                                <div className='profile-storage'>
                                    Invitations
                                    {this.state.invitations !== null && this.state.invitations.length > 0 ?
                                        this.state.invitations.map((element, index) =>
                                            <div className='join-req-tile' key={index}>
                                                <p>{element.org_name}</p>
                                                <div className='action-btns'>
                                                    <button className='accept' onClick={() => this.acceptOrganisation(element.org_id)}><i className="fa-solid fa-circle-check"></i></button>
                                                    <button className='reject' onClick={() => this.rejectOrganisation(element.org_id)}><i className="fa-solid fa-circle-xmark"></i></button>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <p>No inviataions</p>
                                    }
                                </div>
                            </div>
                            <div className='wrapper'>
                                {this.state.verificationStatus.message && (
                                    <div className='column-container'>
                                        <div className='verification-status-container'>
                                            <h2>Verification Status : <span>{this.state.verificationStatus.status}</span></h2>
                                            <p>Domain : <b>{this.state.verificationStatus.domain}</b></p>
                                            {this.state.verificationStatus.date ?
                                                <p>Sent for verification on <b>{this.state.verificationStatus.date}</b> at <b>{this.state.verificationStatus.time}</b></p>
                                                :
                                                <p>Sent for verification <b>few seconds ago</b></p>
                                            }
                                            <p className='message'>{this.state.verificationStatus.message}</p>
                                        </div>
                                    </div>
                                )}
                                <div className='column-container'>
                                    <p>Join Organisation</p>
                                    <div className='row-container'>
                                        <input type='text' placeholder='Organisation ID' name='designation' value={this.state.organisationId} onChange={(e) => this.changeOrganisationId(e)}></input>
                                        <button onClick={this.sendJoinRequest}>Join</button>
                                    </div>
                                </div>

                                <div className='row-container partition-zone'>
                                    <hr></hr><p>OR</p><hr></hr>
                                </div>

                                <div className='column-container'>
                                    <p>Create Organisation</p>
                                    <div className='row-container'>
                                        <input type='text' placeholder='Organisation Name' name='name' onChange={(e) => this.changeOrganisationName(e)}></input>
                                        <input type='text' placeholder='Domain Name' name='domain' value={this.getDomainFromEmail(this.props.email)} readOnly hidden></input>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <div className='row-container'>
                                        <button onClick={this.createOrganisation}>Create Organisation</button>
                                    </div>
                                </div>

                                {this.state.noWorkEmail && (
                                    <div className='row-container partition-zone'>
                                        <hr></hr><p>OR</p><hr></hr>
                                    </div>
                                )}

                                {this.state.noWorkEmail && (
                                    <div className='column-container'>
                                        <label>If you <span>do not have a work email</span> for your organisation,<br></br>We will try to connect your email <span>{this.props.email}</span> with the below domain</label>
                                        <p>Website / Domain Name</p>
                                        <div className='row-container'>
                                            <input type='text' placeholder='www.myorganisation.com' name='domainName' value={this.state.domainName} onChange={(e) => this.changeDomainName(e)}></input>
                                            <button onClick={this.checkEmailToDomainValidity}>Request</button>
                                        </div>
                                    </div>
                                )}

                                <div className='column-container'>
                                    <div className='check-validity-container'>
                                        {this.state.domainChecks.format !== false && (<p>{this.state.domainChecks.format === 1 ? <i className="fa-regular fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Domain format validation</p>)}
                                        {this.state.domainChecks.active !== false && (<p>{this.state.domainChecks.active === 1 ? <i className="fa-regular fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Active web address</p>)}
                                        {this.state.domainChecks.emailmatch !== false && (<p>{this.state.domainChecks.emailmatch === 1 ? <i className="fa-regular fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Email similarity with domain</p>)}
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment >
        );
    }
}

export default ProfileComponent;