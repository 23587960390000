import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';
import sampleCertificate from '../images/sampleCertificate.png'
import ViewSignee from './viewer/signee';

import WarningAction from '../components/warning_action';
import ProgressLoader from '../components/progress_loader';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            approval_list: [],
            approval_history: [],
            uniqueEvents: [],
            filteredEvent: false,
            filteredEventValue: '',
            recepientData: [],
            recepientDataToggle: false,
            approvalHistory_batch: 3,
            approvalHistory_page: 1,
            approvalHistory_pageLimit: 0,
            templatePreviewModal: false,
            previewImage: null,
            tutorial: true,
            moreBtn: 'more',
            elements: [],
            checkboxStates: {},
            progress_loader: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    componentDidMount = () => {
        this.getApprovalHistory(1);
    }

    getApprovalHistory = (offset) => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_approvals_list', {
            params: {
                email: this.props.email,
                limit: this.state.approvalHistory_batch * offset,
            },
        }).then(res => {
            if (res.data.approval_list) {
                this.setState({
                    approval_list: res.data.approval_list,
                    uniqueEvents: [...new Set(res.data.approval_list.map(cert => JSON.parse(cert).event))],
                    approval_history: res.data.approval_history,
                    approval_history_count: parseInt(res.data.approval_history_count),
                    approvalHistory_pageLimit: Math.ceil(parseInt(res.data.approval_history_count) / this.state.approvalHistory_batch),
                })
            } else if (res.data.approval_history) {
                this.setState({
                    approval_history: res.data.approval_history,
                    approval_history_count: parseInt(res.data.approval_history_count),
                    approvalHistory_pageLimit: Math.ceil(parseInt(res.data.approval_history_count) / this.state.approvalHistory_batch),
                })
            }
        });
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    dummyActionBatch = (status) => {
        if (status === 'accept') {
            this.setState({
                warning: {
                    message: "Certificate batch approved.",
                    type: "success",
                    learnmore: false,
                    autoclose: false,
                },
                tutorial: false,
            })
        } else {
            this.setState({
                warning: {
                    message: "Certificate batch rejected.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                },
                tutorial: false,
            })
        }
    }

    acceptBatch = async (batchId, orgId, orgName) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Sign Batch",
                subtext: "This batch from " + orgName + " will be signed. Do you wish to continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'sign_batch', {
            params: {
                email: this.props.email,
                batch_id: batchId,
                updated_approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                org_id: orgId,
                org_name: orgName,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                    warning: {
                        message: "Certificate batch approved.",
                        type: "success",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            } else if (res.data.message === 'no signature') {
                this.setState({
                    warning: {
                        message: "No signature found to issue this certificate.",
                        type: "warning",
                        learnmore: 'mysignature',
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            } else {
                this.setState({
                    approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                    warning: {
                        message: "This Batch was removed by organiser.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    rejectBatch = async (batchId, orgId, orgName) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Reject Batch",
                subtext: "This batch from " + orgName + " will be rejected. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'reject_batch', {
            params: {
                email: this.props.email,
                batch_id: batchId,
                updated_approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                org_id: orgId,
                org_name: orgName,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                    warning: {
                        message: "Certificate batch rejected.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            } else {
                this.setState({
                    approval_list: this.state.approval_list.filter(cert => JSON.parse(cert).batch_id !== batchId),
                    uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                    warning: {
                        message: "This Batch was removed by organiser.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    showRecepients = (batchId, orgId) => {
        this.setState({
            progress_loader: true,
        });
        axios.post(this.props.node_server_axios + 'get_recepient_list', {
            params: {
                batch_id: batchId,
                org_id: orgId,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    recepientData: JSON.parse(res.data.csvData[0].csv_data),
                    recepientDataToggle: true,
                    progress_loader: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    toggleAllApprovals = (e) => {
        e.preventDefault();
        this.setState({
            recepientData: [],
            recepientDataToggle: false,
        })
    }

    filterEvent = (e, element) => {
        e.preventDefault();
        this.setState({
            filteredEventValue: element,
            filteredEvent: true,
        })
        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    clearFilter = () => {
        this.setState({
            filteredEventValue: '',
            filteredEvent: false,
        })
    }

    approvalHistoryMore = () => {
        this.setState({
            approvalHistory_page: this.state.approvalHistory_page < this.state.approvalHistory_pageLimit ? this.state.approvalHistory_page + 1 : this.state.approvalHistory_page,
        }, () => {
            this.getApprovalHistory(this.state.approvalHistory_page);
        })
    }

    openTemplatePreview = (e, batch_id) => {
        axios.post(this.props.node_server_axios + 'get_batch_data', {
            params: {
                batchId: batch_id,
            },
        }).then(res => {
            this.setState({
                elements: res.data.elements,
            })
        }).catch(err => { console.log(err) })
        this.setState({
            templatePreviewModal: true,
            previewImage: e.target.src,
        })
    }

    openSampleTemplatePreview = (e) => {
        this.setState({
            templatePreviewModal: true,
            previewImage: e.target.src,
        })
    }

    closeTemplatePreview = (e) => {
        if (e.target.classList.contains('batchcert-preview-modal')) {
            this.setState({
                templatePreviewModal: false,
                elements: [],
            })
        }
    }

    updateScale = () => {
        const divWidth = this.divRef.offsetWidth;
        const screenWidth = window.innerWidth - 40;
        const scaleFactor = screenWidth / divWidth;
        if (scaleFactor < 1) {
            this.setState({ scaleFactor });
        }
    };

    handleCheckboxChange = (batchId, orgId, orgName) => {
        this.setState((prevState) => ({
            checkboxStates: {
                ...prevState.checkboxStates,
                [batchId]: {
                    org_id: orgId,
                    org_name: orgName,
                    checked: !prevState.checkboxStates[batchId]?.checked
                }
            }
        }));
    };

    handleSignAllClick = async () => {
        const checkedValues = [];
        for (const batchId in this.state.checkboxStates) {
            if (this.state.checkboxStates[batchId].checked) {
                checkedValues.push({
                    batch_id: batchId,
                    org_id: this.state.checkboxStates[batchId].org_id,
                    org_name: this.state.checkboxStates[batchId].org_name
                });
            }
        }

        let temp_approval_list = this.state.approval_list;

        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Sign selected batches",
                subtext: "All selected batches will be signed. Do you wish to continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });

        checkedValues.forEach(async (element) => {
            temp_approval_list = temp_approval_list.filter(cert => JSON.parse(cert).batch_id !== element.batch_id)
            await axios.post(this.props.node_server_axios + 'sign_batch', {
                params: {
                    email: this.props.email,
                    batch_id: element.batch_id,
                    updated_approval_list: temp_approval_list,
                    org_id: element.org_id,
                    org_name: element.org_name,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        approval_list: temp_approval_list,
                        uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                        progress_loader: false,
                    })
                } else if (res.data.message === 'no signature') {
                    this.setState({
                        warning: {
                            message: "No signature found to issue this certificate.",
                            type: "warning",
                            learnmore: 'mysignature',
                            autoclose: false,
                        },
                        progress_loader: false,
                    })
                    return;
                } else {
                    this.setState({
                        approval_list: temp_approval_list,
                        uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                        progress_loader: false,
                    })
                }
            });
        });

        this.setState({
            warning: {
                message: "Multiple batches signed sucessfully.",
                type: "success",
                learnmore: false,
                autoclose: true,
            },
        })
    };

    handleRejectAllClick = async () => {
        const checkedValues = [];
        for (const batchId in this.state.checkboxStates) {
            if (this.state.checkboxStates[batchId].checked) {
                checkedValues.push({
                    batch_id: batchId,
                    org_id: this.state.checkboxStates[batchId].org_id,
                    org_name: this.state.checkboxStates[batchId].org_name
                });
            }
        }

        let temp_approval_list = this.state.approval_list;

        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Reject selected batches",
                subtext: "All selected batches will be rejected. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        });

        checkedValues.forEach(async (element) => {
            temp_approval_list = temp_approval_list.filter(cert => JSON.parse(cert).batch_id !== element.batch_id)
            await axios.post(this.props.node_server_axios + 'reject_batch', {
                params: {
                    email: this.props.email,
                    batch_id: element.batch_id,
                    updated_approval_list: temp_approval_list,
                    org_id: element.org_id,
                    org_name: element.org_name,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        approval_list: temp_approval_list,
                        uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        approval_list: temp_approval_list,
                        uniqueEvents: [...new Set(this.state.approval_list.map(cert => JSON.parse(cert).event))],
                        progress_loader: false,
                    })
                }
            });
        });

        this.setState({
            warning: {
                message: "Multiple batches rejected.",
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
        })
    };

    render() {
        const { scaleFactor } = this.state;
        const isAtLeastOneCheckboxChecked = Object.values(this.state.checkboxStates).some(
            (checkboxState) => checkboxState.checked
        );

        return (
            <React.Fragment>
                <div className="element">
                    {this.state.templatePreviewModal && (
                        <div className='batchcert-preview-modal' onClick={this.closeTemplatePreview}>
                            <div className='preview-rel' ref={ref => (this.divRef = ref)} style={{ transform: `scale(${scaleFactor})` }} onLoad={this.updateScale}>
                                <img src={this.state.previewImage} alt='certificate template' />
                                {
                                    this.state.elements.length > 0 ?
                                        this.state.elements.map((element, index) => {
                                            if (element.type === "signature") {
                                                return <ViewSignee element_id={"view-" + index} key={"view-" + element.timeStamp + "-" + index} data={element} node_server_axios={this.state.signeeStatus ? this.props.node_server_axios : undefined} batch_id={this.props.batchId} />
                                            } else {
                                                return null;
                                            }
                                        })
                                        :
                                        <div className='sign-loader'>
                                            Fetching Data...
                                        </div>
                                }
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Approvals<label>Sign certificates or documents</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-folder-tree"></i> Events</h1>
                                <div className='nav-container'>
                                    {this.state.uniqueEvents.length > 0 && (
                                        this.state.uniqueEvents.map((element, index) =>
                                            <a className='nav' href="foo" key={index} onClick={(e) => { this.filterEvent(e, element) }}>
                                                <i className="fa-regular fa-folder-open"></i>
                                                {element}
                                            </a>
                                        )
                                    )}
                                </div>
                            </div>
                            {
                                this.state.recepientDataToggle ?
                                    <div className='settings wrapper'>
                                        <div className='subevent-head jc-sb sticky'>
                                            <a className='prev-btn' href='#foo' onClick={this.toggleAllApprovals}><i className="fa-solid fa-arrow-left"></i><p>Back</p></a>
                                            <div className='sub'>
                                                <h2>Recepients</h2>
                                                <p>{this.state.recepientData.length} certificates</p>
                                            </div>
                                        </div>
                                        <div className='table-scroller'>
                                            <table className='organiser-table'>
                                                <thead>
                                                    <tr>
                                                        {this.state.recepientData.length > 0 && Object.keys(this.state.recepientData[0]).map((key, index) => (
                                                            <th key={index}>{key.replace(/\[|\]/g, '')}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.recepientData.map((element, index) => (
                                                        <tr key={index}>
                                                            {Object.values(element).map((value, i) => (
                                                                <td key={i}>{value}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <div className='approval-wrapper'>
                                        {this.state.approval_list.length > 0 ?
                                            !this.state.filteredEvent ?
                                                this.state.approval_list.map((element, index) => {
                                                    element = JSON.parse(element);
                                                    return (
                                                        <div className='container' key={index}>
                                                            <input className='checkbox' type='checkbox' value={element.batch_id} checked={this.state.checkboxStates[element.batch_id]?.checked || false} onChange={() => this.handleCheckboxChange(element.batch_id, element.org_id, element.org_name)} />
                                                            <div className='approval-icon'>
                                                                <i className="fa-solid fa-clipboard-check"></i>
                                                            </div>
                                                            <div>
                                                                <h2>{element.title}</h2>
                                                                <p>{element.date}</p>
                                                                <h3>{element.event}</h3>
                                                                <p>{element.org_name}</p>
                                                            </div>
                                                            <div className='flex-grow'></div>
                                                            <div className='actions'>
                                                                <div className='flex' onClick={() => { this.showRecepients(element.batch_id, element.org_id) }}>
                                                                    <div className='cert-count'>{element.cert_count} Certificate</div>
                                                                    <div className='emails'><i className="fa-solid fa-envelope"></i></div>
                                                                </div>
                                                                <div className='flex'>
                                                                    <button className='reject' onClick={() => { this.rejectBatch(element.batch_id, element.org_id, element.org_name) }}>Reject</button>
                                                                    <button className='sign' onClick={() => { this.acceptBatch(element.batch_id, element.org_id, element.org_name) }}>Sign <i className="fa-solid fa-circle-check"></i></button>
                                                                </div>
                                                            </div>
                                                            <div className='certificate-preview'>
                                                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(element.org_id) + "/" + encodeURIComponent(element.preview.replace('.png', '_preview.png'))} alt="Batch Deleted by Admin / Organiser" onClick={(e) => this.openTemplatePreview(e, element.batch_id)} />
                                                            </div>
                                                        </div>)
                                                })
                                                :
                                                this.state.approval_list.filter(obj => JSON.parse(obj).event === this.state.filteredEventValue).map((element, index) => {
                                                    element = JSON.parse(element);
                                                    return (
                                                        <div className='container' key={index}>
                                                            <div className='approval-icon'>
                                                                <i className="fa-solid fa-clipboard-check"></i>
                                                            </div>
                                                            <div>
                                                                <h2>{element.title}</h2>
                                                                <p>{element.date}</p>
                                                                <h3>{element.event}</h3>
                                                                <p>{element.org_name}</p>
                                                            </div>
                                                            <div className='flex-grow'></div>
                                                            <div className='actions'>
                                                                <div className='flex' onClick={() => { this.showRecepients(element.batch_id, element.org_id) }}>
                                                                    <div className='cert-count'>{element.cert_count} Certificate</div>
                                                                    <div className='emails'><i className="fa-solid fa-envelope"></i></div>
                                                                </div>
                                                                <div className='flex'>
                                                                    <button className='reject' onClick={() => { this.rejectBatch(element.batch_id, element.org_id, element.org_name) }}>Reject</button>
                                                                    <button className='sign' onClick={() => { this.acceptBatch(element.batch_id, element.org_id, element.org_name) }}>Sign <i className="fa-solid fa-circle-check"></i></button>
                                                                </div>
                                                            </div>
                                                            <div className='certificate-preview'>
                                                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(element.org_id) + "/" + encodeURIComponent(element.preview.replace('.png', '_preview.png'))} alt="Batch Deleted by Admin / Organiser" onClick={(e) => this.openTemplatePreview(e, element.batch_id)} />
                                                            </div>
                                                        </div>)
                                                })
                                            :
                                            this.state.approval_history.length === 0 && this.state.tutorial ?
                                                <div className='container tutorial-opacity'>
                                                    <div className='approval-icon'>
                                                        <i className="fa-solid fa-clipboard-check"></i>
                                                    </div>
                                                    <div>
                                                        <h2>Tutorial for Approvals</h2>
                                                        <p>30/08/2023</p>
                                                        <h3>Event Name</h3>
                                                        <p>Organisation Name</p>
                                                    </div>
                                                    <div className='flex-grow'></div>
                                                    <div className='actions'>
                                                        <div className='flex'>
                                                            <div className='cert-count'>120 Certificate</div>
                                                            <div className='emails'><i className="fa-solid fa-envelope"></i></div>
                                                        </div>
                                                        <div className='flex'>
                                                            <button className='reject' onClick={() => { this.dummyActionBatch('reject') }}>Reject</button>
                                                            <button className='sign' onClick={() => { this.dummyActionBatch('accept') }}>Sign <i className="fa-solid fa-circle-check"></i></button>
                                                        </div>
                                                    </div>
                                                    <div className='certificate-preview'>
                                                        <img src={sampleCertificate} alt="Batch Deleted by Admin / Organiser" onClick={this.openSampleTemplatePreview} />
                                                    </div>
                                                </div>
                                                :
                                                <p>No Approvals Pending</p>
                                        }
                                        {this.state.approval_list.length > 0 && isAtLeastOneCheckboxChecked && (
                                            <div className='group-action'>
                                                <button onClick={this.handleRejectAllClick}>Reject Selected</button>
                                                <button onClick={this.handleSignAllClick}>Sign Selected</button>
                                            </div>
                                        )}
                                        {
                                            this.state.filteredEvent && (<button onClick={this.clearFilter}>View All Events</button>)
                                        }
                                        <h2>Approval History</h2>
                                        {this.state.approval_history.length > 0 ?
                                            this.state.approval_history.map((element, index) => {
                                                return (
                                                    <div className='container' key={index}>
                                                        <div className='approval-icon'>
                                                            <i className="fa-solid fa-clipboard-check"></i>
                                                        </div>
                                                        <div>
                                                            <h2>{element.title}</h2>
                                                            <p>Date : {element.date}</p>
                                                            <h3>Event : {element.event}</h3>
                                                            <p>{element.org_name}</p>
                                                        </div>
                                                        <div className='flex-grow'></div>
                                                        <div className='actions'>
                                                            {element.status === 'signed' ?
                                                                <div className="status-btn signed"><i className="fa-regular fa-circle-check"></i> Signed</div>
                                                                :
                                                                <div className="status-btn rejected"><i className="fa-regular fa-circle-xmark"></i> Rejected</div>
                                                            }
                                                        </div>
                                                        <div className='certificate-preview'>
                                                            <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(element.organisation) + "/" + encodeURIComponent(element.preview.replace('.png', '_preview.png'))} alt="Batch Deleted by Admin / Organiser" onClick={(e) => this.openTemplatePreview(e, element.id)} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <iframe
                                                className='gdrive-video-1'
                                                src="https://drive.google.com/file/d/17mD156zFKiab_mqT-L-bZpRlD8k_7Olq/preview"
                                                allow="autoplay"
                                                title='pronetin approvals'
                                                id='approvals_video'
                                            ></iframe>
                                        }
                                        {this.state.approval_history.length > 0 && this.state.approvalHistory_page < this.state.approvalHistory_pageLimit && (
                                            <div className="next-prev-btns">
                                                <button onClick={this.approvalHistoryMore}>More <i className="fa-solid fa-arrow-down"></i></button>
                                            </div>
                                        )}
                                    </div>
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;