import React from 'react';
import './css/element.css';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href).hostname + `/certificate/${this.props.data.document_id}`,
            title: this.props.data.title,
            description: 'Document received for ' + this.props.data.event + ' on ' + this.props.data.date,
            moreShareOptions: false
        };
        this.handleShare = this.handleShare.bind(this);
        this.containerRef = React.createRef();
        this.textElementRef = React.createRef();
    }

    openTile = () => {
        window.location = `/appreciation/${this.props.data.document_id}`
    }

    handleShare(event) {
        event.preventDefault();
        const { url, title, description } = this.state;
        const socialMedia = event.currentTarget.getAttribute('data-network');
        const shareUrl = getShareUrl(socialMedia, url, title, description);
        window.open(shareUrl, '_blank', 'width=550,height=450');
    }

    toggleMoreShareOptions = () => {
        this.setState({
            moreShareOptions: !this.state.moreShareOptions,
        })
    }

    componentDidMount() {
        const container = this.containerRef.current;
        const textElement = this.textElementRef.current;

        if (textElement.scrollWidth > container.clientWidth) {
            textElement.classList.add('scrolling');
        } else {
            textElement.classList.remove('scrolling');
        }
    }

    render() {
        const document = this.props.data;

        return (
            <div className='tile-wrapper pronetin'>
                <div className='tile' onClick={this.openTile}>
                    <div className={document.view_type === "public" ? 'view-option public' : 'view-option private'}>
                        <div className='view'>{document.view_type}</div>
                        {document.view_type === "public" ? <i className="fa-solid fa-lock-open"></i> : <i className="fa-solid fa-lock"></i>}
                    </div>
                    <h1 ref={this.containerRef}><span ref={this.textElementRef}>{document.title}</span><label>{document.date}</label></h1>
                    <p>{document.event}</p>
                    <div className='organisation'>{document.issuer_name}</div>
                    <div className='bottom'>
                        <div className='trial'><i className="fa-solid fa-gift"></i> We value your contribution</div>
                    </div>
                </div>
                <div className='action'>
                    <div className='icon-btn'>
                        {/* <button><i className="fa-solid fa-download"></i></button> */}
                        {/* {this.state.moreShareOptions ?
                            <button onClick={this.toggleMoreShareOptions}><i className="fa-solid fa-x"></i></button>
                            :
                            <button onClick={this.toggleMoreShareOptions}><i className="fa-solid fa-share"></i></button>
                        }
                        <button onClick={this.handleShare} data-network="linkedin"><i className="fa-brands fa-linkedin-in"></i></button>
                        {this.state.moreShareOptions && (
                            <button onClick={this.handleShare} data-network="facebook"><i className="fa-brands fa-facebook-f"></i></button>
                        )}
                        {this.state.moreShareOptions && (
                            <button onClick={this.handleShare} data-network="twitter"><i className="fa-brands fa-twitter"></i></button>
                        )} */}
                    </div>
                    {!this.state.moreShareOptions && (
                        <a href={`/appreciation/${this.props.data.document_id}`}>View Certificate</a>
                    )}
                </div>
            </div>
        );
    }
}

function getShareUrl(network, url, title, description) {
    switch (network) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
        case 'twitter':
            return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
        case 'linkedin':
            return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`;
        default:
            return null;
    }
}

export default TileComponent;