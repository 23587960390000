import React from 'react';
import './css/element.css';
import './css/organisation.css';
import axios from 'axios';

import Warning from '../components/warning';

class EventsList extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            eventList: [],
            uniqueEvents: [],
            filteredEvent: false,
            filteredEventValue: '',
            moreBtn: 'events',
            loadingEvents: true,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_events', {
            params: {
                query: "all",
            },
        }).then(res => {
            if (res.data.eventList) {
                this.setState({
                    eventList: res.data.eventList,
                    uniqueEvents: Array.from(new Set(res.data.eventList.map(obj => obj.event))),
                    loadingEvents: false,
                })
            } else {
                this.setState({
                    loadingEvents: false,
                })
            }
        });
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            this.setState({
                warning: {
                    message: "Sorry, You need an organisation account to issue certificates.",
                    type: "warning",
                    learnmore: "create",
                    autoclose: false,
                }
            })
        } else {
            window.location = "/issue";
        }
    }

    filterEvent = (e, element) => {
        e.preventDefault();
        this.setState({
            filteredEventValue: element,
            filteredEvent: true,
        })

        if (window.innerWidth < 820) {
            var subNav = document.getElementsByClassName('sub-nav')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'events',
            });
        }
    }

    clearFilter = () => {
        this.setState({
            filteredEventValue: '',
            filteredEvent: false,
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'events',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>My Organisation<label>View all your issued certificates</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button>
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-folder-tree"></i> Events</h1>
                                <div className='nav-container'>
                                    {
                                        this.state.uniqueEvents.map((element, index) => {
                                            return (
                                                <a className={this.state.filteredEventValue === element ? 'nav active' : 'nav'} href="foo" key={index} title={element} onClick={(e) => { this.filterEvent(e, element) }}>
                                                    {this.state.filteredEventValue === element ?
                                                        <i className="fa-regular fa-folder-open"></i>
                                                        :
                                                        <i className="fa-solid fa-folder"></i>
                                                    }
                                                    <p className='ellipsis'>{element}</p>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {!this.state.filteredEvent ?
                                <div className='wrapper'>
                                    {
                                        this.state.uniqueEvents.length > 0 ?
                                            this.state.uniqueEvents.map((element, index) => {
                                                return (
                                                    <div className='event-wrapper' key={index}>
                                                        <div className='event-name-container'>
                                                            <p key={index}><i className="fa-regular fa-folder-open"></i> {element}</p>
                                                        </div>
                                                        <div className='template-wrapper'>
                                                            {
                                                                this.state.eventList.filter(obj => obj.event === element).map((item, i) => {
                                                                    let pending_count = item.batches_status.filter(obj => obj === 'pending').length
                                                                    return (
                                                                        <div className='template-subevent' key={i} onClick={() => { window.location.href = '/subevents/' + item.event.replace(/\s+/g, '-').replace(/-+/g, '-').trim() + '/' + item.subevent.replace(/\s+/g, '-').replace(/-+/g, '-').trim() }}>
                                                                            <div className='subevent'>{item.subevent}</div>
                                                                            <div className='data'>
                                                                                <div className='content'>
                                                                                    <p>{item.batches.length} Batches</p>
                                                                                    <p>{item.batches_count.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)} Certificates</p>
                                                                                </div>
                                                                                {pending_count > 0 && (
                                                                                    <div className='notification'><p>{pending_count}</p>pending</div>
                                                                                )}
                                                                            </div>
                                                                            <div className='btn-container'>
                                                                                <div className='btn modified-on'><label>Modified On</label><p>{item.modified_on}</p></div>
                                                                                <button className='btn'>View</button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            !this.state.loadingEvents ?
                                                <div className='create-certificate-message'>
                                                    <button className='btn-issue' onClick={this.checkEligibility}>Create your first certificate</button>
                                                    <p>After creating the certificate, come back here to distribute the same !</p>
                                                </div>
                                                :
                                                <div className='create-certificate-message'>
                                                    <p>Loading...</p>
                                                </div>
                                    }
                                </div>
                                :
                                <div className='wrapper'>
                                    {
                                        <div className='event-wrapper'>
                                            <div className='event-name-container'>
                                                <p><i className="fa-regular fa-folder-open"></i> {this.state.filteredEventValue}</p>
                                            </div>
                                            <div className='template-wrapper'>
                                                {
                                                    this.state.eventList.filter(obj => obj.event === this.state.filteredEventValue).map((item, i) => {
                                                        let pending_count = item.batches_status.filter(obj => obj === 'pending').length
                                                        return (
                                                            <div className='template-subevent' key={i} onClick={() => { window.location.href = '/subevents/' + item.event.replace(/\s+/g, '-').replace(/-+/g, '-').trim() + '/' + item.subevent.replace(/\s+/g, '-').replace(/-+/g, '-').trim() }}>
                                                                <div className='subevent'>{item.subevent}</div>
                                                                <div className='data'>
                                                                    <div className='content'>
                                                                        <p>{item.batches.length} Batches</p>
                                                                        <p>{item.batches_count.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)} Certificates</p>
                                                                    </div>
                                                                    {pending_count > 0 && (
                                                                        <div className='notification'><p>{pending_count}</p>pending</div>
                                                                    )}
                                                                </div>
                                                                <div className='btn-container'>
                                                                    <div className='btn modified-on'><label>Modified On</label><p>{item.modified_on}</p></div>
                                                                    <button className='btn'>View</button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {this.state.eventList.length > 1 && (
                                        <button onClick={this.clearFilter}>View All Events</button>
                                    )}
                                </div>
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EventsList;